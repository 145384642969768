import _ from 'lodash';

const unsetDeep = (variable, remove) => {
  if (_.isPlainObject(variable)) {
    return _.reduce(variable, (output, value, key) => (
      key === remove ? output : _.set(output, key, unsetDeep(value, remove))
    ), {});
  }

  if (_.isArray(variable)) {
    return _.reduce(variable, (output, value) => [...output, unsetDeep(value, remove)], []);
  }

  return variable;
};

/**
 * This function will recursively remove all __typename properties from the data.
 * @param {object} data
 * @returns {object}
 */
const cleanTypeName = (data) => unsetDeep(data, '__typename');

export default cleanTypeName;
