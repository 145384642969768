import React from 'react';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  input: {
    margin: '16px',
    width: '100%'
  }
}));

const EditNextBillDialog = (props) => {
  const classes = useStyles();
  const {
    currentNextBillDate,
    handleClose,
    dialogOpen,
    updateNextBillDate
  } = props;
  const initialFormValues = {
    current: currentNextBillDate,
    new: ''
  };

  const handleNextBillSubmit = (data) => {
    const year = Number(data.new.slice(0, 4));
    const month = Number(data.new.slice(5, 7));
    const date = Number(data.new.slice(8, 10));
    const newDate = new Date(year, month - 1, date);
    updateNextBillDate(newDate.toISOString());
    handleClose();
  };

  return (
    <Dialog open={dialogOpen}>
      <DialogContent>
        <DialogTitle disableTypography>
          <Typography variant="h3">Edit Next Bill Date</Typography>
        </DialogTitle>
        <Formik
          initialValues={initialFormValues}
          onSubmit={handleNextBillSubmit}
          render={({
            values,
            handleChange,
            handleSubmit
          }) => (
              <form onSubmit={handleSubmit} id="edit-next-bill-form">
                <div>
                  <TextField
                    className={classes.input}
                    disabled
                    label="Current"
                    name="current"
                    onChange={handleChange}
                    size="small"
                    value={values.current}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.input}
                    label="New"
                    name="new"
                    onChange={handleChange}
                    size="small"
                    type="date"
                    value={values.new}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </form>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          form="edit-next-bill-form"
          type="submit"
          variant="contained"
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Go Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditNextBillDialog;
