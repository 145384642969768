import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Radio from '@material-ui/core/Radio';

import CreditCardDisplay from '../../../components/credit-card/logo-and-last-four';
import ExpirationDisplay from '../../../components/credit-card/expiration';

const useStyles = makeStyles((theme) => ({
  empty: {
    marginTop: '10px'
  },
  table: {
    maxWidth: '800px',
    marginBottom: '15px'
  },
  title: {
    marginTop: '15px',
    fontWeight: 'bold',
    fontSize: '.9em'
  },
  cell: {
    borderBottom: '0px'
  },
  label: {
    fontWeight: 'bold'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  divider: {
    marginTop: '15px'
  }
}));

const AddExistingPaymentMethod = (props) => {
  const classes = useStyles();
  const {
    handleChange,
    selectedValue,
    existingPaymentMethods,
    hideManualPaymentOption,
    disableRadio
  } = props;
  // if (
  //   !existingPaymentMethods
  //   || (Array.isArray(existingPaymentMethods)
  //     && existingPaymentMethods.length === 0)
  // ) {
  //   return null;
  // }
  const paymentMethods = existingPaymentMethods;
  const rows = paymentMethods
    ? paymentMethods.map((item, index) => {
      const {
        last, brand, expiration, token
      } = item;

      return {
        last,
        displayCard: <CreditCardDisplay last={last} brand={brand} />,
        displayExpiration: <ExpirationDisplay expiration={expiration} />,
        formField: (
          <Radio
            disabled={disableRadio}
            value={token}
            name="paymentMethod"
            onChange={(event) => {
              handleChange(event, paymentMethods);
            }}
            checked={selectedValue === token}
          />
        )
      };
    })
    : [];
  return (
    <div>
      <Table className={classes.table}>
        <TableBody>
          <TableRow key="manual-payment-option">
            {!hideManualPaymentOption && <TableCell className={classes.cell} align="left" size="small">
              <Radio
                value="manual"
                name="paymentMethod"
                onChange={(event) => {
                  handleChange(event, paymentMethods);
                }}
                checked={selectedValue === 'manual'}
              />
            </TableCell>}
            {!hideManualPaymentOption && <TableCell
              className={`${classes.cell} ${classes.cardContainer}`}
              align="left"
              size="small"
            >
              Manual Payment
            </TableCell>}
            <TableCell className={classes.cell} align="left" size="small">
            </TableCell>
          </TableRow>
          {rows.map((row, index) => {
            const {
              last, displayCard, displayExpiration, formField
            } = row;
            return (
              <TableRow key={`${last}-${index}`}>
                <TableCell className={classes.cell} align="left" size="small">
                  {formField}
                </TableCell>
                <TableCell
                  className={`${classes.cell} ${classes.cardContainer}`}
                  align="left"
                  size="small"
                >
                  {displayCard}
                </TableCell>
                <TableCell className={classes.cell} align="left" size="small">
                  {displayExpiration}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default AddExistingPaymentMethod;
