import { gql } from '@apollo/client';

export default gql`
  mutation storeDDAPaymentMethod($input: DDAPaymentMethodInput!) {
    storeDDAPaymentMethod(input: $input) {
      id
      category
      token
      primary
      code
      status
      last
      routingTransitNumber
    }
  }
`;
