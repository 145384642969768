import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import getDashboardData from '../queries/dashboard';
import addMerchantIdToAcquirer from '../mutations/add-merchantId-to-acquirer';

import AcquirerSelect from './acquirer-select';
import MerchantIdForm from './merchant-id-form';
import ConfigureCodes from './configure-codes';
import ConfigureRules from './configure-rules';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: 350
  },
  card: {
    padding: 15,
    width: '100%',
    height: '100%',
    minHeight: 350
  },
  cardContent: {
    minHeight: 250,
    fontSize: 12,
    marginBottom: 15
  },
  cardTitle: {
    marginBottom: 7
  },
  stepper: {
    width: '100%',
    marginBottom: 25
  },
  breadcrumbs: {
    width: '100%',
    marginBottom: 25
  }
}));

const initialSteps = [
  'choose acquirer',
  'configure merchant ids',
  'configure codes',
  'configure recycle rules'
];

const renderStep = (path, componentProps) => {
  if (!componentProps.acquirer) {
    return <AcquirerSelect {...componentProps} />;
  }

  switch (path) {
    case '/config/configure-acquirer':
      return <AcquirerSelect {...componentProps} />;
    case '/config/configure-merchant-ids':
      return <MerchantIdForm {...componentProps} />;
    case '/config/configure-codes':
      return <ConfigureCodes {...componentProps} />;
    case '/config/configure-recycle-rules':
      return <ConfigureRules {...componentProps} />;
    default:
      return null;
  }
};

const getActiveStep = (acquirer, path) => {
  if (!acquirer) return 0;

  switch (path) {
    case '/config/configure-acquirer':
      return 0;
    case '/config/configure-merchant-ids':
      return 1;
    case '/config/configure-codes':
      return 2;
    case '/config/configure-recycle-rules':
      return 3;
    default:
      return 0;
  }
};

const getNextStep = (activeStep) => {
  switch (activeStep) {
    case 0:
      return '/config/configure-merchant-ids';
    case 1:
      return '/config/configure-codes';
    case 2:
      return '/config/configure-recycle-rules';
    default:
      return '/config';
  }
};

const MerchatConfigView = (props) => {
  const [steps, setSteps] = useState(initialSteps);
  const [acquirer, setAcquirer] = useState();

  const { match, history } = props;
  const { path } = match;
  const classes = useStyles();
  const activeStep = getActiveStep(acquirer, path);
  const [idToAcquirer] = useMutation(addMerchantIdToAcquirer, {
    refetchQueries: [
      {
        query: getDashboardData
      }
    ]
  });
  const handleNext = (nextStep) => {
    const { history: historyArray } = props;
    if (activeStep + 1 === steps.length) {
      return historyArray.push('/config');
    }

    return historyArray.push(getNextStep(activeStep));
  };

  const handleMerchantIdForm = async (data) => {
    try {
      await idToAcquirer({ variables: { input: data } });
      history.push('/config/configure-codes');
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.warn(err.message);
    }
  };

  const handleAcquirerSelect = (selected) => {
    steps.splice(0, 1, selected.name);
    setSteps(steps);
    setAcquirer(selected);
    history.push('/config/configure-merchant-ids');
  };

  const renderedStep = renderStep(path, {
    acquirer,
    match,
    setAcquirer: handleAcquirerSelect,
    saveMerchantId: handleMerchantIdForm
  });

  return (
    <div className={classes.root}>
      <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
        <Link color="inherit" href="/config">
          Merchant Config
        </Link>
        <Typography color="textPrimary"> Configure New Acquirer</Typography>
      </Breadcrumbs>

      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Card variant="outlined" elevation={0} className={classes.card}>
        <CardActions className={classes.cardActions}>
          {activeStep > 0 && (
            <Button
              onClick={() => handleNext(activeStep)}
              size="small"
              disabled={activeStep === steps.length}
            >
              next
            </Button>
          )}
        </CardActions>

        <CardContent className={classes.cardContent}>
          {renderedStep}
        </CardContent>
      </Card>
    </div>
  );
};

export default MerchatConfigView;
