import { gql } from '@apollo/client';

export default gql`
  {
    getMerchantConfig {
      id
      recycle {
        codes
        acquirerId
        delay {
          type
          value
        }
        rules {
          attribute
          operator
          value
        }
        type
      }
    }
  }
`;
