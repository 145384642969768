import React, { useContext } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import TreeView from 'devextreme-react/tree-view';

import 'devextreme/dist/css/dx.light.css';

import Can from '../../components/auth/userCanPerform';
import { authContext } from '../../contexts/authContext';
import constants from '../../constants';

const {
  BASE_URL,
  REPORTS_API_KEY,
  AWS_BUCKET
} = constants.reports;

const headers = { 'x-api-key': REPORTS_API_KEY };

const getNodeName = (name, isFolder) => {
  const pathStrings = name.split('/');
  return pathStrings[pathStrings.length - (isFolder ? 2 : 1)];
};

const createChildren = async (parent) => {
  try {
    const parentId = parent ? parent.itemData.id : '';

    const { data } = await axios.post(BASE_URL, {
      Bucket: AWS_BUCKET,
      Delimiter: '/',
      Prefix: parentId
    }, { headers });

    const folders = data.CommonPrefixes.map((res) => ({
      id: res.Prefix,
      text: getNodeName(res.Prefix, true),
      parentId,
      hasItems: true,
      icon: 'inactivefolder'
    }));
    const files = data.data.filter((res) => res.Size > 0).map((res) => ({
      id: res.Key,
      text: getNodeName(res.Key, false),
      parentId,
      hasItems: false,
      icon: 'file'
    }));

    return [...folders, ...files];
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getReport = async (url, fileName) => {
  console.log({ method: 'getReport', input: { url, fileName } });

  try {
    const response = await axios.get(url, { responseType: 'blob', headers: { 'x-api-key': REPORTS_API_KEY } });

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const saved = saveAs(blob, fileName);

    console.log({ method: 'getReport', saved });

    return saved;
  } catch (getReportError) {
    console.error({ method: 'getReport', getReportError });

    return false;
  }
};

const Reports = () => {
  const auth = useContext(authContext);

  const handleItemClick = async ({ itemData }) => {
    if (itemData.hasItems) return;

    const fileName = getNodeName(itemData.id, false);

    const downloaded = await getReport(`${BASE_URL}/${itemData.id}`, fileName);

    console.log({ method: 'handleItemClick', downloaded });
  };

  return (
    <Can
      groups={auth.groups}
      perform="reports:access"
      yes={() => (
        <TreeView
          id="simple-treeview"
          dataStructure="plain"
          rootValue=""
          expandNodesRecursive={false}
          createChildren={createChildren}
          onItemClick={handleItemClick}
        />
      )}
    />
  );
};

export default Reports;
