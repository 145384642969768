import { gql } from '@apollo/client';

export default gql`
  query searchProductsQuery($input: QueryProductsInput) {
    products: search(input: $input) {
      id
      sku
      merchantId
      merchantProductId
      taxCode
      glCode
      status
      currentVersion
      description
      type
      prices {
        frequency
        amount
        currency {
          name
          symbol
        }
      }
      products {
        id
        sku
        merchantProductId
        taxCode
        glCode
        status
        currentVersion
        prices {
          frequency
          amount
          currency {
            name
            symbol
          }
        }
        grants {
          id
          description
        }
      }
      grants {
        id
        description
      }
    }
  }
`;
