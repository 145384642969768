import { gql } from '@apollo/client';

export default gql`
  {
    getMerchantConfig {
      cancellationReasons {
          code
          name
          category
      }
    }
  }
`;
