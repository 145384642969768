import React, { Fragment, useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Can from '../../../components/auth/userCanPerform';
import getCustomer from '../queries/getCustomer';
import ViewInvoices from './invoices';
import TransactionTable from './transaction-table';
import AddPaymentMethod from './payment-method';
import AddSubscription from '../subscription/add-subscription';
import ViewPaymentMethods from '../payment-method/view-payment-methods';
import EditButton from '../../../components/buttons/pencil-button';
import CircleProgress from '../../../components/progress/circle';
import CustomerContact from '../components/contact-info';
import ViewSubscriptions from '../subscription/view-subscriptions';
import { authContext } from '../../../contexts/authContext';

import './customer-view.scss';

const getPriceConfig = gql`
  {
    getPriceConfig {
      currencies {
        name
        symbol
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: '25px',
    marginTop: '10px',
    width: '60%',
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px',
    height: '2px',
  },
  empty: {
    marginTop: '10px',
  },
  subscriptions: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  invoiceNumber: {
    fontWeight: '400',
  },
  metadataTitle: {
    fontWeight: 600,
  },
  buttonSpacer: {
    marginTop: '10px',
  }
}));

const renderAction = (auth, navToEdit) => {
  if (!auth) {
    return null;
  }

  return (
    <Can
      groups={auth.groups}
      perform="customer:edit"
      yes={() => <EditButton onClick={() => navToEdit()} />}
    />
  );
};

const renderMetadata = (metadata) => {
  if (!metadata || metadata.length === 0) {
    return null;
  }
  return metadata.map((item) => (
    <li key={`${item.key}${item.value}`}>
      {item.key}: {item.value}
    </li>
  ));
};

const CustomerView = (props) => {
  const classes = useStyles();
  const { history, match } = props;
  const { params } = match;
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const { data: priceConfig } = useQuery(getPriceConfig, {
    client: props.client,
  });

  const { loading, error, data } = useQuery(getCustomer, {
    client: props.client,
    variables: { customerId: params.customerId },
  });

  const auth = useContext(authContext);

  const navigateToEditCustomer = () => history.push(`/customer/${params.customerId}/edit`);

  if (loading) return <CircleProgress size="1em" />;
  if (error) return <p>{error.message} :(</p>;
  if (!data || (data && !data.getCustomer)) return null;
  const { getCustomer: customer } = data;

  const editAction = renderAction(auth, navigateToEditCustomer);
  const renderedMetadata = renderMetadata(customer.metadata);

  const handleInvoiceClick = (invoice) => {
    setCurrentInvoice(invoice);
  };

  return (
    <Fragment>
      <Typography variant="h1">Customer Details</Typography>
      <div className="customer-view">
        <div className="customer-header">
          <h3 className="name">
            {customer.firstName} {customer.lastName}
          </h3>
          {editAction}
        </div>
        <div className="customer-contact">
          <CustomerContact customer={customer} />
        </div>
        <div className="customer-contact">
          {!renderedMetadata ? null : (
            <Typography
              variant="subtitle2"
              gutterBottom={true}
              className={classes.metadataTitle}
            >
              Metadata
            </Typography>
          )}
          <ul>{renderedMetadata}</ul>
        </div>
        {/* <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/activity-list/${params.customerId}`);
            }}
          >
            View {customer.firstName}&apos;s Activity
          </Button>
        </div> */}
        <Divider className={classes.divider} />
        <div>
          <AddPaymentMethod customerId={params.customerId} />
          <ViewPaymentMethods customerId={params.customerId} />
        </div>
        <Divider className={classes.divider} />
        <div>
          <AddSubscription auth={auth} customerId={params.customerId} />
        </div>
        <div className={classes.subscriptions}>
          <ViewSubscriptions {...props} />
        </div>
        <Divider className={classes.divider} />
        <div>
          <Typography gutterBottom={true} variant="h3">
            Invoices
          </Typography>
          <Button
            className={classes.buttonSpacer}
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/virtual-terminal/${params.customerId}`);
            }}
          >
            Create One-Time Invoice
          </Button>
          <ViewInvoices
            auth={auth}
            customerId={params.customerId}
            handleClick={handleInvoiceClick}
            priceConfig={priceConfig}
            confirmation={{ send: true, email: customer.email }}
          />
        </div>
        <Divider className={classes.divider} />
        <div>
          {currentInvoice ? (
            <TransactionTable
              invoiceId={currentInvoice.id}
              invoiceNumber={currentInvoice.number}
              priceConfig={priceConfig}
            />
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerView;
