import _ from 'lodash';
import React from 'react';
import { FieldArray } from 'formik';
import Select from '@material-ui/core/Select';
import { gql, useQuery } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import AddButton from '../../../components/buttons/add-button';
import RemoveButton from '../../../components/buttons/remove-button';

import './product-prices.scss';

const MenuItem = FormControl;

const getPriceConfig = gql`
  {
    getPriceConfig {
      frequencies
      currencies {
        name
        symbol
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  productPriceSelect: {
    marginRight: '10px',
    minWidth: '130px'
  },
  productPriceOption: {
    width: '100%',
    padding: '10px'
  },
  textButton: {
    fontWeight: 600,
    fontSize: '14px'
  },
  errorText: {
    color: theme.palette.error.main
  }
}));

const getCurrencyName = (price) => _.get(price, 'currency.name', _.get(price, 'currency', ''));

const ProductPricesForm = (props) => {
  const classes = useStyles();
  const {
    data, onChange, errors, productType
  } = props;
  const { loading, error, data: queryData } = useQuery(getPriceConfig);

  return (
    <>
      { loading && <p>Loading...</p>}
      { error && <p>{error.message} :(</p>}
      {
        queryData && (
          < FieldArray
            name="prices"
            render={(arrayHelpers) => (
                <div className="product-prices">
                  <div className="product-prices-header">
                    <Typography variant="h3">
                      {productType === 'onetime'
                        ? 'Default Price'
                        : 'Product Prices'}
                    </Typography>
                    {productType === 'onetime' ? null : (
                      <AddButton
                        label="Add Item"
                        onClick={() => arrayHelpers.push({
                          frequency: 'monthly',
                          currency: '',
                          amount: 0
                        })
                        }
                      />
                    )}
                  </div>
                  {data && data.length > 0
                    ? data.map((price, index) => {
                      const frequencyError = errors && errors[index]
                        ? errors[index].frequency
                        : null;
                      const currencyError = errors && errors[index]
                        ? errors[index].currency
                        : null;
                      const amountError = errors && errors[index] ? errors[index].amount : null;

                      return (
                        <div className="price-row" key={index}>
                          {productType !== 'onetime' ? (
                            <FormControl
                              key="product-price-frequency-selector"
                              className={classes.productPriceSelect}
                            >
                              <InputLabel htmlFor="frequency">
                                Frequency
                                </InputLabel>
                              <Select
                                className={classes.productPriceSelect}
                                name="frequency"
                                value={price.frequency}
                                onChange={onChange}
                                inputProps={{
                                  name: `prices.${index}.frequency`,
                                  id: `prices.${index}.frequency`
                                }}
                              >
                                <MenuItem value="" />
                                {queryData && queryData.getPriceConfig.frequencies.map(
                                  (frequency) => (
                                    <MenuItem
                                      className={classes.productPriceOption}
                                      key={frequency}
                                      value={frequency || ''}
                                    >
                                      {frequency}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                              <FormHelperText className={classes.errorText}>
                                {!price.frequency || price.frequency === ''
                                  ? frequencyError
                                  : null}
                              </FormHelperText>
                            </FormControl>
                          ) : null}

                          <FormControl
                            key="product-price-currency-selector"
                            className={classes.productPriceSelect}
                          >
                            <InputLabel htmlFor="currency">
                              Currency
                              </InputLabel>
                            <Select
                              className={classes.productPriceSelect}
                              name="currency"
                              value={getCurrencyName(price)}
                              onChange={onChange}
                              inputProps={{
                                name: `prices.${index}.currency`,
                                id: `prices.${index}.currency`
                              }}
                            >
                              <MenuItem value="" />
                              {queryData && queryData.getPriceConfig.currencies.map((currency) => (
                                  <MenuItem
                                    className={classes.productPriceOption}
                                    key={currency.name}
                                    value={currency.name || ''}
                                  >
                                    {currency.symbol} {currency.name}
                                  </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText className={classes.errorText}>
                              {!price.currency || price.currency === ''
                                ? currencyError
                                : null}
                            </FormHelperText>
                          </FormControl>
                          <FormControl>
                            <TextField
                              className={classes.productPriceSelect}
                              label="Amount"
                              name={`prices.${index}.amount`}
                              type="text"
                              onChange={onChange}
                              value={price.amount || ''}
                            />
                            <FormHelperText className={classes.errorText}>
                              {!price.amount
                                || price.amount === ''
                                || price.amount <= 0
                                ? amountError
                                : null}
                            </FormHelperText>
                          </FormControl>
                          {productType === 'onetime' ? null : (
                            <RemoveButton
                              style={{ paddingTop: '1.6em' }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          )}
                        </div>
                      );
                    })
                    : null}
                </div>
            )}
          />
        )}
    </>
  );
};

export default ProductPricesForm;
