import React from 'react';
import MUIDataTable from 'mui-datatables';

import RemoveButton from '../../../components/buttons/remove-button';

const columns = [
  '',
  '',
  '',
  {
    name: '',
    options: {
      setCellProps: () => ({
        align: 'right'
      })
    }
  },
  '',
  ''
];
const options = {
  elevation: 0,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  pagination: false,
  filter: false,
  disableToolbarSelect: true,
  rowHover: false,
  selectableRows: 'none',
  selectableRowsHeader: false,
  responsive: 'scrollFullHeight'
};

const ViewProducts = (props) => {
  const { selectedProducts, handleRemove, isSaving } = props;

  const getProductRow = (product) => {
    const {
      merchantProductId,
      sku,
      description,
      currency,
      amount,
      quantity,
      id
    } = product;
    const _amount = parseFloat(amount).toFixed(2);
    const price = `${currency.symbol}${_amount}`;
    const removeButton = isSaving ? null : (
      <RemoveButton key={id} label="remove" id={id} onClick={handleRemove} />
    );
    return [
      merchantProductId,
      sku,
      description,
      price,
      `Qty: ${quantity}`,
      removeButton
    ];
  };
  const rows = selectedProducts.map((product) => getProductRow(product));
  if (rows) {
    return <MUIDataTable data={rows} columns={columns} options={options} />;
  }
  return null;
};

export default ViewProducts;
