import React from 'react';
import PaymentIcon from '@material-ui/icons/Payment';
import isAuthenticated from '../../components/auth/isAuthenticated';

import IconHeading from '../../components/icon-heading';

import SubscriptionList from './list-table';

const SubscriptionsContainer = (props) => (
  <div className="subscriptions">
    <IconHeading icon={<PaymentIcon fontSize="large" />} title={'Subscriptions'} />
    <SubscriptionList {...props} />
  </div>
);

export default isAuthenticated(SubscriptionsContainer);
