import { gql } from '@apollo/client';

export default gql`
  mutation updateRecyleCodeTypeMutation($input: RecycleRuleTypeChangeInput!) {
    updateRecyleCodeType(input: $input) {
      id
      recycle {
        codes
        acquirerId
        delay {
          type
          value
        }
        rules {
          attribute
          operator
          value
        }
        type
      }
    }
  }
`;
