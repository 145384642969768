import React, { useContext, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import FolderIcon from '@material-ui/icons/Folder';
import Can from '../../components/auth/userCanPerform';
import searchProducts from './queries/searchProducts';
import ActionButton from '../../components/buttons/action-button';
import CircleProgress from '../../components/progress/circle';
import EmptyState from '../../components/empty-state';
import IconHeading from '../../components/icon-heading';
import ProductsTable from './list-table';
import { authContext } from '../../contexts/authContext';

import './product-list.scss';

const useStyles = makeStyles({
  searchBar: {
    marginTop: '25px',
    marginBottom: '25px',
    display: 'flex !important'
  }
});

const mutation = gql`
  mutation changeStatusMutation($input: ChangeStatusInput) {
    changeStatus(input: $input) {
      id
      status
    }
  }
`;

const ProductList = (props) => {
  const { history } = props;
  const [searchInput, setSearchInput] = useState('');
  const classes = useStyles();
  const [statusFilter, setStatusFilter] = useState(['active', 'pending']);
  const { loading, error, data } = useQuery(searchProducts, {
    variables: {
      input: {
        searchTerm: `${searchInput}`
      }
    }
  });
  const [updateStatus] = useMutation(mutation, {
    update: (store, { data: { changeStatus } }) => {
      // todo: handle errors appropriately. dont update cache with dirty state
      const cacheData = store.readQuery({
        query: searchProducts,
        variables: { input: { searchTerm: '' } }
      });
      const itemIndex = cacheData.products.findIndex(
        (item) => item.id === changeStatus.id
      );

      if (itemIndex >= 0) {
        const itemWithChanges = {

          ...cacheData.products[itemIndex],
          ...changeStatus
        };
        cacheData.products.splice(itemIndex, 1, itemWithChanges);
        store.writeQuery({
          query: searchProducts,
          variables: { input: { searchTerm: '' } },
          data: cacheData
        });
      }
    }
  });

  const auth = useContext(authContext);

  const handleChange = (event) => {
    const { target } = event;
    const { value } = target;

    setSearchInput(value);
  };
  const navigateToEdit = (productId) => history.push(`/product/${productId}/edit`);

  const navigateToView = (productId) => history.push(`/product/${productId}`);

  const handleStatusChange = async (eventData) => {
    await updateStatus({ variables: { input: { ...eventData } } });
  };

  const displayComponentWithData = (displayData) => (
    !displayData.products || displayData.products.length === 0
      ? <EmptyState message="no products to show" />
      : <ProductsTable
        auth={auth}
        products={displayData.products}
        handleStatusChange={(id, status) => {
          handleStatusChange({ id, status });
        }}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        navigateToEdit={navigateToEdit}
        navigateToView={navigateToView}
      />
  );

  return (
    <Can
      groups={auth.groups}
      perform="products:view"
      yes={() => (
          <div className="products">
            <IconHeading
              icon={<FolderIcon fontSize="large" />}
              title={'Products'}
            />
            <Can
              groups={auth.groups}
              perform="product:add"
              yes={() => (
                  <div className="products--add">
                    <ActionButton
                      label="Add a product"
                      onClick={() => history.push('/product/add')}
                    />
                  </div>
              )}
            />
            <TextField
              className={classes.searchBar}
              placeholder="search by id, sku or description"
              onChange={(event) => {
                handleChange(event);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            {loading && <CircleProgress size="1em" />}
            {error && <p>{error.message} :(</p>}
            {data && displayComponentWithData(data)}
          </div>
      )}
    />
  );
};

export default ProductList;
