import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import getPaymentMethodsQuery from '../queries/getPaymentMethods';
import updatePaymentMethodDetails from '../mutations/updatePaymentMethodDetails';
import CreditCardDisplay from '../../../components/credit-card/logo-and-last-four';
import ExpirationDisplay from '../../../components/credit-card/expiration';
import RoutingNumber from '../../../components/credit-card/routing';
import CircleProgress from '../../../components/progress/circle';
import InfoButton from '../../../components/buttons/info-button';
import ModalBody from './modal-body';
import getPaymentMethodDetails from '../utils/get-payment-method-details-from-vault';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px'
  },
  paymentLogo: {
    width: '50px',
    marginRight: '10px'
  },
  expiration: {
    fontWeight: 600
  },
  empty: {
    marginTop: '10px'
  },
  table: {
    marginBottom: '15px',
    width: 'auto'
  },
  title: {
    marginTop: '15px',
    fontWeight: 'bold',
    fontSize: '.9em'
  },
  cell: {
    borderBottom: '0px'
  },
  label: {
    fontWeight: 'bold'
  },
  popover: {
    pointerEvents: 'click',
  },
  paper: {
    padding: theme.spacing(1),
  }
}));

const ViewPaymentMethod = (props) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);
  const [combinedData, setCombinedData] = useState([]);
  const { loading, data } = useQuery(getPaymentMethodsQuery, {
    client: props.client,
    variables: {
      input: { externalId: props.customerId }
    }
  });

  useEffect(() => {
    if (data == null) return;

    const updatedPaymentMethods = [];
    const { getPaymentMethods } = data;
    const requests = getPaymentMethods.map((item) => getPaymentMethodDetails(item.token));
    Promise.all(requests).then((responses) => {
      for (const response of responses) {
        updatedPaymentMethods.push(response);
      }
      const concatData = getPaymentMethods.map(
        (item, i) => ({ ...item, ...updatedPaymentMethods[i] })
      );

      setCombinedData(concatData);
    });
  }, [data]);

  // TODO: Can use the 'update' function to update the
  // cache w/ the new expiration vs reloading the whole page.
  const [editPaymentMethodMutation] = useMutation(updatePaymentMethodDetails, {
    awaitRefetchQueries: true,
    onCompleted: () => window.location.reload()
  });

  const handleModalClose = () => {
    setModalOpen(null);
  };

  const handleClick = (event, popoverId) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const billingInfo = (info) => (
    info && <Typography
        component={'span'}
      >
        <p>
          Address: {info.address}
        </p>
        <p>
          Address 2: {info.address2}
        </p>
        <p>
          Locality: {info.locality}
        </p>
        <p>
          Postal: {info.postal}
        </p>
        <p>
          Region: {info.region}
        </p>
      </Typography>
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (loading || !data) {
    return <CircleProgress size="1em" />;
  }

  const dataToShow = combinedData.map((item) => ({
    ...item,
    displayCard: (
        <CreditCardDisplay last={item.last} brand={item.brand} />
    ),
    displayInfo: item.category === 'CREDIT' ? <ExpirationDisplay expiration={item.expiration} /> : <RoutingNumber props={item} />
  }));

  if (!loading && combinedData.length === 0) {
    return <p>No credit cards added</p>;
  }

  return (
    <div className="view-payment-method">
      <Typography variant="subtitle2" className={classes.title}>
        Payment Options
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          {dataToShow.map((row, index) => {
            const {
              category,
              last,
              issuerId,
              displayCard,
              displayInfo,
              billingDetails
            } = _.set(row, 'issuerId', _.get(row, 'issuer_id'));

            const body = ModalBody(
              props.customerId,
              editPaymentMethodMutation,
              handleModalClose,
              row
            );

            return (
              <TableRow key={`${last}-${index}`}>
                <TableCell
                  className={`${classes.cell} ${classes.cardContainer}`}
                  key={row.id}
                  align="left"
                  size="small"
                >
                  {displayCard}
                </TableCell>
                <TableCell className={classes.cell} align="left" size="small">
                  {displayInfo}
                </TableCell>
                <TableCell className={classes.cell} align="left" size="small">
                  {issuerId && category === 'CREDIT'
                    && <>
                      <span className={classes.label}>IIN </span>
                      {issuerId}
                    </>
                  }
                </TableCell>
                <TableCell className={classes.cell} align="left" size="small">
                  {(category === 'CREDIT')
                    && <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setModalOpen(index)}
                    >
                      Edit
                    </Button>
                  }
                </TableCell>
                <Modal
                  open={modalOpen === index}
                  onClose={handleClose}
                >
                  {body}
                </Modal>
                <TableCell className={classes.cell} align="left" size="small">
                  <InfoButton onClick={(e) => handleClick(e, row.id)} />
                  <Popover
                    id={id}
                    className={classes.popover}
                    classes={{
                      paper: classes.paper,
                    }}
                    open={openedPopoverId === row.id}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {billingInfo(billingDetails)}
                  </Popover>
                </TableCell>
              </TableRow >
            );
          })}
        </TableBody >
      </Table >
    </div >
  );
};

export default ViewPaymentMethod;
