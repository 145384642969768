import { gql } from '@apollo/client';

export default gql`
mutation refundInvoiceMutation ($input: RefundInvoiceInput!) {
  refundInvoice (input: $input) {
      id
      status
      balance
      total
      refundAmount
    }
  }
`;
