import React, { Fragment, useContext } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import FolderIcon from '@material-ui/icons/Folder';
import Typography from '@material-ui/core/Typography';
import getProduct from './queries/getProduct';
import searchProducts from '../products/queries/searchProducts';
import CircleProgress from '../../components/progress/circle';
import Can from '../../components/auth/userCanPerform';
import EditButton from '../../components/buttons/pencil-button';
import CancelButton from '../../components/buttons/remove-button';
import ActivateButton from '../../components/buttons/circle-check-button';
import IconHeading from '../../components/icon-heading';
import { authContext } from '../../contexts/authContext';

import './product-view.scss';

const mutation = gql`
  mutation changeStatusMutation($input: ChangeStatusInput) {
    changeStatus(input: $input) {
      id
      status
    }
  }
`;

const renderDetail = (title, subtitle, id) => (
    <div key={`${id}-${title}`} className={'detail'}>
      <h2>
        <span className="title">{title}: </span>
        {subtitle}
      </h2>
    </div>
);

const renderDescription = (subtitle, id) => (
    <div key={`${id}-description`} className={'detail'}>
      <h2 className="title">Description</h2>
      <Typography variant={'subtitle1'}>{subtitle}</Typography>
    </div>
);

const renderPrices = (prices, id) => {
  if (prices.length > 0) {
    return (
      <div key={`prices-${id}`} className={'detail'}>
        <h2 className={'title'}>Prices</h2>
        {prices.map((price, index) => {
          const { currency, frequency, amount } = price;
          const currencySymbol = currency && currency.symbol ? currency.symbol : '';
          const currencyName = currency && currency.name ? currency.name : '';
          return (
            <Typography
              key={`${frequency}-${index}`}
              variant={'subtitle1'}
            >{`${currencySymbol}${amount} ${currencyName} ${frequency}`}</Typography>
          );
        })}
      </div>
    );
  }
  return null;
};

const renderMetadata = (items, id) => {
  if (items && items.length > 0) {
    return (
      <div key={`metadata=${id}`} className={'detail'}>
        <h2 className="title">Metadata</h2>
        {items.map((meta) => (
            <Typography key={`${meta.value}-${meta.key}`} variant={'subtitle1'}>
              {meta.key}: {meta.value}
            </Typography>
        ))}
      </div>
    );
  }
  return null;
};

const renderProducts = (products, id) => {
  if (products && products.length > 0) {
    return (
      <div key={`products=${id}`} className={'detail'}>
        <h2 className="title">Products</h2>
        {products.map((product) => (
            <div
              className="bundle-product-container"
              key={`${product.id}-container`}
            >
              <p
                className="bundle-product-description"
                key={`${product.id}-desc`}
              >
                {product.description}
              </p>
              <Typography key={`${product.id}-merchantId`} variant={'body2'}>
                MerchantProductId: {product.merchantProductId}
              </Typography>
              <Typography key={`${product.id}-sku`} variant={'body2'}>
                SKU: {product.sku}
              </Typography>
            </div>
        ))}
      </div>
    );
  }
  return null;
};

const renderGrants = (grants, id) => {
  if (grants && grants.length > 0) {
    return (
      <div key={`grants=${id}`} className={'detail'}>
        <h2 className="title">Grants</h2>
        {grants.map((grant) => (
            <Typography key={grant.id} variant={'subtitle1'}>
              Id {grant.id}: {grant.description}
            </Typography>
        ))}
      </div>
    );
  }
  return null;
};

const ProductView = (props) => {
  const { history, match } = props;
  const { params } = match;
  const navigateToEdit = () => history.push(`/product/${params.productId}/edit`);
  const { loading, error, data } = useQuery(getProduct, {
    variables: { productId: `${params.productId}` }
  });
  const [updateStatus] = useMutation(mutation, {
    update: (store, { data: { changeStatus } }) => {
      // todo: handle errors appropriately. dont update cache with dirty state
      const cacheData = store.readQuery({
        query: searchProducts,
        variables: { input: { searchTerm: '' } }
      });

      const itemIndex = cacheData.products.findIndex(
        (item) => item.id === changeStatus.id
      );

      if (itemIndex >= 0) {
        const itemWithChanges = {

          ...cacheData.products[itemIndex],
          ...changeStatus
        };

        cacheData.products.splice(itemIndex, 1, itemWithChanges);
        store.writeQuery({
          query: searchProducts,
          variables: { input: { searchTerm: '' } },
          data: cacheData
        });
      }
    }
  });

  const auth = useContext(authContext);

  const renderEdit = (authData, status, callback) => {
    if (!authData || status !== 'pending') {
      return null;
    }

    return (
      <Can
        groups={authData.groups}
        perform="product:edit"
        yes={() => <EditButton onClick={callback} />}
      />
    );
  };

  const handleStatusChange = async (currentStatus, productId) => {
    const variables = {
      input: {
        id: productId,
        status: currentStatus
      }
    };

    await updateStatus({ variables: { ...variables } });
  };

  const renderStatusChange = (currentStatus, onStatusChange) => {
    switch (currentStatus) {
      case 'pending':
        return (
          <Can
            groups={auth.groups}
            perform="product:change-status"
            yes={() => (
                <ActivateButton
                  label="Activate"
                  onClick={() => onStatusChange('active', params.productId)}
                />
            )}
          />
        );
      case 'active':
        return (
          <Can
            groups={auth.groups}
            perform="product:change-status"
            yes={() => (
                <CancelButton
                  label="Cancel Product"
                  onClick={() => onStatusChange('cancelled', params.productId)}
                />
            )}
          />
        );
      default:
        return null;
    }
  };

  const showProductsWithData = (displayData) => {
    const { getProduct: product } = displayData;
    const {
      type,
      status,
      merchantProductId,
      sku,
      taxCode,
      glCode,
      currentVersion,
      description,
      prices,
      metadata,
      grants,
      products,
      id
    } = product;

    const editAction = renderEdit(auth, status, navigateToEdit);
    const actionButton = renderStatusChange(status, handleStatusChange);
    return (
      <div className="product-container">
        <div className="product-buttons">
          {editAction}
          {actionButton}
        </div>
        {renderDetail('Product Type', type, id)}
        {renderDetail('Merchant Product Id', merchantProductId, id)}
        {renderDetail('SKU', sku, id)}
        {renderDetail('Tax Code', taxCode, id)}
        {renderDetail('GL Code', glCode, id)}
        {renderDetail('Version', currentVersion, id)}
        {renderDetail('Status', status, id)}
        {renderDescription(description, id)}
        {renderPrices(prices, id)}
        {renderMetadata(metadata, id)}
        {renderProducts(products, id)}
        {renderGrants(grants)}
      </div>
    );
  };

  return (
    <Fragment>
      <IconHeading title={'Product Details'} icon={<FolderIcon />} />
      {loading && <CircleProgress size="3em" />}
      {error && <p>{error.message} </p>}
      {data && showProductsWithData(data)}
    </Fragment>
  );
};

export default ProductView;
