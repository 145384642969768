import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%'
  },
  listItem: {
    maxWidth: 300
  }
}));

const renderListItems = (props) => {
  const { acquirers, history } = props;
  const items = [];

  acquirers.forEach((item) => {
    const onClick = () => history.push(`/config/configure-acquirer/${item.acquirerId}`);

    items.push(
      <ListItem key={`acquirer-${item.acquirerId}`} button onClick={onClick}>
        <ListItemIcon>
          <SettingsApplicationsIcon />
        </ListItemIcon>
        <ListItemText primary={item.name} />
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
      </ListItem>
    );
  });

  return items;
};

const AcquirerList = (props) => {
  const classes = useStyles();
  const items = renderListItems(props);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.list}
    >
      {items}
    </List>
  );
};

export default AcquirerList;
