import _ from 'lodash';
import React, {
  Fragment, useCallback, useContext, useEffect, useState
} from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import cleanTypeName from '../../../utils/cleanTypeName';
import ISOToUTC from '../../utils/iso-to-utc';
import getSubscriptionsByCustomerIdQuery from '../../queries/getSubscriptionsByCustomerId';
import getNextBill from '../../queries/getNextBill';
import editSubscriptionMutation from '../../mutations/editSubscription';
import editNextBillMutation from '../../mutations/editNextBillDate';
import getSubscriptionQuery from '../../queries/getSubscription';
import searchSubscriptionProducts from '../../../products/queries/searchSubscriptionProducts';
import AddButton from '../../../../components/buttons/add-button';
import RemoveButton from '../../../../components/buttons/remove-button';
import AddExistingPaymentMethod from './add-existing-payment-method';
import AddServiceAddress from '../add-service-address';
import AddProducts from './add-existing-product';
import CircleProgress from '../../../../components/progress/circle';
import RemoveProducts from './remove-product';
import RemovePaymentMethodForm from './remove-payment-method';
import CancelDialog from './cancel-dialog';
import EditNextBillDialog from './edit-next-bill-dialog';
import Can from '../../../../components/auth/userCanPerform';
import { authContext } from '../../../../contexts/authContext';
import MetaData from '../../form/metadata';
import getPaymentMethodsQuery from '../../queries/getPaymentMethods';
// import getPaymentMethodDetails from '../../utils/get-payment-method-details-from-vault';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: '30px',
    marginBottom: '30px',
    height: '2px'
  },
  section: {
    marginTop: '20px'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  saveAndCancel: {
    marginTop: '10px',
    marginRight: '10px'
  },
  removeButton: {
    width: '200px'
  },
  nextBillText: {
    margin: '10px'
  },
  nextBillWrapper: {
    alignItems: 'center',
    display: 'flex'
  }
}));

const SubscriptionEdit = (props) => {
  const classes = useStyles();
  const { match, client, history } = props;
  const { params } = match;
  const { subscriptionId, customerId } = params;
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addButtonClicked, setAddButtonClicked] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [subPaymentMethodValues, setSubPaymentMethodValues] = useState([]);
  const [availableProducts, setAvailableProducts] = useState(null);
  const [subProductValues, setSubProductValues] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editNextBillDialogOpen, setEditNextBillDialog] = useState(false);
  const auth = useContext(authContext);
  const {
    loading: subscriptionLoading,
    data: subscriptionData,
    error: subscriptionError
  } = useQuery(getSubscriptionQuery, {
    client: props.client,
    variables: { subscriptionId: params.subscriptionId },
    fetchPolicy: 'no-cache'
  });

  const { data: productData } = useQuery(searchSubscriptionProducts, {
    variables: { input: { status: 'active', searchTerm: searchInput } },
    fetchPolicy: 'no-cache'
  });

  const { loading: nextBillDataLoading, data: nextBillData } = useQuery(getNextBill, {
    variables: {
      input: {
        subscriptionId
      }
    }
  });

  const { loading: paymentMethodDataLoading, data: paymentMethodData } = useQuery(getPaymentMethodsQuery, {
    client: '',
    variables: {
      input: { externalId: props.customerId || customerId }
    }
  });

  console.log('data', {
    loading: paymentMethodDataLoading,
    data: paymentMethodData
  });

  const [editSubscription] = useMutation(editSubscriptionMutation, {
    client: props.client,
    refetchQueries: [
      {
        query: getSubscriptionsByCustomerIdQuery,
        variables: { customerId }
      }
    ]
  });

  const [editNextBill] = useMutation(editNextBillMutation, {});

  const fetchAvailableProducts = useCallback(async () => {
    const filteredProducts = [];
    const products = _.get(productData, 'products', []);
    const _products = filterExisting(products, subProductValues, 'id');
    const _subscription = subscriptionData.getSubscription;
    setCurrentSubscription(_subscription);

    _products.forEach((product) => {
      const matchingPrice = product.prices.find(
        (price) => price.frequency === _subscription.frequency
          && price.currency.name === _subscription.currency.name
      );
      if (matchingPrice) {
        filteredProducts.push(product);
      }
    });

    setAvailableProducts(filteredProducts);
    setAddButtonClicked(true);
  }, [subProductValues, productData, subscriptionData]);

  useEffect(() => {
    if (addButtonClicked === true) {
      fetchAvailableProducts();
    }
  }, [addButtonClicked, fetchAvailableProducts, subProductValues, searchInput]);

  const handleCancelForm = () => {
    history.push(`/customer/${customerId}`);
  };

  const handleServiceAddress = (data) => {
    const serviceAddressKeys = [
      'service_place',
      'service_address',
      'service_address2',
      'service_locality',
      'service_region',
      'service_postal'
    ];

    const serviceAddressMeta = _.reduce(serviceAddressKeys, (results, key) => {
      const value = _.get(data, key, '');

      if (value !== '') results.push({ key, value });

      return results;
    }, []);

    return serviceAddressMeta;
  };

  const handleSearch = (event) => {
    const { target } = event;
    const { value } = target;

    setSearchInput(value);
  };

  const filterExisting = (allItems, existingItems, key) => {
    if (!existingItems || existingItems.length === 0) {
      return allItems;
    }
    // these lodash calls return an array of the allItems argument, excluding the
    // items have a matching key argument with an item from the existingItems argument.
    return _.values(
      _.omit(_.keyBy(allItems, key), _.keys(_.keyBy(existingItems, key)))
    );
  };

  const handleSavePaymentMethods = (formValues) => {
    _.set(formValues, 'paymentMethods', subPaymentMethodValues);
  };

  const handleSaveProducts = (formValues) => {
    _.set(formValues, 'products', subProductValues);
  };

  const removePaymentMethod = (event) => {
    const { currentTarget } = event;
    const { id } = currentTarget;
    const indexToDelete = id.split('-')[1];
    const _subPaymentMethods = subPaymentMethodValues
      ? [].concat(subPaymentMethodValues)
      : [];
    _subPaymentMethods.splice(indexToDelete, 1);
    setSubPaymentMethodValues(_subPaymentMethods);
  };

  const removeProduct = (event) => {
    const { currentTarget } = event;
    const { id } = currentTarget;
    const indexToDelete = id.split('-')[1];
    const _subProducts = subProductValues ? [].concat(subProductValues) : [];
    _subProducts.splice(indexToDelete, 1);
    setSubProductValues(_subProducts);
  };

  const handleAddPaymentMethod = (checked, selectedMethod) => {
    const _subPaymentMethods = [].concat(subPaymentMethodValues);
    if (checked) {
      setSubPaymentMethodValues([].concat(selectedMethod));
    }
    if (!checked) {
      const indexToDelete = _subPaymentMethods.findIndex(
        (item) => item.token === selectedMethod.token
      );
      _subPaymentMethods.splice(indexToDelete, indexToDelete + 1);
      setSubPaymentMethodValues(_subPaymentMethods);
    }
  };

  const handleAddProduct = (event) => {
    const indexToAdd = event[0].index;
    const selectedProduct = availableProducts[indexToAdd];
    const {
      description,
      merchantId,
      merchantProductId,
      sku,
      type,
      id,
      prices
    } = selectedProduct;
    const matchingPrice = prices.find(
      (price) => price.frequency === currentSubscription.frequency
        && price.currency.name === currentSubscription.currency.name
    );

    const productItem = {
      amount: matchingPrice.amount,
      currency: matchingPrice.currency,
      description,
      frequency: matchingPrice.frequency,
      id,
      merchantId,
      merchantProductId,
      sku,
      type
    };
    const _subProductValues = [].concat(subProductValues);
    _subProductValues.push(productItem);
    setSubProductValues(_subProductValues);
  };

  const handleMutation = async (data) => {
    setLoading(true);
    const serviceAddressMeta = handleServiceAddress(data);
    handleSavePaymentMethods(data);
    const { nextBillDate, paymentMethods } = data;
    const _paymentMethods = paymentMethods && paymentMethods.length > 0
      ? cleanTypeName(paymentMethods)
      : [];

    handleSaveProducts(data);
    const _products = cleanTypeName(data.products);

    let _metadata = cleanTypeName(_.get(data, 'metadata', []));
    _metadata = [..._metadata, ...serviceAddressMeta];

    const variables = {
      input: {
        metadata: _metadata,
        subscriptionId: params.subscriptionId,
        start: data.start,
        total: data.total,
        end: data.end,
        products: _products,
        paymentMethodIds: _paymentMethods.map((item) => item.id),
        merchantSubscriptionId: 'fix', // todo: implement field after fixing stg
      }
    };

    const nextBillVariables = {
      input: {
        date: nextBillDate,
        subscriptionId: params.subscriptionId
      }
    };

    await editSubscription({ variables });
    await editNextBill({ variables: nextBillVariables });
    setLoading(false);
    history.push(`/customer/${customerId}`);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseNextBillDialog = () => {
    setEditNextBillDialog(false);
  };

  if (subscriptionLoading) {
    return (
      <Fragment>
        <Typography variant="h1">Edit Subscription</Typography>
        <CircleProgress />
      </Fragment>
    );
  }

  if (subscriptionError) {
    return (
      <Fragment>
        <Typography variant="h1">Edit Subscription</Typography>
        <p>There was an error: {subscriptionError.message}</p>
      </Fragment>
    );
  }

  if (subscriptionData) {
    const { getSubscription: subscription } = subscriptionData;
    const {
      total, metadata, products, paymentMethods, paymentMethodIds, start, status
    } = subscription;
    if (status !== 'active') {
      return <p>Only active subscriptions can be edited.</p>;
    }

    const _paymentMethods = paymentMethods.filter((item) => paymentMethodIds.includes(item.id));

    const nextBillDate = nextBillDataLoading
      ? null
      : nextBillData.getNextBillBySubscriptionId[0]?.nextBill;

    const initialFormValues = {
      metadata: metadata || [],
      _paymentMethods,
      nextBillDate,
      products,
      start,
      total
    };

    if (!loaded) {
      setSubPaymentMethodValues(_paymentMethods);
      setSubProductValues(products);
      setCurrentSubscription(subscription);
      setLoaded(true);
    }
    return (
      <Fragment>
        <Typography variant="h1">Edit Subscription</Typography>
        <Can
          groups={auth.groups}
          perform="subscription:cancel"
          yes={() => {
            if (nextBillData.getNextBillBySubscriptionId[0]?.nextBill) {
              return (
                <RemoveButton
                  label="Cancel Subscription"
                  className={classes.removeButton}
                  onClick={() => setDialogOpen(true)}
                />
              );
            }
            return (
              'Subscription cancelled'
            );
          }}
        />
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          onSubmit={handleMutation}
          render={({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            values
          }) => {
            const updateNextBillDate = (date) => {
              setFieldValue('nextBillDate', date);
            };
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className={classes.section}>
                      <Typography variant="h3">Payment Methods</Typography>
                      <AddExistingPaymentMethod
                        customerId={params.customerId}
                        subscriptionPaymentMethods={subPaymentMethodValues}
                        handleChange={handleAddPaymentMethod}
                        values={values}
                      />
                      <RemovePaymentMethodForm
                        subscriptionPaymentMethods={subPaymentMethodValues}
                        removePaymentMethod={removePaymentMethod}
                        values={values}
                      />
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.section}>
                      <div className={classes.buttonContainer}>
                        <Typography variant="h3">Service Address</Typography>
                      </div>
                      <AddServiceAddress
                        handleChange={handleChange}
                        values={values}
                      />
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.section}>
                      <div className={classes.buttonContainer}>
                        <Typography variant="h3">Products</Typography>
                        {addButtonClicked === false ? (
                          <AddButton
                            label="Add to Subscription"
                            onClick={fetchAvailableProducts}
                          />
                        ) : null}
                      </div>
                      <AddProducts
                        availableProducts={availableProducts}
                        handleChange={handleAddProduct}
                        subProducts={subProductValues}
                        handleSearch={handleSearch}
                        currentSubscription={currentSubscription}
                      />
                      <RemoveProducts
                        subProducts={subProductValues}
                        removeProduct={removeProduct}
                        fetchAvailableProducts={fetchAvailableProducts}
                        availableProducts={availableProducts}
                        values={values}
                      />
                    </div>
                  </div>
                  <Divider className={classes.divider} />
                  <Typography variant="h3">Next Bill Date:</Typography>
                  {
                    nextBillDataLoading
                      ? <CircleProgress />
                      : (
                        <div className={classes.nextBillWrapper}>
                          <Typography className={classes.nextBillText} variant="body1">{ISOToUTC(values.nextBillDate)}</Typography>
                          <Button
                            type="button"
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.saveAndCancel}
                            onClick={() => setEditNextBillDialog(true)}
                          >
                            Edit
                          </Button>
                        </div>
                      )
                  }
                  <Divider className={classes.divider} />
                  <Grid>
                    <MetaData
                      data={values.metadata}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      classes={classes}
                      errors={errors}
                    />
                  </Grid>
                  <Divider className={classes.divider} />
                  <div className={classes.buttonContainer}>
                    <Button
                      type="submit"
                      onClick={() => {
                        handleSubmit(values);
                      }}
                      variant="contained"
                      size="medium"
                      color="primary"
                      disabled={loading}
                      className={classes.saveAndCancel}
                    >
                      Save
                    </Button>
                    <Button
                      type="button"
                      onClick={handleCancelForm}
                      variant="outlined"
                      size="medium"
                      color="primary"
                      className={classes.saveAndCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
                <EditNextBillDialog
                  currentNextBillDate={ISOToUTC(values.nextBillDate)}
                  dialogOpen={editNextBillDialogOpen}
                  handleClose={handleCloseNextBillDialog}
                  updateNextBillDate={updateNextBillDate}
                />
              </>
            );
          }}
        />
        < CancelDialog
          handleClose={handleCloseDialog}
          dialogOpen={dialogOpen}
          client={client}
          history={history}
          subscriptionId={subscriptionId}
          customerId={customerId}
        />
      </Fragment >
    );
  }

  return null;
};

export default SubscriptionEdit;
