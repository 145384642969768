import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import CircleProgress from '../../../components/progress/circle';
import IFrame from '../../../components/iframe';
import './payment-method.scss';

import Constants from '../../../constants';

const { vault } = Constants;
const { FORM_URL } = vault;

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  textField: {
    marginBottom: '5px',
    marginRight: '0px',
    width: '100%'
  },
  formRow: {
    display: 'flex',
    margin: '0px',
    width: '100%'
  },
  mediumInput: {
    flexBasis: '75%'
  },
  smallInput: {
    flexBasis: '25%'
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: '10px'
  },
  firstInput: {
    marginRight: '10px'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  saveButton: {
    position: 'relative',
    marginTop: '30px'
  },
  cancelButton: {
    marginTop: '30px',
    marginLeft: '10px'
  },
  progress: {
    position: 'absolute',
    top: '30%'
  }
}));

const PaymentMethod = (props) => {
  const [routingError, setRoutingError] = useState('');

  const classes = useStyles();
  const {
    token,
    resetState,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    variant = 'standard',
    size = 'medium',
    disabled,
    paymentOption,
    setPaymentOption,
    accountType,
    setAccountType
  } = props;

  const routingValidation = (input) => {
    const regex = /^[0-9\b]+$/;
    if (!input || (input.length === 9 && input.match(regex))) {
      return false;
    }
    return true;
  };

  const displayPaymentSpecifics = (input) => {
    switch (input) {
      case 'credit':
        return (
          <div className={`iframe-container ${classes.textField} `}>
            <IFrame src={`${FORM_URL}${token}`} />
          </div>
        );
      case 'ach':
        return (
          <div>
            <TextField
              className={`${classes.textField}`}
              size={size}
              label="Routing Number"
              name="routingTransitNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={values.routingTransitNumber || ''}
              onInput={(e) => setRoutingError(e.target.value)}
              error={routingValidation(routingError)}
              variant="outlined"
            />
            <TextField
              className={`${classes.textField}`}
              size={size}
              label="Account Number"
              name="accountNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={values.accountNumber || ''}
              variant="outlined"
            />
            <RadioGroup
              name="accountType"
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
              row
            >
              <FormControlLabel
                value="checking"
                control={<Radio />}
                label="Checking"
              />
              <FormControlLabel
                value="savings"
                control={<Radio />}
                label="Savings"
              />
              <FormControlLabel
                value="business"
                control={<Radio />}
                label="Business"
              />
            </RadioGroup>
          </div>
        );
      case 'manual':
        return null;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {token ? (
        <div className={classes.formContainer}>
          <RadioGroup
            name="paymentOption"
            value={paymentOption}
            onChange={(e) => setPaymentOption(e.target.value)}
            row
          >
            <FormControlLabel value="ach" control={<Radio />} label="ACH" />
            <FormControlLabel
              value="credit"
              control={<Radio />}
              label="Credit Card"
            />
            {props.showManual && (
              <FormControlLabel
                value="manual"
                control={<Radio />}
                label="Manual Payment"
              />
            )}
          </RadioGroup>
          {displayPaymentSpecifics(paymentOption)}
          <TextField
            className={`${classes.textField}`}
            size={size}
            variant={variant}
            label="Account Holder First Name"
            name="accountHolderFirstName"
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            value={values.accountHolderFirstName}
            required
          />
          <TextField
            className={`${classes.textField}`}
            size={size}
            variant={variant}
            label="Account Holder Last Name"
            name="accountHolderLastName"
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            value={values.accountHolderLastName}
            required
          />
          <TextField
            className={`${classes.textField}`}
            size={size}
            variant={variant}
            label="Billing Address"
            name="billingAddress"
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            value={values.billingAddress || ''}
          />
          <TextField
            className={`${classes.textField}`}
            variant={variant}
            size={size}
            label="Address 2"
            name="billingAddress2"
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            value={values.billingAddress2 || ''}
          />
          <TextField
            className={`${classes.textField}`}
            variant={variant}
            size={size}
            label="Billing City"
            name="billingLocality"
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            value={values.billingLocality || ''}
          />
          <div className={`${classes.formRow} `}>
            <TextField
              className={`${classes.smallInput} ${classes.firstInput}`}
              variant={variant}
              size={size}
              label="State"
              name="billingRegion"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={values.billingRegion || ''}
            />
            {paymentOption === 'credit' ? (
              <TextField
                className={`${classes.mediumInput}`}
                variant={variant}
                size={size}
                label="Billing Zip Code"
                name="billingPostal"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                value={values.billingPostal || ''}
                required
              />
            ) : (
              <TextField
                className={`${classes.mediumInput}`}
                variant={variant}
                size={size}
                label="Billing Zip Code"
                name="billingPostal"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                value={values.billingPostal || ''}
              />
            )}
          </div>

          {/*
          note: if this is a stand alone form (like on view customer)
          a submit method will be passed into this component as props
          */}
          <div className={`${classes.buttonContainer}`}>
            <FormControl className={classes.saveButton}>
              {handleSubmit ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  Add Payment Method
                </Button>
              ) : null}
              {disabled && (
                <CircleProgress className={classes.progress} size={20} />
              )}
            </FormControl>
            {!disabled ? (
              <Button
                variant="outlined"
                className={classes.cancelButton}
                onClick={resetState}
              >
                Cancel
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default PaymentMethod;
