import { gql } from '@apollo/client';

export default gql`
  query searchSubscriptionsQuery($input: QuerySubscriptionsInput) {
    subscriptions: searchSubscriptions(input: $input) {
      id
      externalId
      created
      modified
      start
      total
      end
      status
      nextBill {
        date
      }
      # customer {
      #   id
      #   externalId
      #   firstName
      #   lastName
      #   email
      #   phone
      #   address
      #   address2
      #   locality
      #   region
      #   postal
      #   country
      #   metadata {
      #     key
      #     value
      #   }
      # }
      cancellationReason {
        name
        code
        category
      }
      cancellationNote
      frequency
      collectionMethod
      currency {
        name
        symbol
      }
      paymentMethodIds
      metadata {
        key
        value
      }
      paymentMethods {
        id
        category
        token
        brand
        issuer_id
        last
        expiration
        billingDetails {
          address
          address2
          locality
          region
          postal
          country
        }
      }
      products {
        id
        merchantId
        merchantProductId
        sku
        type
        description
        amount
        frequency
        currency {
          name
          symbol
        }
      }
    }
  }
`;
