import React, { Fragment, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { gql, useQuery, useMutation } from '@apollo/client';

import addProductMutation from './mutations/addProductMutation';
import { cleanProductForMutation } from '../utils';
import cleanTypeName from '../utils/cleanTypeName';
import getProducts from '../products/queries/searchProducts';

import ProductGrants from './form/grants';
import ProductGrid from '../products/products-grid';
import ProductMetadata from './form/metadata';
import ProductPrices from './form/prices';
import ProductSchema from './form/validation-schema';
import ProductTypeSelector from './select-product-type';
import MerchantProductIdInput from './form/merchant-product-id-input';
import SaveButton from '../../components/buttons/save-button';

import './product-form.scss';

const getPriceConfigQuery = gql`
  {
    getPriceConfig {
      frequencies
      currencies {
        name
        symbol
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  textMultiline: {
    width: '100%',
    minHeight: '3em'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  formControl: {
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  firstInput: {
    marginLeft: 0,
    paddingLeft: 0
  },
  formSection: {
    margin: '20px 0'
  },
  sectionItem: {
    marginTop: 0
  },
  errorText: {
    color: theme.palette.error.main,
    margin: 0
  }
}));

const initialFormValues = {
  merchantProductId: '',
  taxCode: '',
  glCode: '',
  sku: '',
  type: 'base',
  description: undefined,
  metadata: [],
  prices: [
    {
      frequency: '',
      currency: '',
      amount: 0
    }
  ],
  products: [],
  grants: []
};

const ProductAdd = (props) => {
  const { history } = props;
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [_priceConfig, setPrices] = useState([]);
  const classes = useStyles();
  const { loading, data } = useQuery(getPriceConfigQuery);
  const [updateProduct] = useMutation(addProductMutation, {
    update: (store, { data: { addProduct } }) => {
      // todo: handle errors appropriately. dont update cache with dirty state
      try {
        const cacheData = store.readQuery({
          query: getProducts,
          variables: { input: { searchTerm: '' } }
        });
        const _newCacheData = [...cacheData.products, addProduct];
        const itemIndex = _newCacheData.findIndex(
          (item) => item.id === addProduct.id
        );
        _newCacheData.splice(itemIndex, 1, addProduct);
        store.writeQuery({
          query: getProducts,
          variables: { input: { searchTerm: '' } },
          data: {
            products: _newCacheData
          }
        });
        history.push('/products');
      } catch (error) {
        history.push('/products');
      }
    }
  });

  useEffect(() => {
    if (!loading) setPrices(data.getPriceConfig);
  }, [loading, data]);

  const submitForm = async (formData) => {
    const { type } = formData;
    const _prices = formData.prices.map((price) => {
      const _frequency = type === 'onetime' ? 'once' : price.frequency;
      const _currency = _priceConfig.currencies.find(
        (item) => price.currency === item.name
      );
      return {
        ...price,
        currency: _currency,
        frequency: _frequency
      };
    });

    const _data = {
      ...formData,
      taxCode: formData.taxCode || null,
      glCode: formData.glCode || null,
      prices: _prices,
      products: selectedProducts || null
    };
    const cleanedData = cleanTypeName(_data);

    const variables = {
      input: cleanedData
    };
    await updateProduct({ variables: { ...variables } });

    // history.push('/products');
  };

  const handleSelectProduct = (selectedProduct) => {
    const product = cleanProductForMutation(selectedProduct);
    const idx = selectedProducts.findIndex((item) => item.id === product.id);
    const _products = [].concat(selectedProducts);

    if (idx === -1) {
      _products.push(product);
      setSelectedProducts(_products);
    } else {
      _products.splice(idx, 1);
      setSelectedProducts(_products);
    }
  };

  return (
    <Fragment>
      <Typography variant="h1">Add Product</Typography>
      <div className="product-form">
        <Formik
          validationSchema={ProductSchema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialFormValues}
          onSubmit={submitForm}
          render={({
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting
          }) => {
            const { type } = values;
            const productGrid = type && type === 'bundle' ? (
                <ProductGrid
                  onChange={handleSelectProduct}
                  selectedProducts={selectedProducts}
                />
            ) : null;

            return (
              <form onSubmit={handleSubmit}>
                <div className="product-form-row">
                  <MerchantProductIdInput
                    classes={classes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors}
                    values={values}
                  />
                  <FormControl className={classes.formControl}>
                    <TextField
                      className={classes.textField}
                      label="SKU"
                      name="sku"
                      required
                      margin="normal"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      value={values.sku || ''}
                    />
                    <FormHelperText className={classes.errorText}>
                      {!values.sku || values.sku === '' ? errors.sku : null}
                    </FormHelperText>
                  </FormControl>
                  <ProductTypeSelector
                    name="type"
                    onChange={handleChange}
                    values={values}
                  />
                </div>
                <div className={'product-form-row'}>
                  {/* <FormControl className={classes.formControl}>
                    <TextField
                      className={classes.textField}
                      label="Merchant Id"
                      name="merchantId"
                      required
                      margin="normal"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      value={values.merchantId || ''}
                    />
                    <FormHelperText className={classes.errorText}>
                      {!values.merchantId || values.merchantId === '' ? errors.merchantId : null}
                    </FormHelperText>
                  </FormControl> */}
                  <FormControl className={classes.formControl}>
                    <TextField
                      className={classes.textField}
                      label="Tax Code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.taxCode || ''}
                      name="taxCode"
                      type="text"
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className={classes.textField}
                      label="GL Code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.glCode || ''}
                      name="glCode"
                      type="text"
                    />
                  </FormControl>
                  <FormHelperText></FormHelperText>
                </div>
                <div className={'product-form-row'}>
                  <FormControl className={classes.formControl} fullWidth={true}>
                    <TextField
                      id="description"
                      label="Internal Product Description"
                      multiline
                      rowsMax="10"
                      value={
                        values.description === null ? '' : values.description
                      }
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.textMultiline}
                      margin="dense"
                    />
                    <FormHelperText className={classes.errorText}>
                      {!values.description || values.description === ''
                        ? errors.description
                        : null}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className={`product-form-row ${classes.formSection}`}>
                  <ProductPrices
                    data={values.prices}
                    onChange={handleChange}
                    errors={errors.prices}
                    productType={values.type}
                  />
                </div>
                <div className={`product-form-row ${classes.formSection}`}>
                  <ProductMetadata
                    data={values.metadata}
                    classes={classes}
                    onChange={handleChange}
                    errors={errors.metadata}
                  />
                </div>
                <div className={`product-form-row ${classes.formSection}`}>
                  <ProductGrants
                    data={values.grants}
                    classes={classes}
                    onChange={handleChange}
                    errors={errors.grants}
                  />
                </div>
                <div className="product-form-row">{productGrid}</div>
                <SaveButton
                  label="Save Product"
                  onClick={() => handleSubmit(values)}
                />
              </form>
            );
          }}
        />
      </div>
    </Fragment>
  );
};

export default ProductAdd;
