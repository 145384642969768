import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './circle-check-button.scss';

const CircleCheck = (props) => {
  const { label, onClick } = props;

  return (
    <div className="circle-check-button" onClick={onClick}>
      <IconButton
        aria-label="activate"
        size="small"
        className="circe-check-icon"
      >
        <CheckCircleIcon fontSize="small" htmlColor="#388e3c" />
      </IconButton>
      <div className="circle-check-label">{label}</div>
    </div>
  );
};

export default CircleCheck;
