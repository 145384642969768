import React, { useEffect, useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

import { authContext } from '../../contexts/authContext';

import './change-password.scss';

const passwordResetName = 'PasswordResetRequiredException';

const ChangePassword = (props) => {
  const context = useContext(authContext);
  const [code, setCode] = useState();
  const [password, setPassword] = useState();
  const [errorText, setErrorText] = useState();
  const [challengeName, setChallengeName] = useState();
  const { history } = props;
  const { authErrorText, completeNewPassword, authChallenge } = context;

  useEffect(() => {
    setChallengeName(authChallenge);
    if (authErrorText !== 'not authenticated') {
      setErrorText(authErrorText);
    }
  }, [authErrorText, challengeName, authChallenge]);

  const handleChange = (event) => {
    event.preventDefault();
    const { currentTarget } = event;
    const { name, value } = currentTarget;

    switch (name) {
      case 'password':
        setPassword(value);
        break;
      case 'code':
        setCode(value);
        break;
      default:
        break;
    }
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();

    if (!password) return null;

    // if (challengeName === passwordResetName && !code) {
    //   return null;
    // }

    return completeNewPassword({ password, code }, history);
  };

  const renderCodeField = () => (
      <TextField
        autoComplete="code off"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="code"
        label="Code"
        type="text"
        id="code"
        value={code}
        onChange={handleChange}
      />
  );

  const renderCode = challengeName === passwordResetName ? renderCodeField() : null;

  return (
    <div className="change-password">
      <Typography component="h1" variant="h5">
        Enter a new password:
      </Typography>
      {renderCode}
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        onChange={handleChange}
        autoComplete="off"
      />
      <FormHelperText className="error" id="component-error-text">
        {errorText}
      </FormHelperText>
      <Button
        type="button"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleChangePassword}
      >
        Change Password
      </Button>
    </div>
  );
};

export default ChangePassword;
