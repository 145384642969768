import React, { useState } from 'react';
import { Formik } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

import AcquirerSchema from './validation-schema';

// todo: user/pwd can drop once the vault stores that info
const initialFormValues = {
  acquirerId: '',
  merchantId: '',
  user: '',
  password: ''
};

const useStyles = makeStyles({
  formControl: {
    width: '100%'
  },
  root: {
    '& .MuiFormControl-root': {
      marginTop: 7,
      marginBottom: 7,
      marginRight: 7
    },
    '& .MuiInputBase-root': {
      width: 450
    }
  }
});

const AcquirerForm = (props) => {
  const { saveMerchantId, acquirer } = props;
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);

  initialFormValues.acquirerId = acquirer.id;

  const handleSave = (data) => {
    setIsSaving(true);
    saveMerchantId(data);
  };

  return (
    <div className={classes.root}>
      <Formik
        validationSchema={AcquirerSchema}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={initialFormValues}
        onSubmit={handleSave}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
            <form onSubmit={handleSubmit}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Merchant ID"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.merchantId || ''}
                  name="merchantId"
                  type="text"
                />
                <FormHelperText>
                  {touched.merchantId && errors.merchantId}
                </FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.user || ''}
                  name="user"
                  type="text"
                />
                <FormHelperText>{touched.user && errors.user}</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password || ''}
                  name="password"
                  type="password"
                />
                <FormHelperText>
                  {touched.password && errors.password}
                </FormHelperText>
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                disabled={isSaving}
                disableElevation
                onClick={handleSubmit}
              >
                Add Merchant Id to Acquirer
              </Button>
            </form>
        )}
      />
    </div>
  );
};

export default AcquirerForm;
