import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function Error404Screen() {
  const [error, setError] = useState({
    title: 'Oops! Something went wrong.',
    message: 'Please try again later or contact support.'
  });

  // Get the error code from the URL - from useLocation
  const location = useLocation();
  const errorCode = location.pathname.split('/').pop();
  useEffect(() => {
    switch (errorCode) {
      case '404':
        setError({
          title: 'Oops! Something went wrong.',
          message: 'Please try again later or contact support.'
        });
        break;
      default:
        break;
    }
  }, [errorCode]);

  return (
    <div className="invoices">
        <p>{error.title}</p>
        <p>{error.message}</p>
    </div>
  );
}
