import React from 'react';
import { FieldArray } from 'formik';
import { FormControl, FormHelperText } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AddButton from '../../../components/buttons/add-button';
import RemoveButton from '../../../components/buttons/remove-button';

import './customer-metadata.scss';

const CustomerMetadataForm = (props) => {
  const {
    data, onChange, errors, handleBlur
  } = props;

  return (
    <FieldArray
      name="metadata"
      validateOnChange={false}
      validateOnBlur={false}
      render={(arrayHelpers) => (
          <div className="customer-metadata">
            <div className="customer-metadata-header">
              <Typography variant="h3">Metadata</Typography>
              <AddButton
                label="Add Item"
                onClick={() => arrayHelpers.push({ key: '', value: '' })}
              />
            </div>
            {data && data.length > 0
              ? data.map((item, index) => {
                const _error = errors.metadata && errors.metadata[index];
                const keyError = _error && _error.key ? _error.key : null;
                const valueError = _error && _error.value ? _error.value : null;
                return (
                    <div className="metadata-row" key={index}>
                      <FormControl>
                        <TextField
                          label="Key"
                          name={`metadata.${index}.key`}
                          margin="normal"
                          type="text"
                          value={item.key}
                          onChange={onChange}
                          onBlur={handleBlur}
                          className="first-input"
                        />
                        <FormHelperText className="error-text">
                          {!item.key || item.key === '' ? keyError : null}
                        </FormHelperText>
                      </FormControl>
                      <FormControl>
                        <TextField
                          onChange={onChange}
                          onBlur={handleBlur}
                          value={item.value}
                          label="Value"
                          margin="normal"
                          type="text"
                          name={`metadata.${index}.value`}
                        />
                        <FormHelperText className="error-text">
                          {!item.value || item.value === '' ? valueError : null}
                        </FormHelperText>
                      </FormControl>
                      <RemoveButton
                        style={{ paddingTop: '1.6em' }}
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      />
                    </div>
                );
              })
              : null}
          </div>
      )}
    />
  );
};

export default CustomerMetadataForm;
