import { parseISO } from 'date-fns';

const convertISOToUTC = (dateISO) => {
  const dateObj = new Date(parseISO(dateISO));
  if (!dateObj) {
    return '';
  }
  return `${dateObj.getUTCMonth() + 1}/${dateObj.getUTCDate()}/${dateObj.getUTCFullYear()}`;
};
export default convertISOToUTC;
