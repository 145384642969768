import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import RemoveButton from '../../../components/buttons/remove-button';

const useStyles = makeStyles((theme) => ({
  table: {
    width: 'auto'
  },
  productPriceSelect: {
    minWidth: '200px'
  },
  productPriceOption: {
    cursor: 'pointer',
    padding: '10px'
  },
  textField: {
    // marginBottom: '5px',
    marginRight: '0px',
    width: '100%'
  },
}));

const renderFrequencyOptions = (classes, prices) => prices.map((priceItem) => (
  <MenuItem
    className={classes.productPriceOption}
    key={priceItem.frequency}
    value={priceItem.frequency}
  >
    {priceItem.frequency}
  </MenuItem>
));

const AddNewProductToSubscription = (props) => {
  const { product, onClick, onRemove } = props;
  const { prices } = product;

  const [amount, setAmount] = useState('');
  const [frequency, setFrequency] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [currencyName, setCurrencyName] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [currencyToSave, setCurrencyToSave] = useState({});

  const classes = useStyles();
  const options = renderFrequencyOptions(classes, prices);

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { value } = target;
    const price = prices.find((priceItem) => priceItem.frequency === value);
    if (price) {
      const { currency } = price;
      const { name, symbol } = currency;
      setFrequency(value);
      setAmount(price.amount);
      setCurrencyName(name);
      setCurrencySymbol(symbol);
      setCurrencyToSave({ name, symbol });
    }
  };

  const handleAdd = (event) => {
    event.preventDefault();
    console.log({ qty });
    const quantity = parseInt(qty, 10);
    // todo: we should be able to spread product, but this may be done for a reason?
    const newProduct = {
      id: product.id,
      merchantId: product.merchantId,
      merchantProductId: product.merchantProductId,
      sku: product.sku,
      type: product.type,
      description: product.description,
      metadata: product.metadata,
      amount,
      frequency,
      currency: currencyToSave
    };
    setDisabled(true);
    if (qty < 1) onClick([newProduct]);
    else {
      const newArray = [];
      for (let i = 0; i < quantity; i += 1) {
        newArray.push(newProduct);
      }
      onClick(newArray);
    }
  };

  const [qty, setQty] = useState(1);

  const showAddAction = frequency && amount ? (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={handleAdd}
      >
        Add Product
      </Button>
  ) : null;
  return (
    <Table key={product.id} className={classes.table}>
      <TableBody>
        <TableRow>
          <TableCell style={{ borderBottom: 0 }}>
            {product.merchantProductId}
          </TableCell>
          <TableCell style={{ borderBottom: 0 }}>
            <MenuItem variant="standard">
              <InputLabel id="frequency-select-label">Frequency</InputLabel>
              <Select
                className={classes.productPriceSelect}
                value={frequency}
                onChange={handleChange}
                labelId='frequency-select-label'
                label='Frequency'
              >
                {options}
              </Select>
            </MenuItem>
          </TableCell>
          <TableCell style={{ borderBottom: 0 }}>
            <TextField
              className={`${classes.textField}`}
              size={'medium'}
              variant={'standard'}
              label="Quantity"
              name="quantity"
              onChange={(event) => setQty(event.currentTarget.value)}
              type="number"
              // value={_.get(values, 'service_place', convertKey('service_place'))}
            />
          </TableCell>
          <TableCell style={{ borderBottom: 0 }}>
            {`${currencySymbol}${amount} ${currencyName}`}
          </TableCell>
          <TableCell style={{ borderBottom: 0 }}>
            <RemoveButton onClick={() => onRemove(product.id)} />
          </TableCell>
          <TableCell style={{ borderBottom: 0 }}>{showAddAction}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default AddNewProductToSubscription;
