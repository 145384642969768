import { gql } from '@apollo/client';

export default gql`
  mutation editNextBillMutation($input: NextBillInput) {
    editNextBill(input: $input) {
      date
      subscriptionId
      nextBill
    }
  }
`;
