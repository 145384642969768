import { gql } from '@apollo/client';

export default gql`
  query getInvoicesByStatusQuery($input: GetByStatusInput) {
    invoices: getInvoicesByStatus(input: $input) {
      id
      number
      created
      balance
      customerId
      customerSnapshot
    }
  }
`;
