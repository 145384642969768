import React from 'react';
import MUIDataTable from 'mui-datatables';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '15px',
    fontWeight: 'bold',
    fontSize: '.9em'
  },
  searchBar: {
    width: '300px',
    marginTop: '25px'
  }
}));

const columns = ['Merchant Product Id', 'SKU', 'Description', 'Price', ''];

const AddSubscriptionProducts = (props) => {
  const classes = useStyles();
  const {
    availableProducts,
    handleChange,
    handleSearch,
    currentSubscription
  } = props;

  const options = {
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    pagination: true,
    filter: false,
    disableToolbarSelect: true,
    rowHover: true,
    selectableRowsHeader: false,
    onRowsSelect: handleChange,
    rowsPerPageOptions: [[5, 10, 25]]
  };

  if (!availableProducts) {
    return null;
  }
  if (availableProducts.length === 0) {
    return <p className={classes.empty}>No other products available</p>;
  }

  const getProductRow = (product, index) => {
    const {
      merchantProductId, sku, description, prices
    } = product;
    const matchingPrice = prices.find(
      (price) => price.frequency === currentSubscription.frequency
        && price.currency.name === currentSubscription.currency.name
    );
    const { frequency, currency, amount } = matchingPrice;
    const priceForDisplay = `${currency.symbol}${amount} ${currency.name} ${frequency}`;

    return [merchantProductId, sku, description, priceForDisplay];
  };

  const rows = availableProducts.map((product, index) => getProductRow(product, index));

  return (
    <div>
      <Typography variant="subtitle2" className={classes.title}>
        Available Products
      </Typography>
      <TextField
        className={classes.searchBar}
        placeholder="search by id, sku or description"
        onChange={(event) => {
          handleSearch(event);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
      <MUIDataTable data={rows} columns={columns} options={options} />
      <Divider />
    </div>
  );
};

export default AddSubscriptionProducts;
