import React from 'react';

import './index.scss';

const ENV_MAPPING = {
  dev: 'development',
  stg: 'staging',
};

const EnvHeader = () => {
  const stage = process.env.REACT_APP_STAGE;
  console.log('app staging:', stage);

  return (
    stage !== 'prd' && (
      <div className='env-banner'>
        You are working in your {ENV_MAPPING[stage]} environment.
      </div>
    )
  );
};

export default EnvHeader;
