import _ from 'lodash';
import React, { useEffect, useReducer } from 'react';
import { Auth } from 'aws-amplify';

import { AuthProvider } from '../../contexts/authContext';
import useCookie from '../../hooks/use-cookie';
import AuthReducer from '../../reducers/auth';

const initialState = {
  authChallenge: undefined,
  authErrorText: undefined,
  inFlight: false,
  user: {},
  groups: []
};
const _expireCookie = 'Thu, 01 Jan 1970 00:00:00 GMT';

const AppAuth = (props) => {
  const token = localStorage.getItem('token');
  const [authenticated, setAuthenticated] = useCookie('authenticated', !token);
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const { inFlight, user, authErrorText } = state;

  const refreshSession = (User, refreshToken) => new Promise((resolve, reject) => {
    User.refreshSession(refreshToken, (err, session) => {
      if (err) return reject(err);

      return resolve(session);
    });
  });

  useEffect(() => {
    const checkForAuthenticatedUser = async () => {
      dispatch({ type: 'auth-request' });

      try {
        const User = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        await refreshSession(User, session.refreshToken);

        return dispatch({ type: 'auth-success', payload: User });
      } catch (err) {
        setAuthenticated(false, { expires: _expireCookie });
        const _msg = err.message ? err.message : err;
        return dispatch({ type: 'auth-error', payload: _msg });
      }
    };

    if (!authErrorText && !inFlight && user && !user.username) {
      checkForAuthenticatedUser();
    }
  }, [authErrorText, inFlight, user, authenticated, setAuthenticated]);

  const initiateLogin = async (data, history) => {
    const { email, password } = data;

    dispatch({ type: 'auth-request' });

    try {
      const User = await Auth.signIn(email, password);
      const { challengeName } = User;

      console.log('Auth Challenge:', { challengeName, user: User });

      switch (challengeName) {
        case 'FORCE_CHANGE_PASSWORD':
        case 'PASSWORD_VERIFIER':
        case 'NEW_PASSWORD_REQUIRED': {
          dispatch({ type: 'auth-challenge', payload: User });
          return history.push('/change-password');
        }
        default: {
          const { signInUserSession } = User;
          const { idToken } = signInUserSession;

          setAuthenticated(true, { expires: idToken.exp });
          dispatch({ type: 'auth-success', payload: User });

          return history.push('/');
        }
      }
    } catch (err) {
      if (err.code === 'PasswordResetRequiredException') {
        dispatch({
          type: 'auth-challenge',
          payload: { user, email, challengeName: err.code }
        });
        return history.push('/change-password');
      }

      setAuthenticated(false, { expires: _expireCookie });
      return dispatch({ type: 'auth-error', payload: err.message });
    }
  };

  /**
   * The user has followed the "Forgot Password" flow and is now
   * providing a new password to replace the previous value
   */
  const changeForgottenPassword = async (data, history) => {
    const { email, code, password } = data;

    console.log('Forgotten Password Change:', { email });

    // Force re-authentication, forgotPasswordSubmit does not return User
    setAuthenticated(false, { expires: _expireCookie });

    return Auth.forgotPasswordSubmit(email, code, password);
  };

  /**
   * An authenticated user is changing their password during the
   * first sign-in attempt. This will only occur once the user
   * has provided the temporary password they were emailed
   */
  const changeTemporaryPassword = async (newPassword) => {
    const User = _.get(user, 'challengeName') === 'NEW_PASSWORD_REQUIRED' ? user : await Auth.currentAuthenticatedUser();

    console.log('Temporary Password Change:', { User });

    return Auth.completeNewPassword(User, newPassword);
  };

  /**
   * An authenticated user is changing the value of their password via
   * the self-care interface and will be providing their existing
   * password and a new password to change the current value
   */
  const changePassword = async (oldPassword, newPassword) => {
    const User = await Auth.currentAuthenticatedUser();

    console.log('Authenticated Password Change:', { User });

    return Auth.changePassword(User, oldPassword, newPassword);
  };

  const completeNewPassword = async (data, history) => {
    _.set(data, 'email', _.get(user, 'email', _.get(data, 'email')));

    const { code, password, oldPassword } = data;

    try {
      console.log('Auth Data:', data);

      let User;

      if (code) User = await changeForgottenPassword(data, history);
      else if (oldPassword) User = await changePassword(oldPassword, password);
      else if (password) User = await changeTemporaryPassword(password);

      console.log('Password Changed:', { User });

      const idToken = _.get(User, 'signInUserSession.idToken', null);

      if (User && !_.isNull(idToken)) {
        setAuthenticated(true, { expires: idToken.exp });
        dispatch({ type: 'auth-success', payload: User });

        return history.push('/');
      }

      return history.push('/sign-in');
    } catch (err) {
      console.log('Error:', { err });

      if (code && password) return { error: true, message: err.message };
      if (oldPassword) return err.message;

      setAuthenticated(false, { expires: _expireCookie });

      if (err.code === 'NotAuthorizedException') {
        return err;
      }

      if (err.code === 'InvalidPasswordException') {
        return dispatch({ type: 'auth-error', payload: err.message });
      }

      if (err.code === 'LimitExceededException') {
        return dispatch({ type: 'auth-error', payload: err.message });
      }

      console.log('Unhandled Error:', { err });

      return false;
    }
  };

  const logout = async (history) => {
    dispatch({ type: 'auth-request' });
    localStorage.setItem('token', undefined);
    setAuthenticated(false, { expires: _expireCookie });
    try {
      await Auth.signOut();
      dispatch({ type: 'auth-sign-out', payload: initialState });
      return history.push('/sign-in');
    } catch (err) {
      return dispatch({ type: 'auth-error', payload: err.message });
    }
  };

  const forgotPassword = async (email) => {
    try {
      console.log({ user });
      const res = await Auth.forgotPassword(email);
      return res;
    } catch (err) {
      return { error: true, message: err.message };
    }
  };

  const authProviderValue = {
    ...state,
    authenticated,
    completeNewPassword,
    initiateLogin,
    logout,
    forgotPassword,
  };

  return (
    <AuthProvider value={authProviderValue}>{props.children}</AuthProvider>
  );
};

export default AppAuth;
