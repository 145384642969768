import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Heading from '../../components/icon-heading';
import { authContext } from '../../contexts/authContext';

import './account-settings.scss';

const passwordResetName = 'PasswordResetRequiredException';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  }
}));

const AccountSettingsPage = (props) => {
  const classes = useStyles();
  const context = useContext(authContext);
  const [code, setCode] = useState();
  const [password, setPassword] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [errorText, setErrorText] = useState();
  const [challengeName, setChallengeName] = useState();
  const { history } = props;
  const { authErrorText, completeNewPassword, authChallenge } = context;

  useEffect(() => {
    setChallengeName(authChallenge);
    if (authErrorText !== 'not authenticated') {
      setErrorText(authErrorText);
    }
  }, [authErrorText, challengeName, authChallenge]);

  const handleChange = (event) => {
    event.preventDefault();
    const { currentTarget } = event;
    const { name, value } = currentTarget;

    switch (name) {
      case 'password':
        setPassword(value);
        break;
      case 'code':
        setCode(value);
        break;
      default:
        break;
    }
  };
  const handleOldPasswordChange = (event) => {
    event.preventDefault();
    const { currentTarget } = event;
    const { name, value } = currentTarget;

    switch (name) {
      case 'oldPassword':
        setOldPassword(value);
        break;
      case 'code':
        setCode(value);
        break;
      default:
        break;
    }
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();

    if (!oldPassword) {
      return null;
    }

    if (!password) {
      return null;
    }

    if (challengeName === passwordResetName && !code) {
      return null;
    }

    const res = await completeNewPassword({ password, code, oldPassword }, history);
    console.log({ res });
    setErrorText(res);

    return false;
  };

  const renderCodeField = () => (
      <TextField
        autoComplete="code off"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="code"
        label="code"
        type="text"
        id="code"
        value={code}
        onChange={handleChange}
      />
  );

  const renderCode = challengeName === passwordResetName ? renderCodeField() : null;

  return (
    <div>
      <Heading title="Account Settings" />
      <Grid container className="account-settings-container">
        <Grid item xs={12} lg={4}>
          <Paper className={classes.paper}>
            <Grid container direction="row" wrap="nowrap" spacing={1}>
              <Grid item lg={12} md={12} sm={12}>
                <Typography variant="h2">Change Password</Typography>
                <span>
                  Enter a new password:
                </span>
                {renderCode}
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="oldPassword"
                  label="Old Password"
                  type="password"
                  id="oldPassword"
                  onChange={handleOldPasswordChange}
                  autoComplete="off"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <FormHelperText className="error" id="component-error-text">
                  {errorText}
                </FormHelperText>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleChangePassword}
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountSettingsPage;
