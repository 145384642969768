import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%'
  },
  listItem: {
    maxWidth: 300
  }
}));

const renderListItems = (props) => {
  const { reasons } = props;
  if (reasons.length === 0) {
    return <p>No cancellation reasons exist</p>;
  }
  return (
    reasons.map((reason) => (
                <ListItem key={reason.code} dense={true} disableGutters={true}>
                    <ListItemText primary={`${reason.code} - ${reason.name}`} secondary={reason.category.toLowerCase()} />
                </ListItem>
    ))

  );
};

const CancellationReasonList = (props) => {
  const classes = useStyles();
  const items = renderListItems(props);

  return (
        <List
            component="nav"
            className={classes.list}
        >
            {items}
        </List>
  );
};

export default CancellationReasonList;
