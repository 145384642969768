const responseCodes = [
  {
    code: 530,
    type: 'Soft Decline',
    sbs_action: 'Retry',
    reason: 'call_issuer',
    description: 'The card was declined for an unknown reason.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 530,
    type: 'Soft Decline',
    sbs_action: 'Retry',
    reason: 'do_not_honor',
    description: 'The card was declined for an unknown reason.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 522,
    type: 'Soft Decline',
    sbs_action: 'Retry',
    reason: 'expired_card',
    description: 'The card has expired.',
    action: 'The customer needs to use another card.'
  },
  {
    code: 303,
    type: 'Soft Decline',
    sbs_action: 'Retry',
    reason: 'generic_decline',
    description: 'The card was declined for an unknown reason or Stripe Radar\u00a0blocked the payment.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 521,
    type: 'Soft Decline',
    sbs_action: 'Retry',
    reason: 'insufficient_funds',
    description: 'The card has insufficient funds to complete the purchase.',
    action: 'The customer needs to use an alternative payment method.'
  },
  {
    code: 301,
    type: 'Soft Decline',
    sbs_action: 'Retry',
    reason: 'issuer_not_available',
    description: 'The card issuer couldn\u2019t be reached, so the payment couldn\u2019t be authorized.',
    action: 'Attempt the payment again. If you still can\u2019t process it, the customer needs to contact their card issuer.'
  },
  {
    code: 303,
    type: 'Soft Decline',
    sbs_action: 'Retry',
    reason: 'no_action_taken',
    description: 'The card was declined for an unknown reason.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 509,
    type: 'Soft Decline',
    sbs_action: 'Retry',
    reason: 'payment_limit_exceeded',
    description: 'The order exceeds a limit on the customer\u2019s account.',
    action: 'The customer needs to resolve the issue with the payment provider before you can attempt the payment again.'
  },
  {
    code: 806,
    type: 'Soft Decline',
    sbs_action: 'Retry',
    reason: 'restricted_card',
    description: 'The customer can\u2019t use this card to make this payment (it\u2019s possible it was reported lost or stolen).',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 530,
    type: 'Soft Decline',
    sbs_action: 'Retry',
    reason: 'try_again_later',
    description: 'The card was declined for an unknown reason.',
    action: `Ask the customer to attempt the payment again. If subsequent payments are declined,
    the customer needs to contact their card issuer for more information.`
  },
  {
    code: 509,
    type: 'Soft Decline',
    sbs_action: 'Retry',
    reason: 'withdrawal_count_limit_exceeded',
    description: 'The customer has exceeded the balance or credit limit available on their card.',
    action: 'The customer needs to use an alternative payment method.'
  },
  {
    code: 532,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'authentication_required',
    description: 'The card was declined because the transaction requires authentication such as\u00a03D Secure.',
    action: `When using Stripe\u2019s front ends, in most cases a soft decline from an issuer triggers
    an authentication flow, allowing the customer to try again and authenticate their card. In some cases,
    such as\u00a0off-session payments, you might have to ask the customer to retry. If the card issuer returns
    this\u00a0decline code\u00a0despite a successfully authenticated transaction, the customer needs to contact their
    card issuer for more information.`
  },
  {
    code: 834,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'card_not_supported',
    description: 'The card does not support this type of purchase.',
    action: 'The customer needs to contact their card issuer to make sure their card can be used to make this type of purchase.'
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'do_not_try_again',
    description: 'The card was declined for an unknown reason.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 432,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'card_inactive',
    description: 'The card has been declined for an unknown reason.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 596,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'fraudulent',
    description: 'The payment was declined because Stripe suspects that it\u2019s fraudulent.',
    action: `Don\u2019t report more detailed information to your customer.
    Instead, present as you would the\u00a0generic_decline\u00a0described below.`
  },
  {
    code: 531,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'incorrect_cvc',
    description: 'The CVC number is incorrect.',
    action: 'The customer needs to try again using the correct CVC.'
  },
  {
    code: 813,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'incorrect_pin',
    description: 'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
    action: 'The customer needs to try again using the correct PIN.'
  },
  {
    code: 260,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'incorrect_zip',
    description: 'The postal code is incorrect.',
    action: 'The customer needs to try again using the correct billing postal code.'
  },
  {
    code: 502,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'lost_card',
    description: 'The payment was declined because the card is reported lost.',
    action: 'The specific reason for the decline shouldn\u2019t be reported to the customer. Instead, it needs to be presented as a generic decline.'
  },
  {
    code: 595,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'new_account_information_available',
    description: 'The card, or account the card is connected to, is invalid.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'not_permitted',
    description: 'The payment isn\u2019t permitted.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'offline_pin_required',
    description: 'The card was declined because it requires a PIN.',
    action: 'The customer needs to try again by inserting their card and entering a PIN.'
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'online_or_offline_pin_required',
    description: 'The card was declined as it requires a PIN.',
    action: `If the card reader supports Online PIN, prompt the customer for a PIN without creating a new transaction.
    If the card reader doesn\u2019t support Online PIN, the customer needs to try again by inserting their card and entering a PIN.`
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'partner_generic_decline',
    description: 'The payment provider has declined this payment and didn\u2019t provide any additional information.',
    action: 'The customer needs to contact the payment provider for more information.'
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'partner_high_risk_customer',
    description: 'The payment provider labeled this customer as high risk.',
    action: 'Don\u2019t tell the customer the specific reason for the decline. Instead, present it as a generic decline.'
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'partner_payment_not_found',
    description: 'The payment provider couldn\u2019t find this payment.',
    action: 'Attempt the payment again. If you still can\u2019t process it, the customer needs to contact the payment provider.'
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'payment_disputed',
    description: 'There\u2019s a dispute over the payment.',
    action: 'If the dispute resolves in favor of the business, attempt this payment again. If it still can\u2019t be processed, try again later.'
  },
  {
    code: 501,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'pickup_card',
    description: 'The customer can\u2019t use this card to make this payment (it\u2019s possible it was reported lost or stolen).',
    action: 'They need to contact their card issuer for more information.'
  },
  {
    code: 508,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'pin_try_exceeded',
    description: 'The allowable number of PIN tries was exceeded.',
    action: 'The customer must use another card or method of payment.'
  },
  {
    code: 572,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'revocation_of_all_authorizations',
    description: 'The card was declined for an unknown reason.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 571,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'revocation_of_authorization',
    description: 'The card was declined for an unknown reason.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'security_violation',
    description: 'The card was declined for an unknown reason.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'service_not_allowed',
    description: 'The card was declined for an unknown reason.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 502,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'stolen_card',
    description: 'The payment was declined because the card is reported stolen.',
    action: 'The specific reason for the decline shouldn\u2019t be reported to the customer. Instead, it needs to be presented as a generic decline.'
  },
  {
    code: 570,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'stop_payment_order',
    description: 'The card was declined for an unknown reason.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'testmode_decline',
    description: 'A Stripe test card number was used.',
    action: 'A genuine card must be used to make a payment.'
  },
  {
    code: 402,
    type: 'Hard Decline',
    sbs_action: 'Fail',
    reason: 'transaction_not_allowed',
    description: 'The card was declined for an unknown reason.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 270,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'merchant_blacklist',
    description: 'The payment was declined because it matches a value on the Stripe user\u2019s block list.',
    action: `Don\u2019t report more detailed information to your customer. Instead,
    present as you would the\u00a0generic_decline\u00a0described above.`
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'approve_with_id',
    description: 'The payment can\u2019t be authorized.',
    action: 'Attempt the payment again. If you still can\u2019t process it, the customer needs to contact their card issuer.'
  },
  {
    code: 551,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'card_velocity_exceeded',
    description: 'The customer has exceeded the balance, credit limit, or transaction amount limit available on their card.',
    action: 'The customer needs to contact their card issuer for more information.'
  },
  {
    code: 238,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'currency_not_supported',
    description: 'The card does not support the specified currency.',
    action: 'The customer needs to check with the issuer whether the card can be used for the type of currency specified.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'duplicate_transaction',
    description: 'A transaction with identical amount and credit card information was submitted very recently.',
    action: 'Check to see if a recent payment already exists.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'incorrect_number',
    description: 'The card number is incorrect.',
    action: 'The customer needs to try again using the correct card number.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_account',
    description: 'The card, or account the card is connected to, is invalid.',
    action: 'The customer needs to contact their card issuer to check that the card is working correctly.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_amount',
    description: 'The payment amount is invalid, or exceeds the amount that\u2019s allowed.',
    action: 'If the amount appears to be correct, the customer needs to check with their card issuer that they can make purchases of that amount.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_authorization',
    description: 'The payment didn\u2019t receive authorization or has revoked its authorization.',
    action: 'The customer must use a different payment method.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_billing_agreement',
    description: 'The customer\u2019s payment method is invalid due to an invalid billing agreement.',
    action: 'The customer must either sign a new billing agreement with the payment provider or use a different payment method.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_business_account',
    description: 'The business account is deactivated and incapable of sending or receiving funds.',
    action: 'The business account with the payment provider needs to be reactivated.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_customer_account',
    description: 'We can\u2019t charge the customer\u2019s account.',
    action: 'The customer must either resolve the issue with the payment provider or use a different payment method.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_cvc',
    description: 'The CVC number is incorrect.',
    action: 'The customer needs to try again using the correct CVC.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_expiry_month',
    description: 'The expiration month is invalid.',
    action: 'The customer needs to try again using the correct expiration date.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_expiry_year',
    description: 'The expiration year is invalid.',
    action: 'The customer needs try again using the correct expiration date.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_number',
    description: 'The card number is incorrect.',
    action: 'The customer needs try again using the correct card number.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_payment_information',
    description: 'The payment has invalid information.',
    action: `The customer needs to try again using correct information. If the customer believes
    that the information is correct, they must resolve the issue with their card issuer or payment provider.`
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'invalid_pin',
    description: 'The PIN entered is incorrect.',
    action: 'The customer needs to try again using the correct PIN.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'processing_error',
    description: 'An error occurred while processing the card.',
    action: 'The payment needs to be attempted again. If it still can\u2019t be processed, try again later.'
  },
  {
    code: 201,
    type: 'Error',
    sbs_action: 'Research',
    reason: 'reenter_transaction',
    description: 'The payment couldn\u2019t be processed by the issuer for an unknown reason.',
    action: 'The payment needs to be attempted again. If it still can\u2019t be processed, the customer needs to contact their card issuer.'
  }
];

export default responseCodes;
