import { gql } from '@apollo/client';

export default gql`
  query getMerchantConfigQuery {
    getMerchantConfig {
      id
      acquirers {
        default
        merchantId
        defaultMerchantId
      }
    }
  }
`;
