import { gql } from '@apollo/client';

export default gql`
  mutation updatePaymentMethodDetails($input: UpdatePaymentMethodInput) {
    updatePaymentMethodDetails(input: $input) {
      expiration
      billingDetails {
        address
        address2
        locality
        region
        postal
        country
      }
      token
    }
  }
`;
