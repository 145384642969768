import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typograph from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Heading from '../../components/icon-heading';

import './dashboard-page.scss';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  }
}));

const DashboardPage = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Heading title="Common Tasks" />
      <Grid container className="dashboard-container">
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper}>
            <Grid container direction="row" wrap="nowrap" spacing={1}>
              <Grid item>
                <CheckCircleOutlineIcon
                  color="secondary"
                  style={{ marginTop: '3px' }}
                />
              </Grid>
              <Grid item>
                <Typograph variant="h2">Setup or Modify a Product</Typograph>
                <span>
                  Create products for use with one-time invoices or to add to
                  subscriptions:
                </span>
                <ul>
                  <li>
                    <Link href="/product/add">Create</Link> a new product
                  </li>
                  <li>
                    <Link href="/products">Modify</Link> an existing product
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper}>
            <Grid container direction="row" wrap="nowrap" spacing={1}>
              <Grid item>
                <CheckCircleOutlineIcon
                  color="secondary"
                  style={{ marginTop: '3px' }}
                />
              </Grid>
              <Grid item>
                <Typograph variant="h2">Find or Add a Customer</Typograph>
                <span>
                  Create customers for invoicing on demand or to add a
                  subscription service to:
                </span>
                <ul>
                  <li>
                    <Link href="/customers">Search</Link> for an existing
                    customer
                  </li>
                  <li>
                    <Link href="/customer/add">Create</Link> a new customer{' '}
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper}>
            <Grid container direction="row" wrap="nowrap" spacing={1}>
              <Grid item>
                <CheckCircleOutlineIcon
                  color="secondary"
                  style={{ marginTop: '3px' }}
                />
              </Grid>
              <Grid item>
                <Typograph variant="h2">Invoice a Customer</Typograph>
                <span>
                  To create and send a one-time invoice to a customer, first{' '}
                  <Link href="/customers">search</Link> for an existing customer
                  or <Link href="/customer/add">create</Link> a new one. Then
                  click &quot;CREATE ONE-TIME INVOICE&quot;.
                </span>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper}>
            <Grid container direction="row" wrap="nowrap" spacing={1}>
              <Grid item>
                <CheckCircleOutlineIcon
                  color="secondary"
                  style={{ marginTop: '3px' }}
                />
              </Grid>
              <Grid item>
                <Typograph variant="h2">Create a Subscription</Typograph>
                <span>
                  To create a subscription, first{' '}
                  <Link href="/customers">search</Link> for an existing customer
                  or <Link href="/customer/add">create</Link> a new one. Then
                  click “Add” next to Subscriptions.
                </span>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper}>
            <Grid container direction="row" wrap="nowrap" spacing={1}>
              <Grid item>
                <CheckCircleOutlineIcon
                  color="secondary"
                  style={{ marginTop: '3px' }}
                />
              </Grid>
              <Grid item>
                <Typograph variant="h2">Modify a Subscription</Typograph>
                <span>
                  To modify or cancel a customer’s subscription, first{' '}
                  <Link href="/customers">search</Link> for the customer, and
                  then click “edit” on the subscription card.
                </span>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardPage;
