import ISOToUTC from './iso-to-utc';

const checkNextBillDate = (data) => {
  if (!data) return '';
  const { getNextBillBySubscriptionId } = data;
  const date = getNextBillBySubscriptionId.length > 0 ? ISOToUTC(getNextBillBySubscriptionId[0]?.nextBill) : 'No Information';
  return date;
};

export default checkNextBillDate;
