import React from 'react';
import { FieldArray } from 'formik';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import AddButton from '../../../components/buttons/add-button';
import RemoveButton from '../../../components/buttons/remove-button';

import './product-grants.scss';

const ProductGrantsForm = (props) => {
  const {
    data, classes, onChange, errors
  } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const [indexToDelete, setIndexToDelete] = React.useState(null);

  return (
    <FieldArray
      name="grants"
      validateOnChange={false}
      validateOnBlur={false}
      render={(arrayHelpers) => (
          <div className="product-grants">
            <div className="product-grants-header">
              <Typography variant="h3">Product Grants</Typography>
              <AddButton
                label="Add Item"
                onClick={() => arrayHelpers.push({ id: '', description: '' })}
              />
            </div>
            {data && data.length > 0
              ? data.map((item, index) => {
                const _error = errors && errors[index];
                const idError = _error && _error.id ? _error.id : null;
                const descriptionError = _error && _error.description ? _error.description : null;
                return (
                    <div className="grant-row" key={index}>
                      <FormControl>
                        <TextField
                          className={`${classes.firstInput} ${classes.sectionItem}`}
                          label="ID"
                          name={`grants.${index}.id`}
                          margin="normal"
                          onChange={onChange}
                          type="text"
                          value={item.id || ''}
                        />
                        <FormHelperText className={classes.errorText}>
                          {!item.id || item.id === '' ? idError : null}
                        </FormHelperText>
                      </FormControl>
                      <FormControl>
                        <TextField
                          className={`${classes.textField} ${classes.sectionItem}`}
                          label="Description"
                          name={`grants.${index}.description`}
                          margin="normal"
                          onChange={onChange}
                          type="text"
                          value={item.description || ''}
                        />
                        <FormHelperText className={classes.errorText}>
                          {!item.description || item.description === ''
                            ? descriptionError
                            : null}
                        </FormHelperText>
                      </FormControl>
                      <RemoveButton
                        style={{ paddingTop: '1.6em' }}
                        onClick={() => {
                          setDialogOpen(true);
                          setIndexToDelete(index);
                        }}
                      />
                      <Dialog open={dialogOpen} onClose={handleDialogClose}>
                        <DialogTitle id="alert-dialog-title">
                          Are you sure you want to delete this grant?
                        </DialogTitle>
                        <DialogActions>
                          <Button onClick={handleDialogClose} color="primary">
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              arrayHelpers.remove(indexToDelete);
                              handleDialogClose();
                            }}
                            color="primary"
                            autoFocus
                          >
                            Confirm Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                );
              })
              : null}
          </div>
      )}
    />
  );
};

export default ProductGrantsForm;
