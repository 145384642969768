import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
// import Link from '@material-ui/core/Link';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import rebarLogo from '../../images/rebar-logo.svg';
import { authContext } from '../../contexts/authContext';

import './header.scss';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#FFF',
    cursor: 'pointer',
    fontSize: '1em',
    '&:hover': {
      color: '#bababa'
    }
  },
  user: {
    display: 'flex',
    alignItems: 'center'
  },
  profileIcon: {
    marginLeft: '5px'
  }
}));

const Header = (props) => {
  // Todo make user menu a drop down instead of a popover
  const [menuAnchor, setMenuAnchor] = useState(null);
  const handleClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchor(null);
  };

  const { history } = props;
  const classes = useStyles();
  const context = useContext(authContext);
  const [currentUsername, setCurrentUsername] = useState('');
  const { authenticated, logout, user } = context;
  useEffect(() => {
    setCurrentUsername(
      user && user.attributes && user.attributes.email
        ? user.attributes.email
        : ''
    );
  }, [user]);

  const logoutMarkdown = authenticated ? (
    <MenuItem onClick={() => logout(history)}>Logout</MenuItem>
  ) : (
      <div className="logout" />
  );

  return (
    <div className="header">
      <div className="logo-container">
        <img src={rebarLogo} className="logo-image" alt="Rebar Logo" />
      </div>
      <div className="nav">
        {/* <Link
          className={`support nav-item ${classes.link}`}
          href="https://rebartechnology.atlassian.net/servicedesk/customer/portal/1"
          underline="none"
          target="_blank"
          rel="noopener"
        >
          Support
        </Link> */}
        <div
          className={`nav-item ${classes.link} ${classes.user}`}
          onClick={handleClick}
        >
          <p>{currentUsername}</p>
          <AccountCircleIcon fontSize="large" className={classes.profileIcon} />
        </div>
        <Menu
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => { history.push('/account-settings'); setMenuAnchor(null); }}>Account Settings</MenuItem>
          <MenuItem onClick={() => window.open('https://rebartechnology.atlassian.net/servicedesk/customer/portal/1')}>Support</MenuItem>
          {logoutMarkdown}
        </Menu>
      </div>
    </div>
  );
};

export default withRouter(Header);
