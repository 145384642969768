import _ from 'lodash';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/client';
import React, { Fragment, useState, useEffect } from 'react';

import AddProduct from './add-product';
import UploadFiles from './upload-files';
import ViewProducts from './view-products';
import Constants from '../../../constants';
import ValidationSchema from './validation-schema';
import getCustomerQuery from '../queries/getCustomer';
import cleanTypeName from '../../utils/cleanTypeName';
import CustomerContact from '../components/contact-info';
import ExistingPaymentMethodForm from './select-payment-method';
import CircleProgress from '../../../components/progress/circle';
import getPaymentMethodsQuery from '../queries/getPaymentMethods';
import PaymentMethodForm from '../payment-method/add-payment-method';
import addOneTimePaymentMutation from '../mutations/addOneTimePayment';
import {
  getPaymentMethodInput,
  getAuthorizationPayload,
  getDDAPaymentMethod,
  getOneTimePaymentMethodPayload,
} from '../../utils/get-payment-method';
import storeDDAPaymentMethodMutation from '../mutations/storeDDAPaymentMethod';
import authorizePaymentMethodMutation from '../mutations/authorizePaymentMethod';
import getPaymentMethodDetails from '../utils/get-payment-method-details-from-vault';
import {
  getVaultToken,
  finalizeWithVault,
  tokenizeDDAWithVault,
} from '../../utils/payment-method';
import ResponseCodes from '../../../utils/responseCodes';

const ACH = 'ach';
const DDA = 'DDA';
const { paymentMethod } = Constants;
const { successCodes } = paymentMethod;

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    marginBottom: '20px',
  },
  contactInfo: {
    fontSize: '1em',
    lineHeight: '18px',
    marginBottom: '20px',
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  orderTotal: {
    marginTop: '20px',
  },
  orderForm: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '450px',
  },
  submitButton: {
    marginRight: '15px',
  },
  buttonContainer: {
    marginTop: '30px',
    display: 'flex',
  },
  input: {
    '& .MuiInputBase-input': {
      minWidth: '300px',
    },
    marginBottom: '10px',
  },
  multilineInput: {
    '& .MuiInputBase-input': {
      minHeight: '75px',
    },
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

/*
  The VirtualTerminal is the origination point for onetime payments in the
  portal. It has quite a few complexities, but two main UX paths for it too
  work.

  1. Using an existing payment method.
  2. Using a new payment method

  Once we have established a payment method both paths will use the same
  product selection path and creation of the onetime payment itself.

  For Scenario #2, we talk with the vault and get a proper token that is
  finalized before calling authorizePaymentMethod, which will do the actual
  authorization for the order amount. This call is required and has key
  data points for the vault to close the loop on a transaction.

  For Scenario #1, we have a valid payment method stored in the database.
  This payment method may have been originally authorized or validated
  with the acquirer.  However, since it is being resued we need to make
  another authorizePaymentMethod call to ensure we have the keys the
  vault needs to close the loop.
*/
const VirtualTerminal = (props) => {
  const classes = useStyles();
  const { match, client, history } = props;
  const { params } = match;
  const { customerId } = params;
  const [attachment, setAttachment] = useState();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showAddNewButton, setShowAddNewButton] = useState(true);
  const [showExistingForm, setShowExistingForm] = useState(true);
  const [error, setError] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [token, setToken] = useState(null);
  const [existingMethodToSave, setExistingMethodToSave] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('manual');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [paymentOption, setPaymentOption] = useState(ACH);
  const [accountType, setAccountType] = useState('checking');
  const [open, setOpen] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [combinedData, setCombinedData] = useState();

  const { loading: customerLoading, data: customerData } = useQuery(
    getCustomerQuery,
    {
      client: props.client,
      variables: { customerId: params.customerId },
    }
  );

  const { loading: paymentMethodLoading, data: paymentMethodData } = useQuery(
    getPaymentMethodsQuery,
    {
      client: props.client,
      variables: { input: { externalId: params.customerId } },
    }
  );

  useEffect(() => {
    if (paymentMethodData == null) return undefined;

    const updatedPaymentMethods = [];
    const { getPaymentMethods } = paymentMethodData;

    const requests = getPaymentMethods.map((item) => getPaymentMethodDetails(item.token));

    Promise.all(requests).then((responses) => {
      for (const response of responses) {
        updatedPaymentMethods.push(response);
      }
      const concatData = getPaymentMethods.map((item, i) => ({
        ...item,
        ...updatedPaymentMethods[i],
      }));
      setCombinedData(concatData);
    });

    return undefined;
  }, [paymentMethodData]);

  const handleOpen = async (data) => {
    // dont allow submit if collectionMethod is true and selectedPaymentMethod is manual
    if (data.collectionMethod && selectedPaymentMethod === 'manual') {
      alert('You cannot use manual payment method with capture payment later option.');
      return;
    }
    await handleMutation(data);
    // window.scrollTo(0, 0); // Scroll up during validation

    if (
      paymentOption === 'credit'
      || (existingMethodToSave && existingMethodToSave.category === 'CREDIT')
    ) {
      setOpen(true);
    } else {
      history.push(`../customer/${params.customerId}`);
    }
  };

  const handleClose = () => {
    // history.push(`../customer/${params.customerId}`);
    window.location.href = `../customer/${params.customerId}`;
  };

  const [oneTimePaymentMutation, { error: addOneTimePaymentError }] = useMutation(addOneTimePaymentMutation);
  const [authorizedPaymentMethodMutation] = useMutation(
    authorizePaymentMethodMutation
  );
  const [DDAPaymentMethod] = useMutation(storeDDAPaymentMethodMutation, {
    refetchQueries: [
      {
        query: getPaymentMethodsQuery,
        variables: { input: { externalId: customerId } },
      },
    ],
  });

  const findResponseCode = (code) => {
    const found = ResponseCodes.find((item) => item.code === code);
    if (found) return found;
    return ResponseCodes.find((item) => item.code === 432);
  };

  const body = (
    <div className={classes.paper}>
      <Typography variant="h6">Authorization: </Typography>
      {authSuccess || error ? (
        <div>
          {error ? (
            <Typography className={classes.modal} color="error">
              {findResponseCode(errorCode).type}: {findResponseCode(errorCode).description}
            </Typography>
          ) : (
            <Typography className={classes.modal} style={{ color: '#00c853' }}>
              Payment Authorized
            </Typography>
          )}
        </div>
      ) : (
        <CircleProgress />
      )}
      <Typography className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          disabled={!authSuccess && !error}
        >
          OK
        </Button>
      </Typography>
    </div>
  );

  useEffect(() => {
    if (
      (token || existingMethodToSave || selectedPaymentMethod === 'manual')
      && selectedProducts
      && selectedProducts.length > 0
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [existingMethodToSave, selectedProducts, token]);

  useEffect(() => {
    // This total is used for auth if a new payment method is added and for UI display.
    // Total for invoice service is calculated on the back-end
    if (selectedProducts.length === 0) {
      setOrderTotal(0);
    }
    if (selectedProducts.length > 0) {
      const total = selectedProducts
        .map((product) => product.amount * product.quantity)
        .reduce((itemTotal, num) => itemTotal + num);

      setOrderTotal(total);
    }
  }, [selectedProducts]);

  if (customerLoading || paymentMethodLoading) {
    return (
      <Fragment>
        <CircleProgress />
      </Fragment>
    );
  }

  const { getCustomer: customer } = customerData;
  const existingPaymentMethods = combinedData && Array.isArray(combinedData) ? combinedData : [];
  // should not happen but just in case
  if (!customer) return null;
  /*
     TODO: should we set the customer address based on what we know?
     TODO: should we move this to a useCallback hook? form may be
     re-rendering each state change.
  */
  const initialFormValues = {
    accountHolderFirstName: customer.firstName,
    accountHolderLastName: customer.lastName,
    orderId: '',
    description: '',
    billingAddress: '',
    billingAddress2: '',
    billingLocality: '',
    billingRegion: '',
    billingPostal: '',
    sendEmail: true,
    collectionMethod: '',
    email: customer.email || '',
  };
  const resetState = () => {
    setIsSaving(false);
    setShowAddNewButton(true);
    setShowExistingForm(true);
    setSelectedPaymentMethod('manual');
    setDisableSubmit(true);
    setToken(null);
  };

  const getToken = async () => {
    setShowAddNewButton(false);
    setShowExistingForm(false);
    setExistingMethodToSave(null);
    setError(null);

    try {
      const results = await getVaultToken();

      if (results && results.success === false) {
        const { error: vaultError } = results;

        resetState();

        setError(vaultError);

        return null;
      }

      const { token: newToken } = results;

      setToken(newToken);

      return results;
    } catch (err) {
      resetState();

      const errorMessage = _.get(err, 'message', 'There was an error');

      setError(errorMessage);

      return null;
    }
  };

  const handleFinalize = async (values) => {
    if (!token) return [];

    setError(null);

    try {
      if (paymentOption === ACH) {
        const result = await tokenizeDDAWithVault({
          account: values.accountNumber,
          routing: values.routingTransitNumber,
          type: accountType,
          token,
        });

        if (result && result.success === false) {
          const { error: vaultError } = result;

          // failure here could be vault technical errors
          setError(vaultError);

          return result;
        }

        return result;
      }
      // working with credit from here down.
      const finalizedPaymentMethod = await finalizeWithVault({ token });

      if (_.get(finalizedPaymentMethod, 'success', false) === false) {
        // failure here could be vault technical errors or auth failures.
        resetState();

        setError('Add payment method failed.');

        return finalizedPaymentMethod;
      }

      /*
        dont allow user to add the same credit card.

        TODO:
        once the vault pulls the old token when adding the same card
        more than once - remove check for last four and issuer id
      */
      const existingMethod = existingPaymentMethods.findIndex(
        (item) => item.token === finalizedPaymentMethod.token
          || (item.last === finalizedPaymentMethod.last
            && item.issuerId === finalizedPaymentMethod.issuerId)
      );

      if (existingMethod !== -1) {
        resetState();

        setError('That card already exists. Please enter a different card.');

        return null;
      }

      return getPaymentMethodInput({
        ...values,
        ...finalizedPaymentMethod,
      });
    } catch (err) {
      resetState();

      const errorMessage = _.get(err, 'message', 'there was an error');

      setError(errorMessage);

      return null;
    }
  };

  const handleUseExistingPaymentMethod = (event, paymentMethods) => {
    const { target } = event;
    const { value, checked } = target;

    if (checked) {
      setSelectedPaymentMethod(value);
      const found = paymentMethods.find((method) => method.token === value);
      setExistingMethodToSave(found);
    }
  };

  const handleAddProduct = (product) => {
    const _quantity = parseInt(product.quantity, 10);
    const _products = selectedProducts.concat({
      ...product,
      quantity: _quantity,
    });
    setSelectedProducts(_products);
  };

  const handleClickRemoveProduct = (event) => {
    const { currentTarget } = event;
    const { id } = currentTarget;
    const indexToDelete = selectedProducts.findIndex(
      (product) => product.id === id
    );
    const _selectedProducts = [].concat(selectedProducts);
    _selectedProducts.splice(indexToDelete, 1);
    setSelectedProducts(_selectedProducts);
  };

  const handleMutation = async (data) => {
    const _selectedProducts = cleanTypeName(selectedProducts);
    setDisableSubmit(true);
    setIsSaving(true);

    let _paymentMethod;
    /*
      at this point we know if its new/existing. Both have to be
      authorized prior too saving and allowing the OTP.
    */
    if (existingMethodToSave) {
      _paymentMethod = existingMethodToSave;
    } else {
      const finalizeResult = await handleFinalize(data);

      if (
        !finalizeResult
        || (finalizeResult && finalizeResult.success === false)
      ) {
        setDisableSubmit(false);
        setIsSaving(false);
        return;
      }

      _paymentMethod = finalizeResult;
    }

    _paymentMethod = cleanTypeName(_paymentMethod);
    /*
      CREDIT and DDA are properly formatted and and stored in vault.

      DDA     - will never do authorization
      CREDIT  - will always do authorization

      when we default paymentOption to ACH, which is what flips between
      the forms, it makes all of the request got by the ACH path. if we
      set it to null then we have an issue with existing payment methods.
    */
    const isDDAMethod = () => {
      if (existingMethodToSave) {
        const { category } = existingMethodToSave;

        return category === DDA;
      }

      return token && paymentOption === ACH;
    };

    let onetimePaymentArgs;
    if (isDDAMethod()) {
      /*
        if we have a token it means that we have finalized with the vault
        and should store this payment method.
      */
      let ddaPaymentMethod = getDDAPaymentMethod({
        ...data,
        ..._paymentMethod,
        customerId: params.customerId,
      });

      if (token) {
        const storeDDAPaymentMethod = async (args) => {
          try {
            const results = await DDAPaymentMethod({
              variables: { input: args },
            });
            const paymentMethodResult = _.get(
              results,
              'data.storeDDAPaymentMethod.0',
              {}
            );

            if (!paymentMethodResult) return { error: 'DDA account not stored.', success: false };

            return { ...args, ...paymentMethodResult, success: true };
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(
              _.get(
                err,
                'message',
                'storeDDAPaymentMethod::something went wrong'
              )
            );

            return { success: false };
          }
        };

        ddaPaymentMethod = await storeDDAPaymentMethod(ddaPaymentMethod);
      }
      // DDA will reuse the existing ID since there is no authorization attempt.
      const ddaPayload = getOneTimePaymentMethodPayload({
        ...ddaPaymentMethod,
        routingTransitNumber:
          ddaPaymentMethod.routingTransitNumber || data.routing,
        id: ddaPaymentMethod.id || _paymentMethod.id,
      });

      // eslint-disable-next-line no-console
      // console.log({ method: 'virtualTerminal-DDA', ddaPayload });

      const { email } = data;
      // eslint-disable-next-line no-console
      // console.log({ method: 'virtualTerminal-DDA', data });

      onetimePaymentArgs = {
        confirmation: { send: true, email, attachment },
        customerId: params.customerId || null,
        externalId: data.orderId || null,
        description: data.description || null,
        products: _selectedProducts || null,
        paymentMethods: [ddaPayload] || [],
        // collectionMethod: 'AUTH'
      };
      // eslint-disable-next-line no-console
      // console.log({ method: 'virtualTerminal-DDA', onetimePaymentArgs });
    } else if (paymentOption === 'manual' || (!token && selectedPaymentMethod === 'manual')) {
      const fullname = `${customer.firstName} ${customer.lastName}`;
      const { email } = data;

      // eslint-disable-next-line no-console
      // console.log({ method: 'virtualTerminal-manual', data });

      onetimePaymentArgs = {
        confirmation: { send: true, email, attachment },
        customerId: params.customerId || null,
        externalId: data.orderId || null,
        description: data.description || null,
        products: _selectedProducts || null,
        name: fullname.trim(),
        // collectionMethod: 'AUTH'
      };

      // eslint-disable-next-line no-console
      // console.log({ method: 'virtualTerminal-manual', onetimePaymentArgs });
    } else {
      // you are dealing with CREDIT here. existing and new.
      const payload = getAuthorizationPayload({
        amount: orderTotal,
        billingDetails: data,
        customer,
        customerId: params.customerId,
        paymentMethod: _paymentMethod,
      });

      const authorizePaymentMethod = async (request) => {
        try {
          console.log({ request });
          const results = await authorizedPaymentMethodMutation({
            variables: { input: request },
          });
          const authResult = _.get(results, 'data.authorizePaymentMethod.0', {});

          if (authResult && !successCodes.includes(authResult.code)) {
            const { id, code, status } = authResult;

            return {
              ...request,
              id,
              code,
              success: false,
              error: `Card not authorized. Status code ${code}. ${status}`,
            };
          }

          const { input } = request;

          return { ...input, ...authResult, success: true };
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(
            err.message || 'authorizePaymentMethod::something went wrong'
          );

          return { success: false };
        }
      };

      const authResult = await authorizePaymentMethod(_.omit(payload, ['orderId', 'description', 'sendEmail', 'email', 'collectionMethod']));

      setAuthSuccess(authResult.success);

      if (!authResult.success) setError(authResult.error);
      if (!authResult.success) setErrorCode(authResult.code);

      /*
        this may be a success or failure now that we store the auth failure
        and associate it with the invoice.
      */
      const authorizedPaymentMethod = getOneTimePaymentMethodPayload(authResult);
      /*
        if sendEmail is true we will attempt to send to the email provided.
      */
      const { email } = data;

      // eslint-disable-next-line no-console
      // console.log({ method: 'virtualTerminal-credit', data });

      onetimePaymentArgs = {
        confirmation: { send: true, email, attachment },
        customerId: params.customerId || null,
        externalId: data.orderId || null,
        description: data.description || null,
        products: _selectedProducts || null,
        paymentMethods: [authorizedPaymentMethod] || [],
        // collectionMethod: 'AUTH' // use this to test
        collectionMethod: data.collectionMethod ? 'AUTH' : 'AUTH_CAPTURE',
      };

      // eslint-disable-next-line no-console
      // console.log({ method: 'virtualTerminal-credit', onetimePaymentArgs });
    }

    // results will have the invoice id and orderId if you need it.
    const results = await oneTimePaymentMutation({
      variables: { input: onetimePaymentArgs },
    });

    // eslint-disable-next-line no-console
    // console.log({ method: 'virtualTerminal-results', results });

    if (addOneTimePaymentError || !results) {
      setError('Unable to process one-time payment');
    }
  };

  const handleCancel = () => {
    history.push(`/customer/${params.customerId}`);
  };

  return (
    <Fragment>
      <Typography variant="h1">Create One-Time Invoice</Typography>
      <Typography variant="h3">
        {customer.firstName} {customer.lastName}
      </Typography>
      <div className={classes.contactInfo}>
        <CustomerContact customer={customer} />
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          className={classes.modal}
          disableBackdropClick
        >
          {body}
        </Modal>
      </div>
      <Formik
        initialValues={initialFormValues}
        validationSchema={ValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOpen}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form id="orderForm" onSubmit={handleSubmit}>
            <div className={classes.orderForm}>
              <TextField
                error={!!errors.orderId}
                helperText={errors.orderId}
                label="Order ID"
                value={values.orderId}
                size="small"
                variant="outlined"
                name="orderId"
                onChange={handleChange}
                className={classes.input}
                required
              />

              <TextField
                label="Order Description"
                value={values.description}
                size="small"
                variant="outlined"
                name="description"
                onChange={handleChange}
                className={`${classes.input} ${classes.multilineInput}`}
                multiline
              />

              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    error={!!errors.email}
                    helperText={errors.email}
                    label="Confirmation Email"
                    value={values.email}
                    size="small"
                    variant="outlined"
                    name="email"
                    onChange={handleChange}
                    className={`${classes.input}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    name="sendEmail"
                    checked={values.sendEmail}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  Send {paymentOption === 'manual' || (!token && selectedPaymentMethod === 'manual') ? 'Invoice' : 'Confirmation'}
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    name="collectionMethod"
                    checked={values.collectionMethod}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  {'Capture Payment Later'}
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                  <UploadFiles setAttachment={setAttachment}/>
                  <Divider className={classes.divider} />
                </Grid>
              </Grid>

              <div>
                <Typography variant="h3" className={classes.sectionHeader}>
                  Payment Method
                </Typography>
                <Typography variant="body2" color="error" gutterBottom={true}>
                  {error && error}
                </Typography>
                <PaymentMethodForm
                  customerId={params.customerId}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  token={token}
                  resetState={resetState}
                  disabled={isSaving}
                  paymentOption={paymentOption}
                  setPaymentOption={setPaymentOption}
                  accountType={accountType}
                  setAccountType={setAccountType}
                />
                {showExistingForm ? (
                  <ExistingPaymentMethodForm
                    hideManualPaymentOption={values.collectionMethod}
                    handleChange={handleUseExistingPaymentMethod}
                    selectedValue={selectedPaymentMethod}
                    customerId={params.customerId}
                    existingPaymentMethods={combinedData}
                    resetState={resetState}
                    isSaving={isSaving}
                  />
                ) : null}
                {showAddNewButton ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={getToken}
                    size="small"
                    disabled={isSaving}
                  >
                    Add New
                  </Button>
                ) : null}
              </div>
            </div>
          </form>
        )}
      />
      <Divider className={classes.divider} />
      <div>
        <Typography variant="h3" className={classes.sectionHeader}>
          Products
        </Typography>

        <AddProduct
          client={client}
          handleAddProduct={handleAddProduct}
          isSaving={isSaving}
        />

        {selectedProducts && selectedProducts.length > 0 ? (
          <ViewProducts
            selectedProducts={selectedProducts}
            handleRemove={handleClickRemoveProduct}
            isSaving={isSaving}
          />
        ) : null}
        <Typography className={classes.orderTotal}>
          Order Total:{' '}
          {selectedProducts.length > 0
            ? selectedProducts[0].currency.symbol
            : null}
          {orderTotal.toFixed(2)}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          disabled={disableSubmit}
          className={classes.submitButton}
          form="orderForm"
          type="submit"
        >
          {isSaving ? 'processing' : 'submit'}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCancel}
          disabled={isSaving}
        >
          Cancel
        </Button>
      </div>
    </Fragment>
  );
};

export default VirtualTerminal;
