import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// todo: this should go away
// import OldConfigView from './index';

import isAuthenticated from '../../../components/auth/isAuthenticated';
import getDashboardData from '../queries/dashboard';
import CircleProgress from '../../../components/progress/circle';
import AcquirersList from '../acquirers/list';
import CancellationReasonList from '../cancellation-reasons/list';
import AddCancellationReasonDialog from '../cancellation-reasons/form';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  systemConfiguration: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 15,
    width: '100%',
    height: '100%',
    minHeight: 350,
    marginBottom: 25
  },
  header: {
    width: '100%'
  },
  card: {
    width: 350,
    minHeight: 350,
    margin: 15,
    padding: 15
  },
  cardContent: {
    fontSize: 12,
    marginBottom: 15
  },
  cardTitle: {
    marginBottom: 7
  },
  cardActions: {}
}));

// TODO fix duplicate key errors
const MerchatConfigView = (props) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { loading, data } = useQuery(getDashboardData, {
    client: props.client
  });
  const { history } = props;

  if (loading || !data) return <CircleProgress size="1em" />;
  if (data && !data.getMerchantConfig) return null;
  const { getMerchantConfig } = data;
  const config = getMerchantConfig[0];

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h1">Merchant Configuration</Typography>
      <Paper
        variant="outlined"
        elevation={0}
        className={classes.systemConfiguration}
      >
        <Typography className={classes.header} variant="h5">
          System Configuration
        </Typography>
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.cardContent}>
            <Typography className={classes.cardTitle} gutterBottom>
              Gateways
            </Typography>
            <AcquirersList acquirers={config.acquirers} history={history} />
          </CardContent>
          <CardActions>
            <Button
              onClick={() => history.push('/config/configure-acquirer')}
              size="small"
            >
              Configure New Gateway
            </Button>
          </CardActions>
        </Card>
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.cardContent}>
            <Typography className={classes.cardTitle} gutterBottom>
              Subscription Cancellation Reasons
            </Typography>
            <CancellationReasonList reasons={config.cancellationReasons} />
          </CardContent>
          <CardActions>
            <Button
              onClick={() => setDialogOpen(true)}
              size="small"
            >
              Add New Reason
            </Button>
          </CardActions>
        </Card>
      </Paper>
      <AddCancellationReasonDialog
        handleClose={handleClose}
        dialogOpen={dialogOpen}
        config={config}
      />
    </div>
  );
};

export default isAuthenticated(MerchatConfigView);
