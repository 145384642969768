import React from 'react';
import Typography from '@material-ui/core/Typography';

import './icon-heading.scss';

const IconHeading = (props) => {
  const { icon, title } = props;

  return (
    <div className="heading-container">
      <div className="heading-icon">{icon}</div>
      <Typography variant="h1">{title}</Typography>
    </div>
  );
};

export default IconHeading;
