import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './circle.scss';

const CircleProgress = (props) => {
  const { size, className } = props;

  return (
    <div className={`progress-circle ${className}`}>
      <CircularProgress size={size} />
    </div>
  );
};

export default CircleProgress;
