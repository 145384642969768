import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles({
  root: {
    '& .MuiFormControl-root': {
      marginTop: 7,
      marginBottom: 7,
      marginRight: 7
    },
    '& .MuiInputBase-root': {
      width: 215
    }
  }
});

const renderMenuItems = (data) => {
  const items = [];

  if (!data) return items;

  data.forEach((item, index) => {
    items.push(
      <MenuItem
        key={`menu-item-${index}-${item.id}`}
        name={item.value}
        value={item.value}
      >
        {item.label}
      </MenuItem>
    );
  });

  return items;
};

const GenericSelect = (props) => {
  const {
    onChange, value, name, label, data, errors, touched
  } = props;
  const classes = useStyles();
  const _menuItems = renderMenuItems(data);
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel = useRef(null);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel}>{label}</InputLabel>
      <Select
        name={name}
        labelId={`${name}-label`}
        id={`${name}-label`}
        value={value || ''}
        onChange={onChange}
        labelWidth={labelWidth}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {_menuItems}
      </Select>
      <FormHelperText>{touched && errors}</FormHelperText>
    </FormControl>
  );
};

export default GenericSelect;
