import { gql } from '@apollo/client';

export default gql`mutation authorizePaymentMethodMutation($input: AuthorizePaymentMethodInput!) {
  authorizePaymentMethod(input: $input) {
    id
    category
    token
    primary
    code
    status
    requestId
  }
}`;
