import React from 'react';

const IFrame = (props) => {
  const { src, height = '125px', width = '100%' } = props;

  return (
    // <div className="iframe">
    <embed height={height} width={width} src={src}></embed>
    // </div>
  );
};

export default IFrame;
