import { useState } from 'react';

const setCookie = (name, value, options) => {
  const _expires = new Date(options.expires).toUTCString();

  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${_expires}; path=${options.path}`;
};
const getCookie = (name) => document.cookie.split('; ').reduce((r, v) => {
  const parts = v.split('=');
  return parts[0] === name ? decodeURIComponent(parts[1]) : r;
}, '');

const useCookie = (key, initialValue) => {
  const [item, setItem] = useState(() => getCookie(key) || initialValue);

  const updateItem = (value, options = { expires: 0, path: '/' }) => {
    setItem(value);
    setCookie(key, value, options);
  };

  return [item, updateItem];
};

export default useCookie;
