import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField
} from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    marginTop: '10px',
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    marginBottom: '20px'
  },
  textField: {
    marginBottom: '5px',
    marginRight: '0px',
    width: '100%'
  },
  formRow: {
    display: 'flex',
    margin: '0px',
    width: '100%'
  },
  mediumInput: {
    flexBasis: '75%'
  },
  smallInput: {
    flexBasis: '25%'
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: '10px'
  },
  firstInput: {
    marginRight: '10px'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  saveButton: {
    position: 'relative',
    marginTop: '30px',

  },
  cancelButton: {
    marginTop: '30px',
    marginLeft: '10px'

  },
  progress: {
    position: 'absolute',
    top: '30%'
  }
}));

const AddServiceAddress = (props) => {
  const classes = useStyles();
  const {
    values,
    handleBlur,
    handleChange,
    variant = 'standard',
    size = 'medium',
  } = props;
  const convertKey = (key) => {
    if (!values.metadata) return '';
    const obj = _.find(values, 'metadata', (i) => i.key === key);
    return _.get(obj, 'value', '');
  };
  console.log({ values });
  return (
    <div>
        <Fragment>
            <div className={classes.formContainer}>
              <TextField
                className={`${classes.textField}`}
                size={size}
                variant={variant}
                label="Service Address Description"
                name="service_place"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                value={_.get(values, 'service_place', convertKey('service_place'))}
              />
              <TextField
                className={`${classes.textField}`}
                size={size}
                variant={variant}
                label="Service Address"
                name="service_address"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                // value={values.accountHolderFirstName}
              />
              <TextField
                className={`${classes.textField}`}
                size={size}
                variant={variant}
                label="Suite Address"
                name="service_address2"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                // value={values.accountHolderFirstName}
              />
              <TextField
                className={`${classes.textField}`}
                size={size}
                variant={variant}
                label="City"
                name="service_locality"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                // value={values.accountHolderFirstName}
              />
              <TextField
                className={`${classes.textField}`}
                size={size}
                variant={variant}
                label="State"
                name="service_region"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                // value={values.accountHolderFirstName}
              />
              <TextField
                className={`${classes.textField}`}
                size={size}
                variant={variant}
                label="Zip Code"
                name="service_postal"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                // value={values.accountHolderFirstName}
              />
            </div>
        </Fragment>
    </div>
  );
};
export default AddServiceAddress;
