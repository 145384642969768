import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import GenericSelect from '../components/generic-select';

import getRecycleConfig from '../queries/getRecycleConfig';
import updateRecycleRuleSet from '../mutations/update-recycle-rule-set';

import delayTypes from '../components/recycle-delay-type';
import CircleProgress from '../../../components/progress/circle';
import cleanTypeName from '../../utils/cleanTypeName';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: 350,
    '& .MuiInputBase-root': {
      width: 450
    }
  },
  grid: {
    width: '100%'
  },
  paper: {
    width: '100%',
    height: 50,
    marginBottom: 25
  },
  formControl: {
    width: '100%',
    marginBottom: 25
  },
  delay: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
}));

// these are recycle rules
const ConfigureRules = (props) => {
  const classes = useStyles();
  const { loading, data } = useQuery(getRecycleConfig, {
    client: props.client
  });
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [delay, setDelay] = useState({ type: '', value: 1 });
  const [attempts, setAttempts] = useState(3);
  const [updateRuleSet] = useMutation(updateRecycleRuleSet, {
    refetchQueries: [
      {
        query: getRecycleConfig
      }
    ]
  });
  useEffect(() => {
    if (!loading && data) {
      const { getMerchantConfig } = data;
      const config = getMerchantConfig[0];
      const { recycle } = config;

      switch (selectedTab) {
        case 0: {
          const type = recycle.find((item) => item.type === 'soft');
          const rule = type.rules.find((item) => item.attribute === 'attempt');
          setDelay(type.delay);
          setAttempts(rule.value);
          break;
        }
        case 1: {
          const type = recycle.find((item) => item.type === 'hard');
          const rule = type.rules.find((item) => item.attribute === 'attempt');
          setDelay(type.delay);
          setAttempts(rule.value);
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [loading, data, selectedTab]);

  if (loading || !data) {
    return <CircleProgress size="1em" />;
  }

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };

  const handleDelayChange = (event) => {
    const {
      target: { name, value }
    } = event;
    const _delay = { ...delay };
    _delay[name] = value;

    setDelay(_delay);
    setHasChanges(true);
  };

  const handleAttemptChange = (event) => {
    const {
      target: { value }
    } = event;

    setAttempts(value);
    setHasChanges(true);
  };

  const handleSave = async () => {
    setIsSaving(true);

    const { getMerchantConfig } = data;
    const config = getMerchantConfig[0];
    const { recycle } = config;
    // get the recycle item and prep it for save
    // do mutation
    let typeIndex;
    switch (selectedTab) {
      case 0: {
        typeIndex = recycle.findIndex((item) => item.type === 'soft');
        break;
      }
      case 1: {
        typeIndex = recycle.findIndex((item) => item.type === 'hard');
        break;
      }
      default:
        typeIndex = -1;
        break;
    }

    if (typeIndex > -1) {
      const type = recycle[typeIndex];
      const ruleIndex = type.rules.findIndex(
        (item) => item.attribute === 'attempt'
      );
      const ruleChanges = type.rules[ruleIndex];

      ruleChanges.value = attempts;
      type.delay = delay;
      type.rules.splice(ruleIndex, 1, ruleChanges);
      recycle.splice(typeIndex, 1, type);

      const input = cleanTypeName(type);

      await updateRuleSet({ variables: { input: { ...input } } });
    }

    setHasChanges(false);
    setIsSaving(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0} square>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab
            label="Soft Failures"
            value={0}
            disabled={selectedTab !== 0 && hasChanges}
          />
          <Tab
            label="Hard Failures"
            value={1}
            disabled={selectedTab !== 1 && hasChanges}
          />
          <Tab label="Technical Failures" value={2} disabled />
        </Tabs>
      </Paper>
      <div className={classes.delay}>
        <GenericSelect
          label="Delay Type"
          name="type"
          onChange={handleDelayChange}
          value={delay.type}
          data={delayTypes}
        />
        <FormControl>
          <TextField
            size="medium"
            variant="outlined"
            label="Delay Value"
            onChange={handleDelayChange}
            value={delay.value}
            name="value"
            type="number"
          />
        </FormControl>
      </div>
      <FormControl className={classes.formControl}>
        <TextField
          size="medium"
          variant="outlined"
          label="# of Attempts"
          onChange={handleAttemptChange}
          value={attempts}
          name="attempts"
          type="number"
        />
      </FormControl>
      <Button
        type="submit"
        variant="contained"
        disabled={isSaving}
        disableElevation
        onClick={handleSave}
      >
        Save Changes
      </Button>
    </div>
  );
};

export default ConfigureRules;
