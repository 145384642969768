const getCurrencyObject = (priceConfig, currency) => {
  if (
    priceConfig
        && priceConfig.getPriceConfig
        && priceConfig.getPriceConfig.currencies
  ) {
    const { getPriceConfig } = priceConfig;
    const { currencies } = getPriceConfig;
    return currencies.find(
      (currencyObj) => currencyObj.name.toUpperCase() === currency.toUpperCase()
    );
  }
  return '';
};

export default getCurrencyObject;
