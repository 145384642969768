import { gql } from '@apollo/client';

export default gql`
  query getSubscriptionQuery($subscriptionId: String) {
    getSubscription(subscriptionId: $subscriptionId) {
      id
      externalId
      created
      modified
      start
      total
      end
      status
      nextBill {
        date
      }
      cancellationReason {
        name
        code
        category
      }
      cancellationNote
      frequency
      collectionMethod
      currency {
        name
        symbol
      }
      paymentMethodIds
      metadata {
        key
        value
      }
      paymentMethods {
        id
        category
        token
        brand
        issuer_id
        last
        expiration
        billingDetails {
          address
          address2
          locality
          region
          postal
          country
        }
      }
      products {
        id
        merchantId
        merchantProductId
        sku
        type
        description
        amount
        frequency
        currency {
          name
          symbol
        }
      }
    }
  }
`;
