import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

const MerchantProductIdInput = (props) => {
  const {
    classes, errors, values, onChange, onBlur
  } = props;
  return (
    <FormControl className={classes.formControl}>
      <TextField
        className={classes.MerchantProductIdInput}
        label="Merchant Product ID"
        margin="normal"
        name="merchantProductId"
        onChange={onChange}
        onBlur={onBlur}
        required
        value={values.merchantProductId || ''}
      />
      <FormHelperText className={classes.errorText}>
        {!values.merchantProductId || values.merchantProductId === ''
          ? errors.merchantProductId
          : null}
      </FormHelperText>
    </FormControl>
  );
};

export default MerchantProductIdInput;
