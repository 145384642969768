import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import searchProducts from './queries/searchSubscriptionProducts';
import EmptyState from '../../components/empty-state';
import Table from '../../components/table';
import CircleProgress from '../../components/progress/circle';

import './products-grid.scss';

const useStyles = makeStyles(() => ({
  textField: {
    marginTop: '20px'
  }
}));

const columnsToShow = [
  {
    key: 'merchantProductId',
    displayName: 'Product Id'
  },
  {
    key: 'sku',
    displayName: 'SKU'
  },
  {
    key: 'description',
    displayName: 'Description'
  }
];
const ProductGrid = (props) => {
  const [searchInput, setSearchInput] = useState('');
  const { selectedProducts, onChange } = props;
  const { loading, error, data } = useQuery(searchProducts, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        searchTerm: `${searchInput}`
      }
    }
  });

  // Todo make non case sensitive
  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  const classes = useStyles();

  const displayComponentWithData = (displayData) => (
    !displayData.products || displayData.products.length === 0
      ? <EmptyState message="no products to show" />
      : <div className="products-grid">
        <Table
          columnsToShow={columnsToShow}
          data={displayData.products}
          showSelection
          onSelect={onChange}
          previouslySelected={selectedProducts}
        />
      </div>
  );

  return (
    <Fragment>
      <h3 className={'product-grid-header'}>Add Products to Bundle</h3>
      <TextField
        className={classes.textField}
        onChange={handleChange}
        placeholder="search for a product"
      />
      {loading && (<CircleProgress size="1em" />)}
      {error && (<p>{error.message} :(</p>)}
      {data && displayComponentWithData(data)}
    </Fragment>
  );
};

export default ProductGrid;
