import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles((theme) => ({
  externalLink: {
    color: '#FFF',
    cursor: 'pointer',
    fontSize: '1em',
    '&:hover': {
      color: '#bababa'
    }
  }
}));

const ExternalLink = (props) => {
  const classes = useStyles();
  const { source, text } = props;

  return (
    <ListItem>
      <Link
        href={source}
        className={classes.externalLink}
        underline="none"
        target="_blank"
        rel="noopener"
      >
        {text}
      </Link>
    </ListItem>
  );
};

export default ExternalLink;
