import _ from 'lodash';

const convertFileToBase64 = (file, handlers = {}) => new Promise((resolve, reject) => {
  const fileReader = new FileReader();

  fileReader.readAsDataURL(file);

  fileReader.onload = _.get(handlers, 'onload', (event) => {
    console.log('LOAD Event:', event);
    resolve(fileReader.result);
  });

  fileReader.onerror = _.get(handlers, 'onerror', (event) => {
    console.log('ERROR Event:', event);
    reject(event);
  });

  fileReader.onabort = _.get(handlers, 'onabort', (event) => {
    console.log('ABORT Event:', event);
  });

  fileReader.onloadend = _.get(handlers, 'onloadend', (event) => {
    console.log('LOAD END Event:', event);
  });

  fileReader.onprogress = _.get(handlers, 'onprogress', (event) => {
    console.log('PROGRESS Event:', event);
  });

  fileReader.onloadstart = _.get(handlers, 'onloadstart', (event) => {
    console.log('LOAD START Event:', event);
  });
});

const getAttachment = async (file, onprogress) => {
  const { name } = file;
  const base64 = await convertFileToBase64(file, { onprogress });

  return { name, base64 };
};

export default getAttachment;
