import _ from 'lodash';
import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import cleanTypeName from '../../utils/cleanTypeName';
import editCustomer from '../mutations/editCustomer';
import getCustomer from '../queries/getCustomer';

import CustomerForm from '../form';
import CustomerSchema from '../form/validation-schema';

const initialFormValues = {
  externalId: '',
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  address: '',
  address2: '',
  locality: '',
  region: '',
  postalCode: '',
  country: '',
  phone: '',
  language: '',
  metadata: []
};

const CustomerEdit = (props) => {
  const { history, match } = props;
  const { params } = match;
  const { loading, error, data } = useQuery(getCustomer, {
    variables: { customerId: params.customerId }
  });

  const [updateCustomer] = useMutation(editCustomer, {
    update: (store, response) => {
      const editCustomerResult = _.get(response, 'data.editCustomer');
      // todo: handle GQL errors appropriately. dont update cache with dirty state
      try {
        const cacheData = store.readQuery({ query: getCustomer });
        cacheData.getCustomer.push(editCustomerResult);
        store.writeQuery({ query: getCustomer, data: cacheData });
        history.push(`/customer/${params.customerId}`);
      } catch (err) {
        // todo: cache can be missing on page refresh. figure out how to make it not so
        history.push(`/customer/${params.customerId}`);
      }
    }
  });

  const handleNext = async (dataObject) => {
    const _metadata = cleanTypeName(dataObject.metadata);
    console.log({ dataObject });
    const variables = {
      input: {
        id: params.customerId,
        externalId: dataObject.externalId,
        firstName: dataObject.firstName,
        lastName: dataObject.lastName,
        address: dataObject.address || null,
        address2: dataObject.address2 || null,
        email: dataObject.email || null,
        company: dataObject.company || null,
        locality: dataObject.locality || null,
        region: dataObject.region || null,
        postal: dataObject.postal || null,
        country: dataObject.country || null,
        language: dataObject.language || null,
        phone: dataObject.phone || null,
        metadata: _metadata || []
      }
    };
    await updateCustomer({ variables: { ...variables } });
  };

  const displayComponentWithData = (dataObject) => {
    const getCustomerResult = _.get(dataObject, 'getCustomer');
    const _initValue = { ...initialFormValues, ...getCustomerResult };
    return (
      <Formik
        initialValues={_initValue}
        validationSchema={CustomerSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleNext}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
            <form onSubmit={handleSubmit}>
              <CustomerForm
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
              />
              <div className="form-row">
                <Button
                  onClick={() => handleSubmit(values)}
                  className="form-button"
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  Save Changes
                </Button>
              </div>
            </form>
        )}
      />
    );
  };

  return (
    <Fragment>
      <Typography variant="h1">Edit Customer</Typography>
      {loading && <p>Loading...</p>}
      {error && <p>{error.message} :(</p>}
      {data && displayComponentWithData(data)}
    </Fragment>
  );
};

export default CustomerEdit;
