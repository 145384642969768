import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import MUIDataTable from 'mui-datatables';

import GetProductsQuery from '../../products/queries/getProductsByType';

import CircleProgress from '../../../components/progress/circle';

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginBottom: '20px'
  },
  priceForm: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '15px',
    marginBottom: '15px'
  },
  input: {
    marginRight: '15px'
  },
  amountInput: {
    width: '125px'
  },
  quantityInput: {
    width: '100px'
  }
}));

const columns = [
  {
    name: '',
    options: {
      searchable: false,
      sort: false
    }
  },
  'Merchant Product Id',
  'SKU',
  'Description',
  {
    name: 'Price',
    options: {
      searchable: false,
      sort: false
    }
  }
];

const AddOneTimePaymentProducts = (props) => {
  const classes = useStyles();
  const { handleAddProduct, isSaving } = props;
  const [getProducts, { loading, error, data }] = useLazyQuery(
    GetProductsQuery,
    {
      variables: { input: { type: 'onetime', status: 'active' } },
      fetchPolicy: 'cache-and-network'
    }
  );

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showProductList, setShowProductList] = useState(false);
  const [showPriceForm, setShowPriceForm] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);

  const resetState = () => {
    setShowProductList(false);
    setShowPriceForm(false);
    setShowAddButton(true);
  };

  const options = {
    elevation: 1,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    pagination: false,
    filter: false,
    disableToolbarSelect: true,
    rowHover: false,
    selectableRows: 'none',
    selectableRowsHeader: false,
    responsive: 'scrollFullHeight'
  };
  const handleSelect = (product) => {
    setSelectedProduct(product);
    setShowProductList(false);
    setShowPriceForm(true);
  };

  const handleChange = (event) => {
    const _price = event.target.value;
    setSelectedProduct({
      ...selectedProduct,
      amount: _price
    });
  };

  const handleChangeQuantity = (event) => {
    const quantity = event.target.value;
    setSelectedProduct({
      ...selectedProduct,
      quantity
    });
  };

  const handleClickAdd = () => {
    getProducts();
    setShowProductList(true);
    setShowAddButton(false);
  };
  const getProductRow = (product) => {
    const {
      id, merchantProductId, sku, description, prices
    } = product;
    const price = prices[0];
    const _price = `${price.currency.symbol}${price.amount}`;
    const productItem = {
      id,
      merchantProductId,
      sku,
      type: 'onetime',
      description,
      amount: price.amount,
      currency: price.currency,
      quantity: 1
    };
    const selectButton = (
      <Button
        id={id}
        onClick={() => {
          handleSelect(productItem);
        }}
        size="small"
        variant="outlined"
        color="primary"
      >
        Select
      </Button>
    );
    return [selectButton, merchantProductId, sku, description, _price];
  };

  if (showAddButton) {
    return (
      <Button
        onClick={handleClickAdd}
        variant="contained"
        color="secondary"
        size="small"
        className={classes.addButton}
        disabled={isSaving}
      >
        Select a Product
      </Button>
    );
  }

  if (loading) {
    return <CircleProgress />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (showProductList && data && data.products && data.products.length > 0) {
    const rows = data.products.map((product) => getProductRow(product));
    return <MUIDataTable data={rows} columns={columns} options={options} />;
  }
  if (showProductList && data && data.products && data.products.length === 0) {
    return <p>No products available</p>;
  }
  if (showPriceForm) {
    return (
      <div>
        <Typography variant="subtitle2" className={classes.title}>
          Set Product Price
        </Typography>
        <div className={classes.priceForm}>
          <TextField
            label="Price"
            variant="outlined"
            size="small"
            value={selectedProduct.amount}
            onChange={handleChange}
            className={`${classes.input} ${classes.amountInput}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
          <TextField
            variant="outlined"
            size="small"
            label="Quantity"
            value={selectedProduct.quantity}
            onChange={handleChangeQuantity}
            type="number"
            className={`${classes.input} ${classes.quantityInput}`}
          />
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => {
              handleAddProduct(selectedProduct);
              resetState();
            }}
          >
            Add to Order
          </Button>
        </div>
        <Divider className={classes.divider} />
      </div>
    );
  }
  return null;
};

export default AddOneTimePaymentProducts;
