import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

import './remove-button.scss';

const RemoveButton = (props) => {
  const { label, onClick, id } = props;
  return (
    <div onClick={onClick} className="remove-button" id={id}>
      <IconButton aria-label="cancel" size="small" className="remove-icon">
        <CancelIcon fontSize="small" htmlColor="#d50000" />
      </IconButton>
      <div className="remove-label">{label}</div>
    </div>
  );
};

export default RemoveButton;
