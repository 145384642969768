const rules = {
  'admin-group': {
    static: [
      'admin-only:view',
      'customer:edit',
      'customer:view',
      'customers:view',
      'products:view',
      'product:add',
      'product:view',
      'product:edit',
      'product:change-status',
      'refund',
      'subscription:add',
      'subscription:edit',
      'subscription:cancel',
      'reports:access',
      'invoices:view',
      'subscriptions:view'
    ]
  },
  'billing-ops': {
    static: [
      'customer:edit',
      'customer:view',
      'customers:view',
      'products:view',
      'product:add',
      'product:view',
      'product:edit',
      'product:change-status',
      'refund',
      'subscription:add',
      'subscription:edit',
      'subscription:cancel',
      'reports:access',
      'invoices:view',
      'subscriptions:view'
    ]
  },
  'agent-manager': {
    static: [
      'customer:edit',
      'customer:view',
      'customers:view',
      'products:view',
      'product:view',
      'subscription:add',
      'subscription:edit',
      'invoices:view',
      'subscriptions:view'
    ]
  },
  agent: {
    static: [
      'customer:edit',
      'customer:view',
      'customers:view',
      'products:view',
      'product:view',
      'invoices:view',
      'subscriptions:view'
    ]
  }
};

const checkPermissions = (permissionRules, role, action, data) => {
  const permissions = permissionRules[role];

  if (!permissions) {
    return false;
  }

  if (permissions.static && permissions.static.includes(action)) {
    return true;
  }

  return false;
};

const allowAction = (props) => {
  const { groups, perform } = props;

  if (groups && groups.length >= 0) {
    for (let i = 0; i < groups.length; i += 1) {
      const group = groups[i];

      if (checkPermissions(rules, group, perform)) {
        return props.yes();
      }
    }
  }

  return null;
};

export default allowAction;
