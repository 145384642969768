import { gql } from '@apollo/client';

export default gql`
  query getCustomerQuery($customerId: String) {
    getCustomer(customerId: $customerId) {
      id
      externalId
      firstName
      lastName
      address
      address2
      locality
      region
      postal
      country
      email
      company
      language
      phone
      created
      metadata {
        key
        value
      }
    }
  }
`;
