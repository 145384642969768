import * as Yup from 'yup';

const CustomerValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('a first name is required')
    .nullable(),
  lastName: Yup.string()
    .required('a last name is required')
    .nullable(),
  externalId: Yup.string()
    .required('a customer id is required')
    .nullable(),
  metadata: Yup.array()
    .ensure()
    .of(
      Yup.object().shape({
        key: Yup.string()
          .required('Key is required')
          .nullable(),
        value: Yup.string()
          .required('Value is required')
          .nullable()
      })
    )
});

export default CustomerValidationSchema;
