import { gql } from '@apollo/client';

export default gql`
  mutation createSubscriptionMutation($input: CreateSubscriptionInput!) {
    createSubscription(input: $input) {
      id
      created
      modified
      start
      total
      collectionMethod
      status
      currency {
        name
        symbol
      }
      frequency
      products {
        id
        merchantProductId
        sku
        frequency
        amount
        description
        currency {
          symbol
          name
        }
      }
    }
  }
`;
