import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AmexLogo from '../../images/payment-icons/amex.svg';
import DDALogo from '../../images/payment-icons/dda.svg';
import DefaultLogo from '../../images/payment-icons/default.svg';
import DiscoverLogo from '../../images/payment-icons/discover.svg';
import MastercardLogo from '../../images/payment-icons/mastercard.svg';
import VisaLogo from '../../images/payment-icons/visa.svg';
import getPaymentMethodDetails from '../../containers/customer/utils/get-payment-method-details-from-vault';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px'
  },
  paymentLogo: {
    width: '50px',
    marginRight: '10px'
  }
}));

const getLogo = (brand) => {
  if (!brand) return DefaultLogo;
  switch (brand.toLowerCase()) {
    case 'amex':
      return AmexLogo;
    case 'discover':
      return DiscoverLogo;
    case 'mastercard':
      return MastercardLogo;
    case 'visa':
      return VisaLogo;
    case 'electroniccheck':
      return DDALogo;
    default:
      return DefaultLogo;
  }
};

const LogoAndLastFourDisplay = (props) => {
  const classes = useStyles();

  const [logoSrc, setLogoSrc] = useState('');
  const [last, setLast] = useState('');

  useEffect(() => {
    const getPaymentMethodForTransaction = async (transaction) => {
      const paymentMethod = _.get(transaction, 'paymentMethods.0', null);

      if (_.isNull(paymentMethod)) return undefined;

      const results = await getPaymentMethodDetails(_.get(paymentMethod, 'token'));

      setLogoSrc(getLogo(results.brand));
      setLast(results.last);

      return undefined;
    };

    if (!_.has(props, 'transaction')) {
      setLogoSrc(getLogo(_.get(props, 'brand')));
      setLast(_.get(props, 'last'));
    } else {
      getPaymentMethodForTransaction(props.transaction);
    }
  }, [props]);

  return (
    <div className={classes.cardContainer}>
      <img alt="Visa Logo" className={classes.paymentLogo} src={logoSrc} />
      *** {last}
    </div>
  );
};

export default LogoAndLastFourDisplay;
