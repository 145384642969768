import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import './action-button.scss';

const ActionButton = (props) => {
  const { label, onClick } = props;

  return (
    <div className="action-button-container">
      <Fab
        aria-label="add"
        size="small"
        color="secondary"
        className={'action-button'}
        onClick={onClick}
      >
        <AddIcon />
      </Fab>
      <div className="action-button-label" onClick={onClick}>
        {label}
      </div>
    </div>
  );
};

export default ActionButton;
