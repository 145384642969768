import _ from 'lodash';

export const buildQueryStringParameterFetch = () => new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export const getQueryStringParameter = (paramName) => _.toString(_.cloneDeep(_.get(buildQueryStringParameterFetch(), paramName, '')));

export const getPathParameter = (pathIndex) => {
  const segments = _.compact(window.location.pathname.split('/'));

  return _.get(segments, pathIndex, null);
};
