import { gql } from '@apollo/client';

export default gql`
  mutation updateCancellationReasonsMutation($input: MerchantCancellationReasonInput) {
    updateCancellationReasons(input: $input) {
      id
      cancellationReasons {
          name
          code
          category
      }
    }
  }
`;
