import React from 'react';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MetaData from './metadata';

const States = require('../../../lists/states.json');
const Countries = require('../../../lists/countries.json');

const MenuItem = FormControl;

const useStyles = makeStyles((theme) => ({
  itemSelect: {
    marginRight: '10px',
    minWidth: '130px',
    marginTop: '15px',
    width: '100%'
  },
  itemOption: {
    width: '100%',
    padding: '10px'
  },
  textField: {
    marginBottom: '5px',
    marginRight: '0px'
  },
  formRow: {
    display: 'flex',
    margin: '0px',
    width: '100%'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0px'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
    width: '100%'
  },
  selectContainer: {
    marginLeft: '15px'
  },
  errorText: {
    color: theme.palette.error.main,
    marginLeft: '5px',
    marginTop: '0px',
    display: 'inline-flex'
  },
  hidden: {
    visibility: 'hidden'
  },
  fullWidth: {
    flexBasis: '100%'
  },
  halfWidth: {
    flexBasis: '50%'
  },
  firstInput: {
    marginRight: '20px'
  },
  contactHeader: {
    marginTop: '25px',
    marginBottom: '15px'
  }
}));

const CustomerForm = (props) => {
  const classes = useStyles();
  const {
    values, handleChange, handleBlur, errors
  } = props;

  const renderErrorMessage = (error, value) => (
      <FormHelperText className={classes.errorText}>
        {!value || value === '' ? error : null}
      </FormHelperText>
  );

  return (
    <Grid container>
      <Grid item m={9}>
        <div className={`${classes.formRow} `}>
          <div className={`${classes.inputContainer} ${classes.halfWidth} ${classes.firstInput}`}>
            <TextField
              className={classes.textField}
              required
              label="Customer ID"
              name="externalId"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={values.externalId || ''}
            />
            {errors.externalId
              ? renderErrorMessage(errors.externalId, values.externalId)
              : null}
          </div>
          <div className={`${classes.inputContainer}`}>
            <TextField
              className={classes.textField}
              label="Company"
              name="company"
              margin="normal"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.company || ''}
            />
          </div>
        </div>
        <div className={`${classes.formRow} `}>
          <div
            className={`${classes.inputContainer} ${classes.halfWidth} ${classes.firstInput}`}
          >
            <TextField
              className={classes.textField}
              required
              label="First Name"
              name="firstName"
              margin="normal"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.firstName || ''}
            />
            {errors.firstName
              ? renderErrorMessage(errors.firstName, values.firstName)
              : null}
          </div>
          <div className={`${classes.inputContainer}`}>
            <TextField
              required
              className={classes.textField}
              label="Last Name"
              name="lastName"
              margin="normal"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.lastName || ''}
            />
            {errors.lastName
              ? renderErrorMessage(errors.lastName, values.lastName)
              : null}
          </div>
        </div>
        <div className={`${classes.formRow} `}>
          <div
            className={`${classes.inputContainer} ${classes.halfWidth} ${classes.firstInput}`}
          >
            <TextField
              className={`${classes.textField}`}
              label="Email"
              name="email"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={values.email || ''}
            />
          </div>
          <div className={`${classes.inputContainer}`}>
            <TextField
              className={classes.textField}
              label="Phone"
              name="phone"
              margin="normal"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone || ''}
            />
          </div>
        </div>
        <div className={`${classes.formRow} `}>
          <div
            className={`${classes.inputContainer} ${classes.halfWidth} ${classes.firstInput}`}
          >
            <TextField
              className={classes.textField}
              label="Address"
              name="address"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={values.address || ''}
            />
          </div>
          <div className={`${classes.inputContainer}`}>
            <TextField
              className={`${classes.textField}`}
              label="Apt #, Suite"
              name="address2"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={values.address2 || ''}
            />
          </div>
        </div>
        <div className={`${classes.formRow} `}>
          <div
            className={`${classes.inputContainer} ${classes.halfWidth} `}
          >
            <TextField
              className={classes.textField}
              label="City"
              name="locality"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={values.locality || ''}
            />
          </div>
          <div className={classes.selectContainer}>
            <FormControl
              className={classes.itemSelect}
              fullWidth>
              <InputLabel>State</InputLabel>
              <Select
                className={classes.itemSelect}
                name="region"
                value={values.region || ''}
                onChange={handleChange}
              >
                <MenuItem value="" />
                {_.map(States, (state) => <MenuItem
                  className={classes.itemOption}
                  key={state.abbreviation}
                  value={state.abbreviation}>
                    {state.name}
                </MenuItem>)}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={`${classes.formRow} `}>
          <div
            className={`${classes.inputContainer} ${classes.halfWidth} ${classes.firstInput}`}
          >
            <FormControl
              className={classes.itemSelect}
              fullWidth>
              <InputLabel>Country</InputLabel>
              <Select
                className={classes.itemSelect}
                name="country"
                value={values.country || ''}
                onChange={handleChange}
              >
                <MenuItem value="" />
                {_.map(Countries, (country) => <MenuItem
                  className={classes.itemOption}
                  key={country.code}
                  value={country.code}>
                    {country.name}
                </MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className={classes.inputContainer}>
            <TextField
              className={`${classes.textField}`}
              label="Zip, Postal Code"
              name="postal"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={values.postal || ''}
            />
          </div>
        </div>
        <div className={`${classes.formRow} `}>
          <div className={`${classes.inputContainer} ${classes.halfWidth}`}>
            <TextField
              className={classes.textField}
              label="Language"
              name="language"
              margin="normal"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.language || ''}
            />
          </div>
        </div>
      </Grid>
      <MetaData
        data={values.metadata}
        onChange={handleChange}
        onBlur={handleBlur}
        classes={classes}
        errors={errors}
      />
    </Grid>
  );
};

export default CustomerForm;
