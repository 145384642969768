import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import './pencil-button.scss';

const InfoButton = (props) => {
  const { onClick } = props;

  return (
    <IconButton
      size="small"
      color="secondary"
      onClick={onClick}
      className="info-icon"
    >
      <InfoIcon color="primary" fontSize="small" />
    </IconButton>
  );
};

export default InfoButton;
