import axios from 'axios';
import { v4 as uuid } from 'uuid';

import Constants from '../../constants';

const { vault } = Constants;
const {
  DDA_TOKENIZE_URL,
  FINALIZE_URL,
  TOKEN_URL,
  VAULT_API_KEY
} = vault;

export const getVaultToken = async () => {
  try {
    const results = await axios.get(
      TOKEN_URL,
      { headers: { 'x-api-key': `${VAULT_API_KEY}` } }
    );
    const { data } = results;
    const { data: response } = data;
    const { token } = response;

    return {
      id: uuid(),
      success: true,
      token
    };
  } catch (err) {
    let errorMessage = 'Problem loading intake form.';
    if (err && err.message && err.message.toLowerCase() !== 'network error') {
      errorMessage = err.message;
    }
    // eslint-disable-next-line no-console
    console.error('getVaultToken::', errorMessage);
    return {
      error: errorMessage,
      success: false
    };
  }
};

export const finalizeWithVault = async (args) => {
  const { token } = args;
  try {
    const results = await axios.get(
      `${FINALIZE_URL}${token}`,
      { headers: { 'x-api-key': `${VAULT_API_KEY}` } }
    );
    const { data, success } = results;

    if (success === false || !data) {
      return {
        error: 'no data returned',
        success: false
      };
    }
    const { data: paymentMethod } = data;

    if (!paymentMethod) {
      return {
        error: 'no data returned',
        success: false
      };
    }

    return {
      ...paymentMethod,
      success: true
    };
  } catch (err) {
    const _message = err && err.message ? err.message : 'finalizeWithVault::something went wrong';
    // eslint-disable-next-line no-console
    console.error(_message);
    return {
      error: _message,
      success: false
    };
  }
};

export const tokenizeDDAWithVault = async (args) => {
  try {
    const {
      account,
      routing,
      type
    } = args;
    const options = {
      headers: { 'x-api-key': `${VAULT_API_KEY}` },
    };
    // vault uses lowercase scheme
    const payload = {
      scheme: 'dda',
      account,
      routing,
      type
    };
    const endpoint = `${DDA_TOKENIZE_URL}${args.token}`;
    const results = await axios.post(endpoint, payload, options);
    const { data: response } = results;
    const { data, errorMessage } = response;

    if (errorMessage) {
      const error = Object.values(data).join(' ');
      const _message = error || 'There was an error.';

      return {
        error: _message,
        success: false
      };
    }

    return {
      ...data,
      success: true
    };
  } catch (err) {
    const _message = err && err.message ? err.message : 'tokenizeDDAWithVault::something went wrong';
    // eslint-disable-next-line no-console
    console.error(_message);
    return {
      error: _message,
      success: false
    };
  }
};
