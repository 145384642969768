import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import getSubscriptionsByCustomerIdQuery from '../queries/getSubscriptionsByCustomerId';
import SubscriptionCard from './components/subscription-card';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px'
  },
  paymentLogo: {
    width: '50px',
    marginRight: '10px'
  },
  expiration: {
    fontWeight: 600
  },
  empty: {
    marginTop: '8px'
  },
  table: {
    width: 'auto',
    marginBottom: '15px'
  },
  title: {
    marginTop: '15px',
    fontWeight: 'bold',
    fontSize: '.9em'
  },
  cell: {
    borderBottom: '0px'
  },
  label: {
    fontWeight: 'bold'
  }
}));

const renderSubscriptions = (subscriptions, navToEdit, customerId, auth) => {
  const _subscriptions = subscriptions.filter(Boolean);

  const activeSubscriptions = _.filter(_subscriptions, (i) => i.status === 'active');
  const cancelledSubscriptions = _.filter(_subscriptions, (i) => i.status !== 'active');

  return [...activeSubscriptions, ...cancelledSubscriptions].map((subscription) => (
      <SubscriptionCard
        key={subscription.id}
        subscription={subscription}
        customerId={customerId}
        navigateToEdit={navToEdit}
        auth={auth}
      />
  ));
};

const ViewSubscriptions = (props) => {
  const { history, match, auth } = props;
  const { params } = match;
  const classes = useStyles();
  const { loading, data } = useQuery(getSubscriptionsByCustomerIdQuery, {
    client: props.client,
    variables: {
      customerId: params.customerId
    },
    fetchPolicy: 'network-only'
  });

  if (loading || !data) {
    return null;
  }
  const { getSubscriptionsByCustomerId: subscriptions } = data;

  if (subscriptions.length === 0) {
    return <p className={classes.empty}>No subscriptions added</p>;
  }

  const navigateToEditSubscription = (customerId, subscriptionId) => history.push(`/${customerId}/subscription/${subscriptionId}/edit`);

  const renderedSubscriptions = renderSubscriptions(
    subscriptions,
    navigateToEditSubscription,
    params.customerId,
    auth
  );

  return <Fragment>{renderedSubscriptions}</Fragment>;
};

export default ViewSubscriptions;
