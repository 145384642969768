import React from 'react';
import MUIDataTable from 'mui-datatables';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import RemoveButton from '../../../../components/buttons/remove-button';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '15px',
    fontWeight: 'bold',
    fontSize: '.9em'
  }
}));

const options = {
  elevation: 0,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  pagination: false,
  filter: false,
  disableToolbarSelect: true,
  selectableRows: 'none',
  rowHover: true
};
const columns = ['Merchant Product Id', 'SKU', 'Description', 'Price', ''];

const RemoveSubscriptionProducts = (props) => {
  const classes = useStyles();
  const { subProducts, removeProduct } = props;

  const renderRemoveButton = (index) => (
      <RemoveButton id={`id-${index}`} label="Remove" onClick={removeProduct} />
  );
  const getProductRow = (product, index) => {
    const {
      merchantProductId,
      sku,
      description,
      amount,
      frequency,
      currency
    } = product;
    const removeButton = subProducts && subProducts.length > 1 ? renderRemoveButton(index) : null;
    const price = `${
      currency && currency.symbol ? currency.symbol : ''
    }${amount} ${currency && currency.name ? currency.name : ''} ${frequency}`;

    return [merchantProductId, sku, description, price, removeButton];
  };

  if (!subProducts || subProducts.length === 0) {
    return null;
  }

  const rows = subProducts.map((product, index) => getProductRow(product, index));

  return (
    <div>
      <Typography variant="subtitle2" className={classes.title}>
        Current Products
      </Typography>
      <MUIDataTable data={rows} columns={columns} options={options} />
    </div>
  );
};

export default RemoveSubscriptionProducts;
