import axios from 'axios';
import _ from 'lodash';

const baseURL = _.get(process.env, 'REACT_APP_GRAPHQL_ENDPOINT');
const headers = {
  'Content-Type': 'application/json',
  'x-api-key': _.get(process.env, 'REACT_APP_API_KEY'),
};

const logErrorService = {
  create: (async (payload) => {
    const request = {
      method: 'post',
      baseURL,
      url: '/log/error',
      data: payload,
      headers,
    };
    return axios(request);
  })
};

export default logErrorService;
