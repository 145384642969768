import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  expiration: {
    fontWeight: 600
  }
}));

const ExpirationDisplay = (props) => {
  const classes = useStyles();
  const { expiration } = props;

  if (expiration == null) {
    return null;
  }

  const month = expiration.substring(0, 2);
  const year = expiration.substring(2);
  const dateToShow = `${month}/${year}`;

  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  const getLastTwoDigits = (input) => Number(input.toString().substring(2));

  const isExpired = (getLastTwoDigits(currentYear) > Number(year))
    || (getLastTwoDigits(currentYear) === Number(year) && currentMonth >= Number(month));

  return (
    <Typography variant="body2" color={isExpired ? 'error' : 'initial'}>
      <span className={classes.expiration}>Expires </span>
      {dateToShow}
    </Typography>
  );
};

export default ExpirationDisplay;
