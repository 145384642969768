import React from 'react';

import CreditCardDisplay from '../../../components/credit-card/logo-and-last-four';
import ExpirationDisplay from '../../../components/credit-card/expiration';
import Table from '../../../components/table';

import './add-subscription.scss';

const columnsToShow = [
  {
    key: 'displayCard',
    displayName: ''
  },
  {
    key: 'displayExpiration',
    displayName: ''
  }
];

const AddPaymentMethodToSubscription = (props) => {
  const { data = [], onSelect, selectedPaymentMethods } = props;

  // TODO: the ID below is a hack because token is currently not unique
  const dataToShow = data
    ? data.map((item) => ({
      ...item,
      displayCard: (
            <CreditCardDisplay last={item.last} brand={item.brand} />
      ),
      displayExpiration: <ExpirationDisplay expiration={item.expiration} />
    }))
    : [];

  return (
    <div className="add-subscription-payment-method">
      <div className="add-subscription-payment-method-header">
        <h2>Add Subscription Payment Method</h2>
      </div>
      <div className="subscription-row">
        <Table
          data={dataToShow}
          columnsToShow={columnsToShow}
          showSelection={true}
          previouslySelected={selectedPaymentMethods}
          onSelect={onSelect}
        />
      </div>
    </div>
  );
};

export default AddPaymentMethodToSubscription;
