import { gql } from '@apollo/client';

export default gql`
  mutation editCustomerMutation($input: EditCustomerInput) {
    editCustomer(input: $input) {
      id
      externalId
      firstName
      lastName
      address
      address2
      locality
      region
      postal
      country
      phone
      email
      company
      language
      metadata {
        key
        value
      }
      # paymentMethods {
      #   category
      #   token
      #   brand
      #   issuer_id
      #   last
      #   expiration
      #   billingDetails {
      #     address
      #     address2
      #     locality
      #     region
      #     postal
      #     country
      #   }
      # }
    }
  }
`;
