import _ from 'lodash';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '600px'
  }
}));

const renderHeader = (data, showSelection) => {
  const selectionCell = (
    <TableCell key="item-selection-all" className="item-selection" />
  );
  const results = [];

  if (showSelection) {
    results.push(selectionCell);
  }

  data.forEach((item) => {
    results.push(
      <TableCell key={`header-${item.key}`}>{item.displayName}</TableCell>
    );
  });

  return results;
};

const buildRowObject = (columnsToRender, item) => {
  const results = {};

  _.forEach(columnsToRender, (column) => {
    if (_.isNull(_.get(results, column.key, null))) {
      _.set(results, column.key, _.get(item, column.key));
    }
  });

  return results;
};

const buildSelectionCell = (item, onSelect, isSelected) => {
  const onClick = onSelect && typeof onSelect === 'function'
    ? () => onSelect(item)
    : () => { };

  return (
    <TableCell key={`item-selection-${item.id}`} className="item-selection">
      <Checkbox onClick={onClick} checked={isSelected} />
    </TableCell>
  );
};

const buildRowCells = (context) => {
  const {
    columnsToRender,
    isSelected,
    showSelection,
    onSelect,
    item,
    itemToRender,
    index
  } = context;
  const results = [];

  if (showSelection) {
    results.push(buildSelectionCell(item, onSelect, isSelected));
  }

  columnsToRender.forEach((column) => {
    results.push(
      <TableCell key={`${column.key}-${index}`}>
        {itemToRender[column.key]}
      </TableCell>
    );
  });

  return results;
};

const renderRows = (columnsToRender, context) => {
  const {
    data, showSelection, onSelect, previouslySelected = []
  } = context;
  const results = [];

  data.forEach((item, index) => {
    const _item = buildRowObject(columnsToRender, item);
    const isSelected = !!previouslySelected.find((obj) => obj.id === item.id);
    const cells = buildRowCells({
      columnsToRender,
      isSelected,
      showSelection,
      onSelect,
      itemToRender: _item,
      item,
      index
    });

    results.push(<TableRow key={`${item.id}-${index}`}>{cells}</TableRow>);
  });

  return results;
};

const TableView = (props) => {
  const classes = useStyles();
  const { columnsToShow, showHeader = true, showSelection = false } = props;
  const header = showHeader ? renderHeader(columnsToShow, showSelection) : [];
  const rows = renderRows(columnsToShow, props);

  return (
    <Table key={'table-view'} className={classes.table}>
      <TableHead>
        <TableRow>{header}</TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};

export default TableView;
