import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import getPaymentMethodsQuery from '../../queries/getPaymentMethods';
import getPaymentMethodDetails from '../../utils/get-payment-method-details-from-vault';

import CreditCardDisplay from '../../../../components/credit-card/logo-and-last-four';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  }
}));

const renderPaymentMethods = (paymentMethods, paymentMethodIds) => {
  // Originally, Subscriptions could have more than 1 payment method
  // this is no longer the case, and they need to be filtered by the payment methods
  // listed in the subscription item in the db.  This filter accomplishes that.

  const _paymentMethods = paymentMethods.filter((method) => paymentMethodIds.includes(method.id));
  if (_paymentMethods.length > 0) {
    return (
      <CreditCardDisplay
        last={_paymentMethods[0].last}
        brand={_paymentMethods[0].brand}
        key={_paymentMethods[0].token}
      />
    );
  }

  return null;
};

const SubscriptionPaymentMethods = (props) => {
  const classes = useStyles();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodIds] = useState(props.paymentMethodIds);
  const { loading, data } = useQuery(getPaymentMethodsQuery, {
    client: '',
    variables: {
      input: { externalId: props.customerId }
    }
  });

  useEffect(() => {
    (async () => {
      if (data == null) return;
      const updatedPaymentMethods = [];
      const { getPaymentMethods } = data;
      const requests = getPaymentMethods.map((item) => getPaymentMethodDetails(item.token));
      const responses = await Promise.all(requests);
      for (const response of responses) {
        updatedPaymentMethods.push(response);
      }
      const concatData = getPaymentMethods.map(
        (item, i) => ({ ...item, ...updatedPaymentMethods[i] })
      );
      setPaymentMethods(concatData);
    })();
  }, []);

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="subtitle2" className={classes.title}>
          Payment Method
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {!loading
          ? renderPaymentMethods(paymentMethods, paymentMethodIds)
          : <Typography variant="subtitle3" className={classes.title}>Loading...</Typography>}
      </Grid>
    </Grid>
  );
};

export default SubscriptionPaymentMethods;
