import _ from 'lodash';
import React, { useEffect, useState, Fragment } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import MUIDataTable from 'mui-datatables';
import format from 'date-fns/format';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import getInvoicesQuery from '../queries/getInvoices';
import getTransactionsQuery from '../queries/getTransactions';
import refundInvoiceMutation from '../mutations/refundInvoice';
import createTransactionForInvoice from '../mutations/createTransactionForInvoice';
import getCurrencyObject from '../utils/get-currency-object';
import ISOtoUTC from '../utils/iso-to-utc';
import CircleProgress from '../../../components/progress/circle';
import Can from '../../../components/auth/userCanPerform';
import ExistingPaymentMethodForm from '../virtual-terminal/select-payment-method';
import getPaymentMethodsQuery from '../queries/getPaymentMethods';
import getPaymentMethodDetails from '../utils/get-payment-method-details-from-vault';
// TODO: add ability to add a payment method via the Invoice modal
// import IFrame from '../../../components/iframe';
// import Constants from '../../../constants';
// const { vault } = Constants;
// const { FORM_URL } = vault;

const columns = [
  {
    name: '',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'Date',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'Number',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'Subscription/Order Id',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Start of Billing Period',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Total',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'Balance',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'Method',
    options: {
      filter: false,
      sort: true
    }
  },
  // empty section is for the "Refund" button
  {
    name: '',
    options: {
      filter: false,
      sort: true
    }
  },
  // empty section is for the "Pay Invoice" button
  {
    name: '',
    options: {
      filter: false,
      sort: true
    }
  },
  // empty section is for the "Capture Payment" button
  {
    name: '',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'Status',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: '',
    options: {
      filter: false,
      sort: true
    }
  }
];

const options = {
  elevation: 0,
  selectableRows: 'none',
  download: true,
  print: false,
  viewColumns: false,
  search: true,
  filter: false,
  sortOrder: {
    name: 'Number',
    direction: 'desc'
  }
};

const ViewInvoices = (props) => {
  const {
    auth, customerId, handleClick, priceConfig, confirmation
  } = props;
  const [invoiceToRefund, setInvoiceToRefund] = useState(null);
  const [invoiceToPay, setInvoiceToPay] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [refundInput, setRefundInput] = useState(false);
  const [paymentInput, setPaymentInput] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [refundValue, setRefundValue] = useState();
  const [paymentValue, setPaymentValue] = useState();
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [combinedData, setCombinedData] = useState();
  // TODO: it's ideal to move this logic to mutation implementation
  const [refundAttemptedTime, setRefundAttemptedTime] = useState(null);
  const [refundAttemptedOnInvoiceId, setRefundAttemptedOnInvoiceId] = useState(null);
  const [refundMutationLoading, setRefundMutationLoading] = useState(false);
  const [mutationError, setMutationError] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [fetchInvoices, { loading, data }] = useLazyQuery(getInvoicesQuery, {
    client: props.client,
    variables: {
      input: {
        customerId
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { data: paymentMethodData } = useQuery(
    getPaymentMethodsQuery,
    {
      client: props.client,
      variables: { input: { externalId: customerId } }
    },
  );

  const [fetchTransactions, { data: transactionsData, startPolling, stopPolling }] = useLazyQuery(getTransactionsQuery, {
    client: props.client
  });

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  useEffect(() => {
    if (transactionsData && Array.isArray(transactionsData.getTransactionsByExternalId)) {
      const latestTransaction = transactionsData.getTransactionsByExternalId.find((tr) => new Date(tr.date) > refundAttemptedTime);
      if (latestTransaction && latestTransaction.action === 'refund') {
        stopPolling();
        setRefundMutationLoading(false);
        fetchInvoices();
        if (latestTransaction.status !== 'APPROVED') {
          const error = `Cannot Refund until the transaction settles with the payment processor.
          \nPlease try again after it settles (usually 1-2 days for credit card or 2-5 days for ACH)`;
          setMutationError(error);
          handleError(error);
        }
      }
    }
  }, [transactionsData]);

  const resetState = () => {
    setDialogOpen(false);
    setDialogMessage(null);
    setInvoiceToRefund(null);
    setRefundInput(false);
    setMutationError(null);
  };

  const handleError = (error) => {
    setDialogMessage(error || 'There was an error. Refund not processed');
    setRefundInput(false);
  };

  const [refundInvoice] = useMutation(
    refundInvoiceMutation,
    {
      client: props.client,
      onCompleted: () => {
        setDialogOpen(false);
        fetchTransactions({
          variables: {
            input: {
              externalId: refundAttemptedOnInvoiceId
            }
          },
        });
        startPolling(1000);
      },
      onError: (error) => handleError(error),
    }
  );

  const [payInvoice, { loading: paymentMutationLoading, error: createTransactionError }] = useMutation(
    createTransactionForInvoice,
    {
      client: props.client,
      refetchQueries: [
        {
          query: getInvoicesQuery,
          variables: {
            input: {
              customerId
            }
          },
        }
      ]
    }
  );

  useEffect(() => {
    if (paymentMethodData == null) return undefined;

    const updatedPaymentMethods = [];
    const { getPaymentMethods } = paymentMethodData;

    const requests = getPaymentMethods.map((item) => getPaymentMethodDetails(item.token));

    Promise.all(requests).then((responses) => {
      for (const response of responses) {
        updatedPaymentMethods.push(response);
      }
      const concatData = getPaymentMethods.map((item, i) => ({
        ...item,
        ...updatedPaymentMethods[i]
      }));
      setCombinedData(concatData);
    });

    return undefined;
  }, [paymentMethodData]);

  const handleClickRefund = (invoice, currencySymbolValue) => {
    setInvoiceToRefund(invoice);
    setDialogMessage(`Enter the amount you would like to refund on Invoice #${invoice.number}.
      \nMust be less than or equal to the amount paid (${currencySymbolValue}${(invoice.total - invoice.balance).toFixed(2)}).`);
    setDialogOpen(true);
    setCurrencySymbol(currencySymbolValue);
    setRefundValue(_.toNumber((invoice.total - invoice.balance).toFixed(2)));
    setRefundInput(true);
  };

  const hasPaymentMethods = _.size(combinedData) > 0;

  const fetchTransactionsForInvoice = async (invoice) => {
    const { id } = invoice;
    const { data: getTransactionsByExternalId } = await fetchTransactions({
      variables: {
        input: {
          externalId: id
        }
      },
    });
    const transactions = _.get(getTransactionsByExternalId, 'getTransactionsByExternalId');
    return transactions;
  };
  const [hideManualPaymentOption, setHideManualPaymentOption] = useState(false);
  const [disableRadio, setDisableRadio] = useState(false);
  const [disableAmountInput] = useState(false);
  const handleClickPayInvoice = async (invoice, currencySymbolValue) => {
    console.log({ invoice });
    const transactions = await fetchTransactionsForInvoice(invoice);
    const paymentMethods = transactions[0]?.paymentMethods;
    if (_.lowerCase(invoice.collectionMethod) === 'auth') {
      setHideManualPaymentOption(true);
    } else {
      setHideManualPaymentOption(false);
    }

    if (invoice.status === 'authorized') {
      setDisableRadio(true);
      // setDisableAmountInput(true);
    } else {
      setDisableRadio(false);
      // setDisableAmountInput(false);
    }

    if (hasPaymentMethods) {
      setInvoiceToPay(invoice);
      setDialogMessage(`Enter the amount you would like to pay on Invoice #${invoice.number}
        \nup to the full balance of ${currencySymbolValue}${(invoice.balance).toFixed(2)}.`);
      if (paymentMethods && paymentMethods.length > 0) {
        setSelectedPaymentMethod(paymentMethods[0].token);
      }
      setDialogOpen(true);
      setCurrencySymbol(currencySymbolValue);
      setPaymentValue(_.toNumber(invoice.balance.toFixed(2)));
      setPaymentInput(true);
    } else {
      setInvoiceToPay(invoice);
      setDialogMessage('In order to pay invoice, please first add a payment method to the Customer account');
      setDialogOpen(true);
    }
  };

  const handleUseExistingPaymentMethod = (event, paymentMethods) => {
    const { target } = event;
    const { value, checked } = target;

    if (checked) {
      setSelectedPaymentMethod(value);
    }
  };

  const renderTransactionButton = (invoice) => (
    <Button
      size="small"
      color="primary"
      onClick={() => {
        handleClick(invoice);
      }}
    >
      View Transactions
    </Button>
  );

  const renderRefundButton = (invoice, currencySymbolValue) => {
    const { total, balance, status } = invoice;
    if (parseFloat(total) === parseFloat(balance) || status.toLowerCase() !== 'paid') {
      return null;
    }
    return (
      <Can
        groups={auth.groups}
        perform="refund"
        yes={() => (
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => {
                handleClickRefund(invoice, currencySymbolValue);
              }}
            >
              Refund
            </Button>
        )}
      />
    );
  };

  const renderPayInvoiceButton = (invoice, currencySymbolValue) => {
    const {
      balance, paymentMethodIds, collectionMethod, status
    } = invoice;
    if (paymentMethodIds.length === 0 && balance !== 0 && _.lowerCase(collectionMethod) !== 'auth' && status === 'due') {
      return (
        <Can
          groups={auth.groups}
          perform="refund"
          yes={() => (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => {
                  handleClickPayInvoice(invoice, currencySymbolValue);
                }}
              >
                Pay Invoice
              </Button>
          )}
        />
      );
    }
    return null;
  };

  const renderCaptureInvoiceButton = (invoice, currencySymbolValue) => {
    const {
      balance, paymentMethodIds, collectionMethod, status
    } = invoice;
    if (paymentMethodIds.length !== 0 && balance !== 0 && _.lowerCase(collectionMethod) === 'auth'
      && _.includes(['authorized', 'due'], _.lowerCase(status))) {
      return (
        <Can
          groups={auth.groups}
          perform="refund"
          yes={() => (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => {
                  handleClickPayInvoice(invoice, currencySymbolValue);
                }}
              >
                Capture Payment
              </Button>
          )}
        />
      );
    }
    return null;
  };

  const renderViewInvoiceButton = (invoice, currencySymbolValue) => {
    // const {
    //   balance, paymentMethodIds, collectionMethod, status
    // } = invoice;
    console.log({ invoice, currencySymbolValue, selectedInvoice });
    return <Can
      groups={auth.groups}
      perform="refund"
      yes={() => (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => { setOpen(true); setSelectedInvoice({ ...invoice, currencySymbolValue }); }}
          >
            View
          </Button>
      )}
    />;
  };

  const getInvoiceRow = (invoice) => {
    const {
      created,
      total,
      balance,
      status,
      subscriptionId,
      externalId,
      lineItems,
      number,
      period,
      collectionMethod
    } = invoice;
    const currency = Array.isArray(lineItems) && lineItems[0] && lineItems[0].currency
      ? lineItems[0].currency
      : '';
    const _currency = getCurrencyObject(priceConfig, currency);
    const invoiceDate = format(created, 'MM/dd/yyyy');
    const _subscriptionId = subscriptionId
      ? subscriptionId.substring(subscriptionId.length - 10)
      : externalId || '';
    const _total = `${_currency.symbol}${parseFloat(total).toFixed(2)}`;
    const _balance = balance !== null ? `${_currency.symbol}${parseFloat(balance).toFixed(2)}` : '';

    const payInvoiceButton = renderPayInvoiceButton(invoice, _currency.symbol);
    const captureInvoiceButton = renderCaptureInvoiceButton(invoice, _currency.symbol);
    const viewInvoiceButton = renderViewInvoiceButton(invoice, _currency.symbol);
    const transactionButton = renderTransactionButton(invoice);
    const refundButton = renderRefundButton(invoice, _currency.symbol);
    const startOfPeriod = period ? ISOtoUTC(period.start) : '-';
    return [
      viewInvoiceButton,
      invoiceDate,
      number,
      _subscriptionId,
      startOfPeriod,
      _total,
      _balance,
      collectionMethod,
      refundButton,
      payInvoiceButton,
      captureInvoiceButton,
      _.startCase(status),
      transactionButton
    ];
  };

  const validateRefundInput = (number, invoice) => {
    if (number > (invoice.total - invoice.balance)) {
      setInputError(true);
    } else {
      setRefundValue(_.toNumber(number));
      setInputError(false);
    }
  };

  const validatePaymentInput = (number, invoice) => {
    if (number > invoice.balance || number < 1 || number == null) {
      setInputError(true);
    } else {
      setPaymentValue(_.toNumber(number));
      setInputError(false);
    }
  };

  if (loading) return <CircleProgress size="1em" />;
  if (!data) return null;

  let rows;
  if (!loading) {
    const { getInvoiceByCustomerId } = data;
    if (!getInvoiceByCustomerId || getInvoiceByCustomerId.length === 0) {
      return <p>No Invoices to show</p>;
    }
    rows = getInvoiceByCustomerId.map((invoice) => getInvoiceRow(invoice));
  }

  return (
    <Fragment>
      {rows && <MUIDataTable options={options} columns={columns} data={rows} />}
      <Dialog open={dialogOpen}>
        <DialogContent>
          <DialogContentText>
            {dialogMessage}
          </DialogContentText>
          {refundInput && (
            <>
              <div>Amount ({currencySymbol})</div>
              <TextField type="number" value={refundValue} error={inputError} onChange={(e) => validateRefundInput(e.target.value, invoiceToRefund)}/>
            </>
          )}
          {paymentInput && (
            <>
              <ExistingPaymentMethodForm
                disableRadio={disableRadio}
                hideManualPaymentOption={hideManualPaymentOption}
                handleChange={handleUseExistingPaymentMethod}
                selectedValue={selectedPaymentMethod}
                customerId={customerId}
                existingPaymentMethods={combinedData}
                resetState={resetState}
              />
              <div>Amount ({currencySymbol})</div>
              <TextField type="number" disabled={disableAmountInput} value={paymentValue}
                error={inputError} onChange={(e) => validatePaymentInput(e.target.value, invoiceToPay)}/>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {invoiceToRefund && !mutationError
            ? <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={async () => {
                  try {
                    setRefundMutationLoading(true);
                    setRefundAttemptedTime(new Date());
                    setRefundAttemptedOnInvoiceId(invoiceToRefund.id);
                    await refundInvoice({
                      variables: {
                        input: {
                          invoiceId: invoiceToRefund.id,
                          refundAmount: refundValue
                        }
                      },
                    });
                    // Refresh the page
                    window.location.reload(false);
                  } catch (err) {
                    // eslint-disable-next-line
                    console.error(mutationError)
                    setRefundMutationLoading(false);
                  }
                }}
                disabled={refundMutationLoading || inputError}
              >
                {refundMutationLoading ? 'Processing' : 'Refund'}
              </Button>
            : null
            }
          {hasPaymentMethods && invoiceToPay && !createTransactionError
            ? <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={async () => {
                  try {
                    _.get(console, 'log')({ confirmation });
                    await payInvoice({
                      variables: {
                        input: {
                          amount: paymentValue,
                          customerId,
                          confirmation,
                          invoiceId: invoiceToPay.id,
                          token: selectedPaymentMethod,
                          currency: 'usd',
                          paymentMethodIds: [
                            combinedData.find((e) => e.token === selectedPaymentMethod).id
                          ]
                        }
                      }
                    });
                    window.location.reload(false);
                  } catch (err) {
                    // eslint-disable-next-line
                    console.error(createTransactionError)
                  }
                }}
                disabled={paymentMutationLoading || inputError}
              >
                {paymentMutationLoading ? 'Processing' : 'Pay'}
              </Button>
            : null
          }
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={resetState}
            disabled={paymentMutationLoading || refundMutationLoading}
          >
            {mutationError || createTransactionError ? 'Close' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
      {selectedInvoice?.id && <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
      >
        <DialogTitle>Invoice Details</DialogTitle>
        <DialogContent>
          {/* Display selectedInvoice details here */}
          <div>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>Status:
            <Typography variant="caption"> {_.upperFirst(selectedInvoice.status)}</Typography></Typography>
          </div>
          <br />
          <div>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>Invoice ID:
            <Typography variant="caption"> {selectedInvoice.id}</Typography></Typography>
          </div>
          <br />
          <div>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>Invoice Number:
            <Typography variant="caption"> {selectedInvoice.number}</Typography></Typography>
          </div>
          <br />
          <div>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>Order ID:
            <Typography variant="caption"> {selectedInvoice.externalId}</Typography></Typography>
          </div>
          <br />
          <div>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>Invoice Description:
            <Typography variant="caption"> {_.get(selectedInvoice, 'description', 'N/A')}</Typography></Typography>
          </div>
          <br />
          <div>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>Total Invoice Amount:
            <Typography variant="caption"> {selectedInvoice.currencySymbolValue}{selectedInvoice.total}</Typography></Typography>
          </div>
          <br />
          <div>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>Balance:
            <Typography variant="caption"> {selectedInvoice.currencySymbolValue}{selectedInvoice.balance}</Typography></Typography>
          </div>
          <br />
          <div>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>Line Items:</Typography>
            <br />
            {selectedInvoice.lineItems.map((item, index) => (
              <div key={index}>
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>{item.productName}</Typography>
                <ul>
                  <li>
                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>Merchant Product ID -
                    <Typography variant="caption"> {_.get(item, 'product.merchantProductId', 'N/A')}</Typography></Typography>
                  </li>
                  <li>
                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>Product Description -
                    <Typography variant="caption"> {item.productDescription}</Typography></Typography>
                  </li>
                  <li>
                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>Quantity -
                    <Typography variant="caption"> {item.quantity}</Typography></Typography>
                  </li>
                  <li>
                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>Amount -
                    <Typography variant="caption"> {selectedInvoice.currencySymbolValue}{item.amount}</Typography></Typography>
                  </li>
                </ul>
                <br />
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>}
    </Fragment>
  );
};

export default ViewInvoices;
