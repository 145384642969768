import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import getAcquirersData from '../queries/acquirers';
import GenericSelect from '../components/generic-select';
import CircleProgress from '../../../components/progress/circle';

const useStyles = makeStyles({
  formControl: {
    width: '100%'
  },
  root: {
    '& .MuiFormControl-root': {
      marginTop: 7,
      marginBottom: 7,
      marginRight: 7
    },
    '& .MuiInputBase-root': {
      width: 450
    }
  }
});

const AcquirerSelect = (props) => {
  const { setAcquirer, match } = props;
  const { params } = match;
  const classes = useStyles();
  const { loading, data } = useQuery(getAcquirersData, {
    client: props.client
  });
  /*
    this useEffect allows us to reuse this component when
    we are editing a config. the params have the acquirerId
    which we can use to setAcquirer with the same data that
    is populating the dropdown.
  */
  useEffect(() => {
    if (!loading && data && params) {
      const { getAcquirers } = data;
      const item = getAcquirers.find((acquirerItem) => acquirerItem.id === params.acquirerId);

      if (item) {
        return setAcquirer(item);
      }
    }

    return undefined;
  }, [params, setAcquirer, loading, data]);

  if (loading || !data) return <CircleProgress size="1em" />;
  if (data && !data.getAcquirers) return null;
  const { getAcquirers: acquirers } = data;

  const _acquirers = acquirers.map((item) => ({
    value: item.id,
    label: item.name
  }));

  const handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    const selectedAcquirer = acquirers.find((item) => item.id === value);

    setAcquirer(selectedAcquirer);
  };

  return (
    <div className={classes.root}>
      <GenericSelect
        label="Acquirer"
        name="acquirerId"
        onChange={handleChange}
        data={_acquirers}
      />
    </div>
  );
};

export default AcquirerSelect;
