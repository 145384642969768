import { gql } from '@apollo/client';

export default gql`
  query getSubscriptionsByCustomerIdQuery($customerId: String) {
    getSubscriptionsByCustomerId(customerId: $customerId) {
      id
      created
      modified
      start
      total
      end
      status
      frequency
      collectionMethod
      cancellationReason {
        name
        code
        category
      }
      cancellationNote
      currency {
        name
        symbol
      }
      products {
        id
        merchantProductId
        sku
        type
        description
        amount
        frequency
        currency {
          name
          symbol
        }
      }
      paymentMethods {
        category
        token
        brand
        issuer_id
        last
        expiration
        id
      }
      metadata {
        key
        value
      }
      paymentMethodIds
    }
  }
`;
