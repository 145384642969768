import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import updateRecycleCodeType from '../mutations/update-recycle-code-type';
import recycleQueries from '../queries/recycle-queries';
import CircleProgress from '../../../components/progress/circle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: 350,
    '& .MuiInputBase-root': {
      width: 450
    },
    '& td:nth-child(2)': {
      width: '38%'
    }
  },
  card: {
    padding: 15,
    width: '100%',
    height: '100%',
    minHeight: 350
  },
  group: {
    width: '100%'
  }
}));

const renderCode = (value, tableMeta) => {
  if (!value) return null;
  const { rowData } = tableMeta;
  return `${rowData[0]} - ${rowData[1]}`;
};

const options = {
  elevation: 0,
  selectableRows: 'none',
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  filter: false,
  disableToolbarSelect: true,
  pagination: false
};

const columns = [
  {
    label: 'Code',
    name: 'code',
    options: {
      filter: false,
      sort: false,
      customBodyRender: renderCode
    }
  },
  {
    label: 'Group',
    name: 'label',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'type',
    options: {
      filter: false,
      sort: false,
      display: false
    }
  }
];

/* todo: pull merge functions out and test them */
const configuredType = (code, config) => {
  const { recycle } = config;
  const currentConfig = recycle.find((item) => item.codes.indexOf(code) > -1);
  const type = currentConfig ? currentConfig.type : 'none';
  return type;
};
/* todo: pull merge functions out and test them */
const mergeAcquirerWithConfig = (data) => {
  const { getMerchantConfig, getAcquirerCodes } = data;
  const config = getMerchantConfig[0];
  const merged = getAcquirerCodes.map((item) => ({
    ...item,
    type: configuredType(item.code, config)
  }));

  return merged;
};

const ConfigureCodes = (props) => {
  const { acquirer } = props;
  const classes = useStyles();
  const { loading, data } = useQuery(recycleQueries, {
    client: props.client,
    variables: { acquirerId: acquirer.id }
  });
  const [codes, setCodes] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [updateCodeType] = useMutation(updateRecycleCodeType, {
    refetchQueries: [
      {
        query: recycleQueries,
        variables: { acquirerId: acquirer.id }
      }
    ]
  });
  useEffect(() => {
    if (!loading && data) {
      const codesList = mergeAcquirerWithConfig(data);
      setCodes(codesList);
    }
  }, [loading, data]);

  if (loading || !data) {
    return <CircleProgress size="1em" />;
  }

  const handleChange = async (event, code) => {
    const idx = codes.findIndex((item) => item.code === code);

    if (idx > -1) {
      const { currentTarget } = event;
      const { value } = currentTarget;
      const [desiredType] = value.split('-');
      const previousType = codes[idx].type;
      const input = {
        previousType,
        desiredType,
        code,
        acquirerId: acquirer.id
      };

      setIsSaving(true);
      await updateCodeType({ variables: { input: { ...input } } });
      setIsSaving(false);
    }
  };

  const renderRadioButtons = (radioValue, tableMeta) => {
    let value = _.cloneDeep(radioValue);

    const { rowData } = tableMeta;
    const code = rowData[0];
    const type = rowData[2];

    switch (type) {
      case 'soft':
        value = `soft-${code}`;
        break;
      case 'hard':
        value = `hard-${code}`;
        break;
      default:
        value = `none-${code}`;
        break;
    }

    return (
      <FormControl className={classes.group} component="fieldset">
        <RadioGroup
          row
          aria-label={code}
          name={code}
          defaultValue="top"
          value={value}
          onChange={(event) => handleChange(event, code)}
        >
          <FormControlLabel
            value={`none-${code}`}
            control={<Radio color="primary" />}
            label="No Action"
            labelPlacement="top"
          />
          <FormControlLabel
            value={`soft-${code}`}
            control={<Radio color="primary" />}
            label="Soft Failures"
            labelPlacement="top"
          />
          <FormControlLabel
            value={`hard-${code}`}
            control={<Radio color="primary" />}
            label="Hard Failures"
            labelPlacement="top"
          />
          <FormControlLabel
            value={`tech-${code}`}
            control={<Radio color="primary" />}
            label="Technical Failures"
            labelPlacement="top"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  columns[1].options.customBodyRender = renderRadioButtons;

  return (
    <div className={classes.root}>
      {isSaving && <CircleProgress size="2em" />}
      <MUIDataTable
        className={classes.card}
        columns={columns}
        data={codes}
        options={options}
      />
    </div>
  );
};

export default ConfigureCodes;
