import { gql } from '@apollo/client';

export default gql`
  query getNextBill($input: NextBillInput!) {
    getNextBillBySubscriptionId (input: $input) {
      date
      subscriptionId
      nextBill
    }
  }
`;
