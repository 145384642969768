import React, { Component } from 'react';
import _ from 'lodash';
import Error404Screen from '../../containers/error/404';
import logErrorService from '../../services/api';

function logErrorToService(url, error, errorInfo, user) {
  const payload = {
    url,
    error,
    errorInfo,
    user,
    interface: 'portal'
  };
  console.log('logErrorToService', payload);
  logErrorService.create(payload)
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to a logging service
    console.log('ErrorBoundary componentDidCatch', error, errorInfo);

    // Get the current url
    const url = window.location.href;

    console.error(error, errorInfo);

    // Get user info from local storage
    const user = JSON.parse(localStorage.getItem('user'));

    // Log the error to error reporting service, but if url contains 'localhost' then don't log
    if (!_.includes(url, 'localhost')) logErrorToService(url, _.toString(error), errorInfo, user);

    // Set the state to trigger a render with an error message
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // Render the error page component
      return <Error404Screen />;
    }

    // If no error occurred, render the children components
    return this.props.children;
  }
}

export default ErrorBoundary;
