import { gql } from '@apollo/client';

export default gql`
  mutation createCustomerMutation($input: CustomerInput) {
    createCustomer(input: $input) {
      id
      externalId
      firstName
      lastName
      address
      address2
      locality
      region
      postal
      country
      phone
      language
      metadata {
        key
        value
      }
      email
      company
      paymentMethods {
        category
        token
        brand
        issuer_id
        last
        expiration
        billingDetails {
          address
          address2
          locality
          region
          postal
          country
        }
      }
    }
  }
`;
