const {
  REACT_APP_VAULT_BASE_URL,
  REACT_APP_VAULT_API_KEY = 'no-env-key',
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_REPORTS_API_KEY,
  REACT_APP_CLIENT_SLUG,
  REACT_APP_STAGE
} = process.env;

const constants = {
  vault: {
    BASE_URL: REACT_APP_VAULT_BASE_URL,
    TOKEN_URL: `${REACT_APP_VAULT_BASE_URL}/authorize`,
    DDA_TOKENIZE_URL: `${REACT_APP_VAULT_BASE_URL}/tokenize/`,
    FINALIZE_URL: `${REACT_APP_VAULT_BASE_URL}/finalize/`,
    FORM_URL: `${REACT_APP_VAULT_BASE_URL}/form/`,
    MODIFY_URL: `${REACT_APP_VAULT_BASE_URL}/modify`,
    PAYMENT_METHOD_BY_TOKEN: `${REACT_APP_VAULT_BASE_URL}/review/`,
    VAULT_API_KEY: `${REACT_APP_VAULT_API_KEY}`
  },
  reports: {
    BASE_URL: `${REACT_APP_GRAPHQL_ENDPOINT}/reports`,
    REPORTS_API_KEY: REACT_APP_REPORTS_API_KEY,
    AWS_BUCKET: `${REACT_APP_CLIENT_SLUG}-merchant-reports-${REACT_APP_STAGE}`
  },
  paymentMethod: {
    successCodes: ['100', '101', '102', '103', '104', '109', '110', '116']
  }
};

export default constants;
