import React, { Fragment } from 'react';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';

import ActivateButton from '../../components/buttons/circle-check-button';
import CancelButton from '../../components/buttons/remove-button';
import Can from '../../components/auth/userCanPerform';

const ProductsTable = (props) => {
  const {
    products,
    navigateToView,
    handleStatusChange,
    statusFilter,
    setStatusFilter
  } = props;

  const options = {
    elevation: 0,
    selectableRows: 'none',
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    filter: true,
    filterType: 'multiselect',
    rowsPerPage: [100],
    rowsPerPageOptions: false,
    sortOrder: { name: 'Merchant Product ID', direction: 'desc' },
    onFilterChange(changedColumn, filterList) {
      // filterList is an array of arrays. The status column is at index 5 and
      // is equal to an array of all filters currently applied to that column.
      setStatusFilter(filterList[5]);
    }
  };

  const columns = [
    {
      name: '',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Merchant Product ID',
      options: { filter: false, sort: true }
    },
    {
      name: 'SKU',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Description',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Type',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Status',
      options: {
        filter: true,
        filterList: statusFilter,
        sort: true,
        filterType: 'checkbox'
      }
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  const renderViewButton = (id) => (
      <Button
        color="primary"
        size="small"
        onClick={() => {
          navigateToView(id);
        }}
      >
        View
      </Button>
  );

  const renderActionButton = (auth, id, status) => {
    switch (status) {
      case 'pending':
        return (
          <Can
            groups={auth.groups}
            perform="product:change-status"
            yes={() => (
                <ActivateButton
                  label="Activate"
                  onClick={() => handleStatusChange(id, 'active')}
                />
            )}
          />
        );
      case 'active':
        return (
          <Can
            groups={auth.groups}
            perform="product:change-status"
            yes={() => (
                <CancelButton
                  label="Cancel"
                  onClick={() => handleStatusChange(id, 'cancelled')}
                />
            )}
          />
        );
      default:
        return null;
    }
  };

  const getProductRow = (product) => {
    const {
      id, merchantProductId, sku, description, type, status
    } = product;
    const buttons = <div>{renderViewButton(id)}</div>;
    const action = renderActionButton(id, status);
    return [buttons, merchantProductId, sku, description, type, status, action];
  };
  const rows = products.map((product) => getProductRow(product));

  return (
    <Fragment>
      <MUIDataTable data={rows} columns={columns} options={options} />
    </Fragment>
  );
};

export default ProductsTable;
