import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Box, Typography, Button, withStyles
} from '@material-ui/core';
import getAttachment from './upload/upload.service';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#EEEEEE',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const UploadFiles = (props) => {
  const [selectedFiles, setSelectedFiles] = useState();
  const [currentFile, setCurrentFile] = useState();
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    console.log({ selectedFiles });
    if (_.size(selectedFiles) > 0) setCurrentFile(_.first(selectedFiles));
  }, [selectedFiles]);

  useEffect(() => {
    console.log({ currentFile });
    if (currentFile) upload();
  }, [currentFile]);

  const selectFile = (event) => {
    setSelectedFiles(_.get(event, 'target.files'));
  };

  const upload = () => {
    setProgress(0);

    getAttachment(currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    }).then((response) => {
      props.setAttachment(response);
    }).catch((error) => {
      console.error(error);
      setProgress(0);
      setMessage('Could not upload the file!');
      setCurrentFile(undefined);
      setIsError(true);
    });
  };

  return (
    <div className="mg20">
      <Box width="100%" mr={1}>
        <Typography variant="subtitle2" className={'file-name'}>
            {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
        </Typography>
      </Box>

      {currentFile && (
        <Box className="mb25" display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <BorderLinearProgress variant="determinate" value={progress} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
          </Box>
        </Box>)
      }

      <label htmlFor="btn-upload">
        <input
          id="btn-upload"
          name="btn-upload"
          style={{ display: 'none' }}
          type="file"
          onChange={selectFile} />
        <Button
          className="btn-choose"
          variant="outlined"
          component="span" >
           Select Files to Attach
        </Button>
      </label>

      <Box width="100%" mr={1}>
        <Typography variant="subtitle2" className={`upload-message ${isError ? 'error' : ''}`}>
          {message}
        </Typography>
      </Box>
    </div >
  );
};

export default UploadFiles;
