import React from 'react';
import PeopleIcon from '@material-ui/icons/People';
import isAuthenticated from '../../components/auth/isAuthenticated';

import IconHeading from '../../components/icon-heading';

import ActionButton from '../../components/buttons/action-button';
import CustomerList from './list-table';

import './customers-list.scss';

const CustomersContainer = (props) => {
  const { history } = props;
  // todo: we should probably have a unauthorized page that shows
  // when a user is not allowed
  return (
    <div className="customers">
      <IconHeading icon={<PeopleIcon fontSize="large" />} title={'Customers'} />
      <div className="customers--add">
        <ActionButton
          label="Add a customer"
          onClick={() => history.push('/customer/add')}
        />
      </div>
      <CustomerList {...props} />
    </div>
  );
};

export default isAuthenticated(CustomersContainer);
