import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import updateCancellationReasonsMutation from '../mutations/update-cancellation-reasons';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  formField: {
    width: '90%',
    marginTop: '10px'
  },
  actions: {
    paddingBottom: '20px'
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: '10px'
  }
}));

const AddReasonForm = (props) => {
  const classes = useStyles();
  const {
    handleClose,
    dialogOpen,
  } = props;
  const initialFormValues = {
    code: '',
    category: 'VOLUNTARY',
    name: ''
  };
  const [codeValue, setCodeValue] = useState(null);
  const [nameValue, setNameValue] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    if (codeValue && nameValue) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [codeValue, nameValue]);

  const [addCancellationReason, { loading, error }] = useMutation(
    updateCancellationReasonsMutation,
    {
      client: props.client,
      onCompleted: () => handleClose()
    }
  );
  const handleMutation = async (values) => {
    addCancellationReason({
      variables: {
        input: {
          code: values.code || null,
          name: values.name || null,
          category: values.category
        }
      }
    });
  };

  return (
        <Dialog open={dialogOpen}>
            <DialogContent>
                <DialogTitle disableTypography>
                    <Typography variant="h3">Add Reason</Typography>
                    {error ? (
                        <p className={classes.errorText}>There was an error</p>
                    ) : null}
                </DialogTitle>
                <Formik
                    initialValues={initialFormValues}
                    onSubmit={handleMutation}
                    render={({
                      values,
                      errors,
                      status,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting
                    }) => (
                            <form onSubmit={handleSubmit} id="cancel-form">
                                <div className={classes.formContainer}>
                                    <TextField
                                        select
                                        size="small"
                                        variant="outlined"
                                        name="category"
                                        className={classes.formField}
                                        value={values.category}
                                        onChange={handleChange}
                                        label="Category"
                                    >
                                        <MenuItem value={'INVOLUNTARY'}>Involuntary</MenuItem>
                                        <MenuItem value={'VOLUNTARY'}>Voluntary</MenuItem>
                                        <MenuItem value={'OTHER'}>Other</MenuItem>
                                    </TextField>
                                    <TextField
                                        size="small"
                                        className={classes.formField}
                                        variant="outlined"
                                        name="name"
                                        label="Name"
                                        value={values.name}
                                        onChange={(event) => {
                                          handleChange(event);
                                          setNameValue(event.target.value);
                                        }
                                        }
                                    />
                                    <TextField
                                        size="small"
                                        className={classes.formField}
                                        variant="outlined"
                                        name="code"
                                        label="Code"
                                        value={values.code}
                                        onChange={(event) => {
                                          handleChange(event);
                                          setCodeValue(event.target.value);
                                        }}
                                    />
                                </div>
                            </form>
                    )}
                />
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button
                    form="cancel-form"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!!(loading || disableSubmit)}
                >
                    {loading ? 'Processing' : 'Submit'}
                </Button>
                <Button
                    disabled={!!loading}
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                >
                    Cancel
        </Button>
            </DialogActions>
        </Dialog>
  );
};

export default AddReasonForm;
