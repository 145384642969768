import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  expiration: {
    fontWeight: 600
  }
}));

const RoutingNumber = ({ props }) => {
  const classes = useStyles();
  const { routingTransitNumber } = props;
  return (
    <div>
      <span className={classes.expiration}>Routing # </span>
      {routingTransitNumber || ''}
    </div>
  );
};

export default RoutingNumber;
