import axios from 'axios';
import constants from '../../../constants';

const { vault } = constants;

const { PAYMENT_METHOD_BY_TOKEN, VAULT_API_KEY } = vault;

const getPaymentMethodDetails = async (token) => {
  const options = {
    headers: { 'x-api-key': `${VAULT_API_KEY}` },
  };

  const details = await axios.get(`${PAYMENT_METHOD_BY_TOKEN + token}`, options);
  const { data } = details;
  return { ...data.data, category: data.data.scheme.toUpperCase() };
};

export default getPaymentMethodDetails;
