import { gql } from '@apollo/client';

export default gql`
  query getPaymentMethodsQuery($input: GetByExternalIdInput!) {
    getPaymentMethods(input: $input) {
      id
      token
      primary
      code
      status
      routingTransitNumber
      billingDetails {
        address
        address2
        locality
        region
        postal
        country
      }
    }
  }
`;
