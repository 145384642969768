import { gql } from '@apollo/client';

export default gql`
  query getTransactionsByExternalIdQuery($input: GetByExternalIdInput!) {
    getTransactionsByExternalId(input: $input) {
      id
      status
      amount
      currency
      externalId
      gateway
      code
      date
      txid
      status
      requestId
      debit
      time
      authCode
      paymentMethods {
        brand
        last
        token
      }
      action
    }
  }
`;
