import _ from 'lodash';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { gql, useQuery, useMutation } from '@apollo/client';
import React, { useState, Fragment, useEffect } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';

import ProductGrants from './form/grants';
import ProductPrices from './form/prices';
import MetadataForm from './form/metadata';
import { cleanProductForMutation } from '../utils';
import cleanTypeName from '../utils/cleanTypeName';
import getProductQuery from './queries/getProduct';
import ProductGrid from '../products/products-grid';
import ProductSchema from './form/validation-schema';
import ProductTypeSelector from './select-product-type';
import SaveButton from '../../components/buttons/save-button';
import searchProducts from '../products/queries/searchProducts';
import editProductMutation from './mutations/editProductMutation';
import MerchantProductIdInput from './form/merchant-product-id-input';

import './product-form.scss';

const getPriceConfigQuery = gql`
  {
    getPriceConfig {
      frequencies
      currencies {
        name
        symbol
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  textMultiline: {
    width: '100%'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  formControl: {
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formSection: {
    margin: '20px 0'
  },
  sectionItem: {
    marginTop: 0
  },
  errorText: {
    color: theme.palette.error.main,
    margin: 0
  }
}));

const ProductEdit = (props) => {
  const [_products, setProducts] = useState([]);
  const [loaded, setloaded] = useState(false);
  const [_priceConfig, setPrices] = useState([]);
  const { match } = props;
  const { params } = match;
  const classes = useStyles();
  const {
    data: productData,
    error: getProductQueryError,
    loading: getProductQueryLoading
  } = useQuery(getProductQuery, {
    variables: {
      productId: params.productId
    }
  });
  const { loading: getPriceConfigLoading, data: priceConfigData } = useQuery(getPriceConfigQuery);
  const { history } = props;
  const [updateProduct] = useMutation(editProductMutation, {
    update: (store, { data: { editProduct } }) => {
      // todo: handle errors appropriately. dont update cache with dirty state
      try {
        const cacheData = store.readQuery({
          query: searchProducts,
          variables: { input: { searchTerm: '' } }
        });
        const _newCacheData = [...cacheData.products];
        const itemIndex = _newCacheData.findIndex(
          (item) => item.id === editProduct.id
        );
        if (itemIndex >= 0) {
          _newCacheData.splice(itemIndex, 1, editProduct);
          store.writeQuery({
            query: searchProducts,
            variables: { input: { searchTerm: '' } },
            data: {
              products: _newCacheData
            }
          });
        }
        history.push('/products');
      } catch (error) {
        history.push('/products');
      }
    }
  });

  const priceConversion = ({ getProduct }) => {
    const value = getProduct.prices.map((price) => {
      const _currency = _priceConfig.currencies.find(
        (item) => price.currency.name === item.name
      );
      return {
        ...price,
        currency: _currency.name
      };
    });
    return value;
  };

  useEffect(() => {
    if (!getPriceConfigLoading) setPrices(priceConfigData.getPriceConfig);
  }, [getPriceConfigLoading, priceConfigData]);

  useEffect(() => {
    // In prices, we need to map the currency name to the full currency object
    if (productData && _priceConfig.currencies) {
      const newPrices = priceConversion(productData);
      setPrices(newPrices);
    }
  }, [productData]);

  const submitForm = async (formValues) => {
    // const { history } = props;
    const data = cleanProductForMutation(_.set(formValues, 'products', _products));
    const { prices } = data;
    const _prices = prices.map((price) => {
      // An existing price will already have the full currency object
      // and will not need to be mapped with the price config
      if (price.currency.name && price.currency.symbol) {
        return price;
      }
      const _currency = _priceConfig.currencies.find(
        (item) => price.currency === item.name
      );
      return {
        ...price,
        currency: _currency
      };
    });
    const _data = {
      ...data,
      taxCode: data.taxCode || null,
      glCode: data.glCode || null,
      prices: _prices
    };

    const cleanedData = cleanTypeName(_data);
    const variables = {
      input: {
        ...cleanedData,
        id: params.productId
      }
    };

    await updateProduct({ variables: { ...variables } });

    // history.push('/products');
  };

  const handleSelectProduct = (selectedProduct) => {
    const product = cleanProductForMutation(selectedProduct);
    const idx = _products.findIndex((item) => item.id === product.id);
    const existingProducts = [].concat(_products);

    if (idx === -1) {
      existingProducts.push(product);
      setProducts(existingProducts);
    } else {
      existingProducts.splice(idx, 1);
      setProducts(existingProducts);
    }
  };

  // TODO: this is the function that loads the component with data,
  // it should probably be its own component. I didn't have time
  // to get through the business logic before end of sprint
  const loadComponentWithData = (data) => {
    const { getProduct } = data;
    const {
      grants = [],
      products = [],
      // prices = [],
      metadata = [],
      merchantProductId,
      sku,
      type,
      description,
      taxCode,
      glCode
    } = getProduct;

    const initialFormValues = {
      prices: priceConversion({ getProduct }),
      merchantProductId,
      sku,
      type,
      description,
      metadata,
      products,
      grants,
      taxCode,
      glCode
    };
    if (!loaded) {
      setProducts(products);
      setloaded(true);
    }

    return (
      <div className="product-form">
        <Formik
          validationSchema={ProductSchema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialFormValues}
          onSubmit={submitForm}
          render={({
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting
          }) => {
            const productType = _.get(values, 'type');
            const productGrid = productType && productType === 'bundle' ? (
                <ProductGrid
                  onChange={handleSelectProduct}
                  selectedProducts={_products}
                />
            ) : null;

            return (
              <form onSubmit={handleSubmit}>
                <div className="product-form-row">
                  <MerchantProductIdInput
                    classes={classes}
                    onChange={handleChange}
                    errors={errors}
                    values={values}
                  />
                  <FormControl className={classes.formControl}>
                    <TextField
                      className={classes.textField}
                      label="SKU"
                      name="sku"
                      margin="normal"
                      onChange={handleChange}
                      type="text"
                      value={values.sku || ''}
                    />
                    <FormHelperText className={classes.errorText}>
                      {values.sku === '' || !values.sku
                        ? errors.sku
                        : null}
                    </FormHelperText>
                  </FormControl>
                  {productType === 'onetime' ? null : (
                    <ProductTypeSelector
                      name="type"
                      onChange={handleChange}
                      values={values}
                    />
                  )}
                </div>
                <div className={'product-form-row'}>
                  {/* <FormControl className={classes.formControl}>
                    <TextField
                      className={classes.textField}
                      label="Merchant Id"
                      name="merchantId"
                      required
                      margin="normal"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      value={values.merchantId || ''}
                    />
                    <FormHelperText className={classes.errorText}>
                      {!values.merchantId || values.merchantId === '' ? errors.merchantId : null}
                    </FormHelperText>
                  </FormControl> */}
                  <FormControl className={classes.formControl}>
                    <TextField
                      className={classes.textField}
                      label="Tax Code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.taxCode || ''}
                      name="taxCode"
                      type="text"
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className={classes.textField}
                      label="GL Code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.glCode || ''}
                      name="glCode"
                      type="text"
                    />
                  </FormControl>
                  <FormHelperText></FormHelperText>
                </div>
                <div className="product-form-row">
                  <FormControl
                    className={classes.formControl}
                    fullWidth={true}
                  >
                    <TextField
                      name="description"
                      label="Internal Product Description"
                      multiline
                      rowsMax="10"
                      value={values.description || ''}
                      onChange={handleChange}
                      className={classes.textMultiline}
                      margin="normal"
                    />
                    <FormHelperText className={classes.errorText}>
                      {!values.description || values.description === ''
                        ? errors.description
                        : null}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div
                  className={`product-form-row ${classes.formSection}`}
                >
                  <ProductPrices
                    data={values.prices}
                    onChange={handleChange}
                    errors={errors.prices}
                    productType={productType}
                  />
                </div>
                <div
                  className={`product-form-row ${classes.formSection}`}
                >
                  <MetadataForm
                    data={values.metadata}
                    classes={classes}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={`product-form-row ${classes.formSection}`}
                >
                  <ProductGrants
                    data={values.grants}
                    classes={classes}
                    onChange={handleChange}
                    errors={errors.grants}
                  />
                </div>
                <div className="product-form-row">{productGrid}</div>
                <SaveButton
                  label="Save Product"
                  onClick={() => handleSubmit(values)}
                />
              </form>
            );
          }}
        />
      </div>);
  };

  return (
    <Fragment>
      <Typography variant="h1">Edit Product</Typography>
      {getProductQueryLoading && <p>Loading...</p>}
      {getProductQueryError && <p>{getProductQueryError.message} :(</p>}
      {productData && _priceConfig.currencies && loadComponentWithData(productData)}
    </Fragment>
  );
};

export default ProductEdit;
