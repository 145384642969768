import { gql } from '@apollo/client';

export default gql`
  {
    getMerchantConfig {
      id
      acquirers {
        acquirerId
        name
        merchantId
      }
      cancellationReasons {
        code
        name
        category
      }
    }
  }
`;
