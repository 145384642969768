import * as Yup from 'yup';

const ProductValidationSchema = Yup.object().shape({
  merchantProductId: Yup.string()
    .required('Merchant Product Id is required')
    .nullable(),
  sku: Yup.string()
    .required('Product sku is required')
    .nullable(),
  description: Yup.string()
    .required('Product description is required')
    .nullable(),
  prices: Yup.array().of(
    Yup.object().shape({
      currency: Yup.string()
        .required('Currency is required')
        .nullable(),
      amount: Yup.number()
        .positive('Amount must be a number greater than 0')
        .typeError('Amount must be a number greater than 0')
        .nullable()
    })
  ),
  grants: Yup.array()
    .ensure()
    .of(
      Yup.object().shape({
        id: Yup.string()
          .required('ID is required')
          .nullable(),
        description: Yup.string()
          .required('Description is required')
          .nullable()
      })
    ),
  metadata: Yup.array()
    .ensure()
    .of(
      Yup.object().shape({
        key: Yup.string()
          .required('Key is required')
          .nullable(),
        value: Yup.string()
          .required('Value is required')
          .nullable()
      })
    )
});

export default ProductValidationSchema;
