import { gql } from '@apollo/client';

export default gql`
  query getProductQuery($productId: String) {
    getProduct(productId: $productId) {
      id
      merchantId
      merchantProductId
      sku
      type
      taxCode
      glCode
      description
      status
      metadata {
        key
        value
      }
      prices {
        frequency
        currency {
          name
          symbol
        }
        amount
      }
      products {
        id
        sku
        taxCode
        glCode
        merchantProductId
        type
        status
        description
        metadata {
          key
          value
        }
        prices {
          frequency
          currency {
            name
            symbol
          }
          amount
        }
        grants {
          id
          description
        }
        currentVersion
      }
      grants {
        id
        description
      }
      currentVersion
    }
  }
`;
