import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MerchantConfigView from './view/merchant-config-view';
import ConfigureNewAcquirer from './acquirers/configure-acquirer';

import isAuthenticated from '../../components/auth/isAuthenticated';
import NotFound from '../../components/not-found';

const ConfigRoutes = (props) => (
    <Switch>
      <Route
        exact
        path="/config"
        render={() => <MerchantConfigView {...props} />}
      />
      <Route
        exact
        path="/config/configure-acquirer"
        render={() => <ConfigureNewAcquirer {...props} />}
      />
      <Route
        exact
        path="/config/configure-acquirer/:acquirerId"
        render={() => <ConfigureNewAcquirer {...props} />}
      />
      <Route
        exact
        path="/config/configure-merchant-ids"
        render={() => <ConfigureNewAcquirer {...props} />}
      />
      <Route
        exact
        path="/config/configure-codes"
        render={() => <ConfigureNewAcquirer {...props} />}
      />
      <Route
        exact
        path="/config/configure-recycle-rules"
        render={() => <ConfigureNewAcquirer {...props} />}
      />
      <Route component={NotFound} />
    </Switch>
);

export default isAuthenticated(ConfigRoutes);
