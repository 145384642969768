import { gql } from '@apollo/client';

const addProduct = gql`
  mutation addProductMutation($input: AddProductInput) {
    addProduct(input: $input) {
      id
      sku
      merchantProductId
      taxCode
      glCode
      type
      status
      description
      metadata {
        key
        value
      }
      prices {
        frequency
        currency {
          name
          symbol
        }
        amount
      }
      products {
        id
        sku
        taxCode
        glCode
        merchantProductId
        type
        status
        description
        metadata {
          key
          value
        }
        prices {
          frequency
          currency {
            name
            symbol
          }
          amount
        }
        currentVersion
        grants {
          id
          description
        }
      }
      currentVersion
      grants {
        id
        description
      }
    }
  }
`;

export default addProduct;
