import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  },
  list: {
    padding: 0,
    margin: 0,
    fontSize: '14px'
  },
  productsContainer: {
    marginTop: '10px'
  }
}));

const SubscriptionProducts = (props) => {
  const classes = useStyles();
  const { products } = props;
  return (
    <div className={classes.productsContainer}>
      <Typography variant="subtitle2" className={classes.title}>
        Products
      </Typography>
      <List dense={true} className={classes.list}>
        {!products || products.length === 0
          ? null
          : products.map((product, index) => (
                <ListItem key={`product-${index}`} className={classes.list}>
                  <ListItemText
                    primary={product.description}
                    secondary={`Merchant Id: ${product.merchantProductId} - SKU: ${product.sku}`}
                    className={classes.list}
                  />
                </ListItem>
          ))}
      </List>
    </div>
  );
};

export default SubscriptionProducts;
