import { gql } from '@apollo/client';

export default gql`
  query getInvoicesQuery($input: GetByCustomerIdInput!) {
    getInvoiceByCustomerId(input: $input) {
      id
      number
      subscriptionId
      balance
      created
      modified
      status
      total
      totalTax
      externalId
      description
      customerSnapshot
      collectionMethod
      period {
        start
        end
      }
      lineItems {
        currency
        amount
        externalId
        # product {
        #   amount
        #   currency {
        #     name
        #     symbol
        #   }
        #   description
        #   id
        #   merchantProductId
        #   quantity
        #   sku
        #   type
        # }
        productDescription
        productId
        productName
        productType
        quantity
        sku        
      }      
      paymentMethodIds
    }
  }
`;
