import React from 'react';

import { format, addDays } from 'date-fns';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import Heading from '../../components/icon-heading';
import Table from '../../components/table';

import './batch-processing.scss';

const baseDate = new Date();
const data = [
  {
    date: format(addDays(baseDate, 0), 'MM/dd/yyyy'),
    id: 1003,
    total: 1023,
    success: 1001,
    chargeback: 22
  },
  {
    date: format(addDays(baseDate, -1), 'MM/dd/yyyy'),
    id: 1002,
    total: 976,
    success: 965,
    chargeback: 11
  },
  {
    date: format(addDays(baseDate, -2), 'MM/dd/yyyy'),
    id: 1001,
    total: 844,
    success: 837,
    chargeback: 7
  },
  {
    date: format(addDays(baseDate, -3), 'MM/dd/yyyy'),
    id: 1000,
    total: 876,
    success: 859,
    chargeback: 17
  }
];
const columnsToShow = [
  {
    key: 'date',
    displayName: 'Processed Date'
  },
  {
    key: 'id',
    displayName: 'Batch'
  },
  {
    key: 'success',
    displayName: 'Successes'
  },
  {
    key: 'chargeback',
    displayName: 'Charge Backs'
  },
  {
    key: 'total',
    displayName: 'Total'
  }
];
// TODO maybe change files and component names to Batch?
const BatchProcessingContainer = (props) => (
    <div className="gateway">
      <Heading
        title="Batch Processing"
        icon={<GraphicEqIcon fontSize="large" />}
      />
      <Table columnsToShow={columnsToShow} data={data} />
    </div>
);

export default BatchProcessingContainer;
