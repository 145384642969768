import _ from 'lodash';

const unsetDeep = (variable, remove) => {
  if (_.isPlainObject(variable)) {
    return _.reduce(variable, (output, value, key) => (
      key === remove ? output : _.set(output, key, unsetDeep(value, remove))
    ), {});
  }

  if (_.isArray(variable)) {
    return _.reduce(variable, (output, value) => [...output, unsetDeep(value, remove)], []);
  }

  return variable;
};

/**
 * This function will recursively remove all __typename properties from the product.
 * @param {object} product - defined in schema
 * @returns {object}
 */
const cleanProductForMutation = (product) => unsetDeep(product, '__typename');

export default cleanProductForMutation;
