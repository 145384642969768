import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery } from '@apollo/client';
import searchProducts from '../../containers/products/queries/searchSubscriptionProducts';
import './quick-search.scss';

const NO_PRODUCTS = 'No Products to Show';
const renderSuggestion = (suggestion) => {
  if (suggestion === NO_PRODUCTS) {
    return (
      <div className="quick-search-suggestion">
        <div className="product-id">{NO_PRODUCTS}</div>
        <div className="product-sku"></div>
      </div>
    );
  }

  return (
    <div className="quick-search-suggestion">
      <div className="product-description">{suggestion.description}</div>
      <div className="product-id">{suggestion.merchantProductId}</div>
      <div className="product-sku">SKU: {suggestion.sku}</div>
    </div>
  );
};

const QuickSearch = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const { loading, data } = useQuery(searchProducts, {
    variables: { input: { status: 'active', searchTerm: `${searchValue}` } }
  });

  let query;
  const { type, filterList = [] } = props;

  switch (type) {
    case 'products':
      query = searchProducts;
      break;
    default:
      break;
  }

  if (!query) {
    return null;
  }

  const getSuggestionValue = (dataObject) => dataObject.id;
  const clearSuggestions = () => {
    setSearchValue('');
  };
  // handles changes to the value of the search input
  const handleChange = (event) => {
    event.preventDefault();
    const { currentTarget } = event;
    const { value } = currentTarget;
    setSearchValue(value);
  };

  const fetchSuggestions = async (value, event) => {
    const suggestionsList = filterList.length > 0 && data.products.length > 0
      ? data.products.filter(
        (product) => !filterList.find((item) => item.id === product.id)
      )
      : data.products;

    if (suggestionsList.length === 0) {
      suggestionsList.push(NO_PRODUCTS);
    }
    setSuggestions(suggestionsList);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    fetchSuggestions(value);
  };

  const handleSelected = (event, dataObject) => {
    event.preventDefault();
    const { suggestion } = dataObject;

    if (suggestion !== NO_PRODUCTS) {
      props.onSelected(suggestion);
    }
  };

  const showProgress = loading ? (
    <div className="quick-search-progress">
      <CircularProgress size="1em" />
    </div>
  ) : null;

  const inputProps = {
    onChange: handleChange,
    value: searchValue,
    placeholder: 'search for a product'
  };

  return (
    <div className="quick-search">
      <Autosuggest
        suggestions={suggestions}
        inputProps={inputProps}
        renderSuggestion={renderSuggestion}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={clearSuggestions}
        onSuggestionSelected={handleSelected}
        getSuggestionValue={getSuggestionValue}
      />
      {showProgress}
    </div>
  );
};

export default QuickSearch;
