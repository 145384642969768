import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import CircleProgress from '../../components/progress/circle';

const useStyles = makeStyles((theme) => ({
  productTypeSelect: {
    marginLeft: '10px'
  }
}));

const getProductTypes = gql`
  {
    productTypes
  }
`;

const ProductTypeSelector = (props) => {
  const classes = useStyles();
  const { values, onChange } = props;
  const { loading, error, data } = useQuery(getProductTypes);

  return (
    <>
      {loading && <CircleProgress size="1em" />}
      {error && <p>{error.message} :(</p>}
      { data
        && <FormControl
          className={classes.productTypeSelect}
          key="product-type-selector"
        >
          <FormLabel component="legend">Product Type</FormLabel>
          <RadioGroup
            aria-label="type"
            name="type"
            value={values.type}
            onChange={onChange}
            row={true}
          >
            {data.productTypes.map((productType) => (
                <FormControlLabel
                  value={productType}
                  checked={productType === values.type}
                  control={<Radio />}
                  label={productType}
                  key={productType}
                />
            ))}
          </RadioGroup>
        </FormControl>
      }
    </>
  );
};

export default ProductTypeSelector;
