import React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthConsumer } from '../../contexts/authContext';

const isAuthenticated = (Component, props) => (
  class extends React.Component {
    displayName: 'Auth';

    render() {
      const { location } = this.props;
      const _from = location && location.pathname ? location.pathname : '/';

      return (
        <AuthConsumer>
          {(context) => {
            const { authenticated } = context;

            if (!authenticated) {
              return (
                <Redirect
                  to={{
                    pathname: '/sign-in',
                    state: { from: _from }
                  }}
                />
              );
            }

            return <Component {...this.props} auth={context} />;
          }}
        </AuthConsumer>
      );
    }
  }
);

export default isAuthenticated;
