import React, { Fragment, useState, useEffect } from 'react';
import * as dayjs from 'dayjs';
import _ from 'lodash';
import Papa from 'papaparse';
import { useQuery } from '@apollo/client';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CloudDownload from '@material-ui/icons/CloudDownload';
import MUIDataTable from 'mui-datatables';
import searchSubscriptions from './queries/searchSubscriptions';
import EmptyState from '../../components/empty-state';
import CircleProgress from '../../components/progress/circle';
import { getQueryStringParameter } from '../utils/queryString';

const useStyles = makeStyles({
  searchBar: {
    marginTop: '25px',
    marginBottom: '25px',
    display: 'flex !important',
  },
});

const options = {
  elevation: 0,
  selectableRows: 'none',
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  filter: false,
  rowsPerPage: [50],
  rowsPerPageOptions: false,
  sortOrder: { name: 'Updated On', direction: 'desc', default: true }
};

const columns = [
  {
    name: '',
    options: { filter: false, sort: false },
  },
  {
    name: 'Subscription ID',
    options: { filter: false, sort: true },
  },
  {
    name: 'Customer ID',
    options: { filter: false, sort: true },
  },
  {
    name: 'Status',
    options: { filter: false, sort: true },
  },
  {
    name: 'Next Bill Date',
    options: { filter: false, sort: true },
  },
  // {
  //   name: 'Last Name',
  //   options: { filter: false, sort: true },
  // },
  // {
  //   name: 'First Name',
  //   options: { filter: false, sort: true },
  // },
  {
    name: 'Products',
    options: { filter: false, sort: true },
  },
  {
    name: 'Total',
    options: { filter: false, sort: true },
  },
  {
    name: 'Created At',
    options: { filter: false, sort: true },
  },
  {
    name: 'Updated On',
    options: { filter: false, sort: true },
  }
];

const renderViewButton = (id, externalId, navToView) => (
  <Button
    color="primary"
    size="small"
    onClick={() => {
      navToView(id, externalId);
    }}
  >
    View
  </Button>
);

const CustomerList = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [searchInput, setSearchInput] = useState(getQueryStringParameter('search'));
  const { loading, error, data } = useQuery(searchSubscriptions, {
    fetchPolicy: 'no-cache',
    skip: searchInput.length < 0,
    variables: {
      input: {
        subscriptionSearchTerm: `${searchInput}`,
      },
    },
  });

  const navigateToView = (subscriptionId, externalId) => {
    history.push(`/subscriptions?search=${searchInput}`);
    history.push(`/${externalId}/subscription/${subscriptionId}/edit`);
  };

  const handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    setSearchInput(value);
  };

  const getSubscriptionRow = (subscription) => {
    const {
      id, created, modified, products, total, externalId, status, nextBill
    } = subscription;
    const button = <div>{renderViewButton(id, externalId, navigateToView)}</div>;
    const descriptions = products.map((product) => product.description);
    const joinedDescriptions = descriptions.join(', ');
    const currency = _.get(products, '[0].currency.symbol', '$');
    return [button, id, externalId, _.upperFirst(status), nextBill?.date ? dayjs(nextBill.date).format('YYYY-MM-DD') : '-',
      joinedDescriptions, `${currency}${total}`,
      dayjs(created).format('YYYY-MM-DD'), dayjs(modified).format('YYYY-MM-DD')];
  };

  const getCSVRow = (subscription) => {
    const products = _.get(subscription, 'products', []);
    const descriptions = products.map((product) => product.description);
    const joinedDescriptions = descriptions.join(', ');
    _.set(subscription, 'products', joinedDescriptions);
    // Remove paymanetMethods from CSV
    _.unset(subscription, 'paymentMethods');
    // Set nextBill.date to nextBill
    const nextBill = _.get(subscription, 'nextBill.date', '');
    _.set(subscription, 'nextBill', nextBill);
    const currency = _.get(products, '[0].currency.name', 'USD');
    _.set(subscription, 'currency', currency);
    const cancellationReason = _.get(subscription, 'cancellationReason.name', '');
    _.set(subscription, 'cancellationReason', cancellationReason);
    return subscription;
  };

  const downloadCSV = (rows) => {
    const csv = Papa.unparse(rows);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    // Get pathname and remove first and  trailing slash
    const pathname = window.location.pathname.replace(/^\/|\/$/g, '');
    // Get timestamp
    const timestamp = dayjs().format('YYYY-MM-DD');
    link.download = `${pathname}_${timestamp}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    setSearchInput(getQueryStringParameter('search'));
  }, []);

  const renderTableWithData = (tableData) => {
    const { subscriptions } = tableData;
    if (!subscriptions || subscriptions.length === 0) {
      return <EmptyState message="no subscriptions to show" />;
    }
    const rows = subscriptions.map((subscription) => getSubscriptionRow(subscription));
    return <MUIDataTable data={rows} columns={columns} options={options} />;
  };

  const CSVButton = (tableData) => {
    const { subscriptions } = tableData;
    if (!subscriptions || subscriptions.length === 0) {
      return null;
    }
    const rows = subscriptions.map((subscription) => getCSVRow(subscription));
    return <Button onClick={() => downloadCSV(rows)} size='small' variant="contained" color="primary" startIcon={<CloudDownload />}>
      Download CSV
    </Button>;
  };

  return (
    <Fragment>
      <TextField
        defaultValue={searchInput}
        placeholder="Subscription Search"
        onChange={handleChange}
        className={classes.searchBar}
        InputProps={{
          startAdornment: (
            <InputAdornment className={classes.input} position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {loading && <CircleProgress size="1em" />}
      {error && <p>{error.message} :(</p>}
      {data && <Box display="flex" justifyContent="flex-end">
        <CSVButton {...data} />
      </Box>}
      {data && renderTableWithData(data)}
    </Fragment>
  );
};

export default CustomerList;
