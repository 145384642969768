const _groups = 'cognito:groups';

const AuthReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'auth-request': {
      return {
        ...state,
        inFlight: true,
        authErrorText: undefined
      };
    }
    case 'auth-challenge': {
      const { challengeName } = payload;

      return {
        ...state,
        authChallenge: challengeName,
        inFlight: false,
        user: payload
      };
    }
    case 'auth-error': {
      return {
        ...state,
        authErrorText: payload,
        inFlight: false
      };
    }
    case 'auth-sign-out':
      return payload;
    case 'auth-success': {
      const {
        signInUserSession: { idToken }
      } = payload;
      const { payload: userData } = idToken;

      localStorage.setItem('token', idToken.jwtToken);

      return {
        authChallenge: undefined,
        authErrorText: undefined,
        inFlight: false,
        user: payload,
        groups: userData[_groups] || []
      };
    }

    default:
      return state;
  }
};

export default AuthReducer;
