import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Can from '../../components/auth/userCanPerform';
import getInvoicesByStatus from './queries/getInvoices';
import CircleProgress from '../../components/progress/circle';
import EmptyState from '../../components/empty-state';
import IconHeading from '../../components/icon-heading';
import InvoicesTable from './list-table';
import { authContext } from '../../contexts/authContext';

const InvoiceList = (props) => {
  const { loading, error, data } = useQuery(getInvoicesByStatus, {
    variables: {
      input: { status: 'due' }
    }
  });

  const auth = useContext(authContext);

  const displayComponentWithData = (displayData) => (
    !displayData.invoices || displayData.invoices.length === 0
      ? <EmptyState message="no invoices to show" />
      : <InvoicesTable
          auth={auth}
          invoices={displayData.invoices}
      />
  );

  return (
    <Can
      groups={auth.groups}
      perform="invoices:view"
      yes={() => (
        <div className="invoices">
          <IconHeading
            icon={<ReceiptIcon fontSize="large" />}
            title={'Open Invoices'}
          />
          {loading && <CircleProgress size="1em" />}
          {error && <p>{error.message}</p>}
          {data && displayComponentWithData(data)}
        </div>
      )}
    />
  );
};

export default InvoiceList;
