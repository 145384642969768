import { gql } from '@apollo/client';

export default gql`
  mutation editSubscriptionMutation($input: SubscriptionInput) {
    editSubscription(input: $input) {
      id
      created
      modified
      status
      start
      total
      end
      collectionMethod
      currency {
        name
        symbol
      }
      frequency
      products {
        id
        merchantProductId
        sku
        type
        description
        metadata {
          key
          value
        }
        amount
        frequency
        currency {
          name
          symbol
        }
      }
      paymentMethods {
        category
        token
        brand
        issuer_id
        last
        expiration
        billingDetails {
          address
          address2
          locality
          region
          postal
          country
        }
      }
    }
  }
`;
