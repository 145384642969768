import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { authContext } from '../../contexts/authContext';

import './signin.scss';

const SignInPage = (props) => {
  const context = useContext(authContext);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorText, setErrorText] = useState();
  const [resetPassword, setResetPassword] = useState(false);
  const [code, setCode] = useState();
  const { initiateLogin, authErrorText } = context;
  const { location } = props;

  const { forgotPassword, completeNewPassword } = context;

  useEffect(() => {
    if (authErrorText !== 'not authenticated') {
      setErrorText(authErrorText);
    }
  }, [authErrorText]);

  const handleChange = (event) => {
    event.preventDefault();
    const { currentTarget } = event;
    const { name, value } = currentTarget;

    switch (name) {
      case 'password':
        setPassword(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'code':
        setCode(value);
        break;
      default:
        break;
    }
  };

  const handleSignIn = async (event) => {
    const { history } = props;
    event.preventDefault();

    if (!email || !password) return null;
    return initiateLogin({ email, password }, history);
  };

  if (context.authenticated) {
    // state is passed from isAuthenticated
    const { state } = location;
    const redirectTo = state && state.from ? state.from : '/';
    return <Redirect to={redirectTo} />;
  }

  const handleForgotPassword = async () => {
    const res = await forgotPassword(email);
    console.log(res);
    if (res.error) {
      setErrorText(res.message);
      return false;
    }
    setResetPassword(true);
    return res;
  };

  const handleSubmitNewPassword = async (event) => {
    const { history } = props;
    event.preventDefault();
    if (!code || !password) {
      setErrorText('Code and password required');
      return false;
    }
    const res = await completeNewPassword({ email, code, password }, history);
    if (res?.error) {
      setErrorText(res.message);
      return false;
    }
    setResetPassword(false);
    setCode('');
    setPassword('');
    setErrorText('');
    return res;
    // return initiateLogin({ email, password }, history);
  };

  return (
    <div className="signin">
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        value={email || ''}
        onChange={handleChange}
      />
      {resetPassword && <TextField
        autoComplete="code off"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="code"
        label="Code"
        type="text"
        id="code"
        value={code}
        onChange={handleChange}
      />}
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label={resetPassword ? 'New Password' : 'Password'}
        type="password"
        id="password"
        onChange={handleChange}
        value={password || ''}
      />

      <Link href="JavaScript:void(0)" onClick={() => handleForgotPassword()}>Forgot Password?</Link>
      <div className="auth-error-text">{errorText}</div>
      {
        !resetPassword ? <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSignIn}
        >
          Sign In
        </Button>
          : <Button
        type="button"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmitNewPassword}
      >
        Reset Password
      </Button>
      }
    </div>
  );
};

export default SignInPage;
