import _ from 'lodash';
import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import { Formik } from 'formik';
import Constants from '../../../constants';

const { vault } = Constants;
const { MODIFY_URL, VAULT_API_KEY } = vault;

const AddressInput = styled(TextField)({
  marginBottom: '5px',
  marginRight: '0px',
  marginLeft: '24px',
  width: '100%'
});

const RegionInput = styled(TextField)({
  flexBasis: '25%',
  marginRight: '10px'
});

const PostalInput = styled(TextField)({
  flexBasis: '75%'
});

const RowWrapper = styled(Container)({
  display: 'flex',
  margin: '0px',
  width: '100%'
});

const ButtonWrapper = styled(Container)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '50%'
});

const ExpirationInput = styled(TextField)({
  margin: '24px'
});

const InfoWrapper = styled(Container)({
  display: 'flex',
  padding: '0',
  marginBottom: '24px'
});

const InputWrapper = styled(Container)({
  display: 'flex',
  padding: '0'
});

const ModalWrapper = styled(Container)({
  background: 'white',
  borderRadius: '5px',
  left: '30%',
  outline: 'none',
  padding: '10px 15px',
  position: 'absolute',
  top: '30%',
  userSelect: 'none',
  width: '600px'
});

const regex = /^[0-9\b]+$/;

const monthError = (input) => {
  if (!input) {
    return true;
  }
  // Ensures input is only numbers 0-9 and length is 2
  if (input.length !== 2 || !input.match(regex)) {
    return true;
  }
  // Ensures first two digits are a valid month
  if (input.length === 2 && Number(input) > 12) {
    return true;
  }

  return false;
};

export const yearError = (input, month) => {
  if (!input) {
    return true;
  }
  // Ensures input is only numbers 0-9 and length is 2
  if (input.length !== 2 || !input.match(regex)) {
    return true;
  }
  // Ensures year is not in the past
  const today = new Date();
  if (input.length === 2 && Number(input) < Number(String(today.getFullYear()).slice(2, 4))) {
    return true;
  }
  // Ensures last two digits set expiration within 20 years.
  if (
    input.length === 2
    && Number(input) > (Number(String(today.getFullYear()).slice(2, 4)) + 20)
  ) {
    return true;
  }

  if (
    Number(input) === Number(String(today.getFullYear()).slice(2, 4))
    && (Number(month) <= (today.getMonth() + 1))
  ) {
    return true;
  }

  return false;
};

const ModalBody = (customerId, editPaymentMethodMutation, handleClose, row) => {
  let monthInput = row.expiration && row.expiration.slice(0, 2);
  let yearInput = row.expiration && row.expiration.slice(2, 4);
  let addressInput = row.billingDetails && row.billingDetails.address;
  let addressTwoInput = row.billingDetails && row.billingDetails.address2;
  let localityInput = row.billingDetails && row.billingDetails.locality;
  let postalInput = row.billingDetails && row.billingDetails.postal;
  let regionInput = row.billingDetails && row.billingDetails.region;
  const countryInput = row.billingDetails && row.billingDetails.country;

  return (
    <ModalWrapper>
      <Typography variant='h6'>Editing Payment Method:</Typography>
      <InfoWrapper>{row.displayCard}</InfoWrapper>
      <Formik
        initialValues={{ customerId, ..._.pick(row, ['token', 'expiration', 'billingDetails']) }}
        onSubmit={async (values) => {
          const concatExpiration = monthInput + yearInput;
          const newBillingDetails = {
            address: addressInput,
            address2: addressTwoInput,
            locality: localityInput,
            region: regionInput,
            postal: postalInput,
            country: countryInput
          };
          const options = {
            headers: { 'x-api-key': `${VAULT_API_KEY}` },
          };
          const payload = {
            expiration: concatExpiration,
            billingDetails: newBillingDetails,
            token: values.token
          };

          const response = await axios.post(MODIFY_URL, payload, options);
          const { data, errorMessage } = response;

          if (errorMessage) {
            const error = Object.values(data).join(' ');
            const _message = error || 'There was an error.';
            // eslint-disable-next-line
            console.error(error, _message);
            return;
          }

          editPaymentMethodMutation({
            variables:
            {
              input:
              {
                customerId: values.customerId,
                token: values.token,
                expiration: concatExpiration,
                billingDetails: newBillingDetails
              }
            }
          });
          handleClose();
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <AddressInput
              label="Billing Address"
              name="billingAddress"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={addressInput || ''}
              onInput={(e) => { addressInput = e.target.value; }}
            />
            <AddressInput
              label="Address 2"
              name="billingAddress2"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={addressTwoInput || ''}
              onInput={(e) => { addressTwoInput = e.target.value; }}
            />
            <AddressInput
              label="City"
              name="billingLocality"
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              value={localityInput || ''}
              onInput={(e) => { localityInput = e.target.value; }}
            />
            <RowWrapper>
              <RegionInput
                label="State"
                name="billingRegion"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                value={regionInput || ''}
                onInput={(e) => { regionInput = e.target.value; }}
              />
              <PostalInput
                label="Billing Zip Code"
                name="billingPostal"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                value={postalInput || ''}
                onInput={(e) => { postalInput = e.target.value; }}
              />
            </RowWrapper>
            <InputWrapper>
              <ExpirationInput
                type="expiration"
                name="expiration"
                onChange={handleChange}
                onBlur={handleBlur}
                value={monthInput}
                label="Month (MM)"
                placeholder="MM"
                error={monthError(monthInput)}
                onInput={(e) => { monthInput = e.target.value; }}
              />
              <ExpirationInput
                type="expiration"
                name="expiration"
                onChange={handleChange}
                onBlur={handleBlur}
                value={yearInput}
                label="Year (YY)"
                placeholder="YY"
                error={yearError(yearInput, monthInput)}
                onInput={(e) => { yearInput = e.target.value; }}
              />
            </InputWrapper>
            <ButtonWrapper>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={monthError(monthInput) || yearError(yearInput, monthInput)}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </ButtonWrapper>
          </form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default ModalBody;
