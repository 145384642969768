import React from 'react';
import Button from '@material-ui/core/Button';

const SaveButton = (props) => {
  const { label, onClick, disabled = false } = props;

  return (
    <Button
      disabled={disabled}
      type="button"
      variant="contained"
      size="medium"
      color="primary"
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default SaveButton;
