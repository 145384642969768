/*
  the schema for auth lives in the payments repo.

  below are current on: 2021-01-27

  input AuthorizePaymentMethodInput {
    amount: Float!
    billingDetails: BillingDetailsInput
    brand: String
    category: PaymentMethodCategory!
    customerId: String!
    expiration: String
    id: String
    issuer_id: String
    last: String
    primary: Boolean!
    token: String!
  }

  input BillingDetailsInput {
    accountHolder: AccountHolderInput
    address: String
    address2: String
    locality: String
    region: String
    postal: String
    country: String
  }

  input AccountHolderInput {
    firstName: String!
    lastName: String!
  }
*/
const CREDIT = 'CREDIT';
const DDA = 'DDA';

export const getAuthorizationPayload = (args) => {
  try {
    const {
      amount,
      billingDetails,
      customerId,
      paymentMethod
    } = args;
    /*
      destructuring keys the API does not allow. some of these are coming
      from the vault finalize and the others could be from an existing
      payment method.

      code
      id
      routingTransitNumber
      scheme
      status
      type
    */
    const {
      code,
      id,
      routingTransitNumber,
      scheme,
      status,
      success,
      type,
      address,
      address2,
      city,
      state,
      zip,
      ...rest
    } = paymentMethod;
    const {
      accountHolderFirstName,
      accountHolderLastName,
      billingAddress,
      billingAddress2,
      billingLocality,
      billingRegion,
      billingPostal
    } = billingDetails;

    let accountHolder;
    if (accountHolderFirstName && accountHolderLastName) {
      accountHolder = {
        firstName: accountHolderFirstName,
        lastName: accountHolderLastName
      };
    }
    const category = paymentMethod.category === DDA ? DDA : CREDIT;

    return {
      ...rest,
      amount,
      billingDetails: {
        accountHolder,
        address: billingAddress || address || null,
        address2: billingAddress2 || address2 || null,
        locality: billingLocality || city || null,
        region: billingRegion || state || null,
        postal: billingPostal || zip || null,
        country: null
      },
      category,
      customerId,
      primary: false,
      token: rest.token
    };
  } catch (err) {
    const _error = err && err.message ? err.message : 'something when wrong.';
    // eslint-disable-next-line no-console
    console.error(_error);

    return false;
  }
};
/*
  input OneTimePaymentMethodInput {
    id: String
    billingDetails: BillingDetailsInput
    brand: String
    category: PaymentMethodCategory!
    expiration: String
    issuer_id: String
    last: String
    primary: Boolean!
    token: String!
    status: String
    code: String
  }
*/
export const getOneTimePaymentMethodPayload = (args) => {
  try {
    const {
      accountHolderFirstName,
      accountHolderLastName,
      amount,
      customerId,
      error,
      requestId,
      routingTransitNumber,
      __typename,
      success,
      type,
      ...paymentMethod
    } = args;

    return paymentMethod;
  } catch (err) {
    const _error = err && err.message ? err.message : 'something when wrong.';
    // eslint-disable-next-line no-console
    console.error(_error);

    return false;
  }
};
/*
  input DDAPaymentMethodInput {
    accountHolderFirstName: String!
    accountHolderLastName: String!
    brand: String
    category: PaymentMethodCategory!
    customerId: String!
    expiration: String
    issuer_id: String
    last: String
    routingTransitNumber: String!
    primary: Boolean!
    subscriptionId: String
    type: DDAAccountType!
    token: String!
  }
*/
export const getDDAPaymentMethod = (args) => {
  try {
    const {
      accountHolderFirstName,
      accountHolderLastName,
      brand,
      customerId,
      last,
      routingTransitNumber,
      token,
      type
    } = args;

    return {
      accountHolderFirstName,
      accountHolderLastName,
      brand,
      category: DDA,
      customerId,
      last,
      routingTransitNumber,
      primary: false,
      token,
      type: type ? type.toUpperCase() : null
    };
  } catch (err) {
    const _error = err && err.message ? err.message : 'something when wrong.';
    // eslint-disable-next-line no-console
    console.error(_error);

    return false;
  }
};

/*
  input PaymentMethodInput {
    id: String
    amount: Float
    billingDetails: BillingDetailsInput
    brand: String
    category: PaymentMethodCategory!
    customerId: String
    expiration: String
    issuer_id: String
    last: String
    primary: Boolean!
    subscriptionId: String
    token: String!
  }

  input AccountHolderInput {
    firstName: String!
    lastName: String!
  }

  input BillingDetailsInput {
    accountHolder: AccountHolderInput
    address: String
    address2: String
    locality: String
    region: String
    postal: String
    country: String
  }
*/
export const getPaymentMethodInput = (args) => {
  try {
    const {
      accountHolderFirstName,
      accountHolderLastName,
      billingAddress,
      billingAddress2,
      billingLocality,
      billingRegion,
      billingPostal,
      code,
      id,
      routingTransitNumber,
      scheme,
      status,
      success,
      token,
      type,
      ...rest
    } = args;
    let accountHolder;
    if (accountHolderFirstName && accountHolderLastName) {
      accountHolder = {
        firstName: accountHolderFirstName,
        lastName: accountHolderLastName
      };
    }

    return {
      ...rest,
      billingDetails: {
        accountHolder,
        address: billingAddress || null,
        address2: billingAddress2 || null,
        locality: billingLocality || null,
        region: billingRegion || null,
        postal: billingPostal || null,
        country: null
      },
      category: CREDIT,
      primary: false,
      token
    };
  } catch (err) {
    const _error = err && err.message ? err.message : 'something when wrong.';
    // eslint-disable-next-line no-console
    console.error(_error);

    return false;
  }
};
