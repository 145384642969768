import _ from 'lodash';
import React, { useState, Fragment } from 'react';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useMutation } from '@apollo/client';

import createCustomer from '../mutations/createCustomer';
import getCustomers from '../../customers/queries/searchCustomers';

import CustomerSchema from '../form/validation-schema';
import CustomerForm from '../form';
import SaveButton from '../../../components/buttons/save-button';
import CircleProgress from '../../../components/progress/circle';

const useStyles = makeStyles((theme) => ({
  addContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  sectionTitle: {
    marginRight: '7px'
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: '10px'
  },
  saveButton: {
    position: 'relative',
    marginTop: '30px'
  },
  progress: {
    position: 'absolute',
    top: '30%'
  }
}));

const initialFormValues = {
  externalId: '',
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  address: '',
  address2: '',
  locality: '',
  region: '',
  postal: '',
  country: 'US',
  phone: '',
  language: '',
  metadata: [],
};

const CustomerAdd = (props) => {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const { history } = props;
  const [updateCreateCustomer] = useMutation(createCustomer, {
    refetchQueries: [
      {
        query: getCustomers,
        variables: { input: { customerSearchTerm: '' } }
      }
    ]
  });

  const handleNext = async (data) => {
    // disables button and shows spinner
    setIsSaving(true);
    const _metadata = data.metadata.map((item) => {
      _.unset(item, '__typename');
      return item;
    });
    const variables = {
      input: {
        externalId: data.externalId,
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address || null,
        address2: data.address2 || null,
        email: data.email || null,
        company: data.company || null,
        locality: data.locality || null,
        region: data.region || null,
        postal: data.postal || null,
        phone: data.phone || null,
        language: data.language || null,
        country: data.country || null,
        metadata: _metadata || []
      }
    };
    console.log({ variables });
    try {
      const createResult = await updateCreateCustomer({ variables: { ...variables } });
      console.log({ createResult });
      const {
        data: { createCustomer: newCustomer }
      } = createResult;

      if (_.has(newCustomer, 'id')) {
        history.push(`/customer/${_.get(newCustomer, 'id')}`);
      } else {
        throw new Error('Customer Creation Failed.');
      }
    } catch (error) {
      console.error(error.message.replace('[INTERNAL_SERVER_ERROR] ', ''));
      setIsSaving(false);
    }
  };

  return (
    <Fragment>
      <Typography variant="h1">Add Customer</Typography>
      <Formik
        validationSchema={CustomerSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialFormValues}
        onSubmit={handleNext}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
            <form onSubmit={handleSubmit}>
              <CustomerForm
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
              />
              <FormControl className={classes.saveButton}>
                <SaveButton
                  disabled={isSaving}
                  label="Add Customer"
                  onClick={() => handleSubmit(values)}
                />
                {isSaving && (
                  <CircleProgress className={classes.progress} size={20} />
                )}
              </FormControl>
            </form>
        )}
      />
    </Fragment>
  );
};

export default CustomerAdd;
