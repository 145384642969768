import { gql } from '@apollo/client';

export default gql`
  query getProductsByTypeQuery($input: GetProductsByTypeInput) {
    products: getProductsByType(input: $input) {
      id
      merchantId
      merchantProductId
      sku
      description
      type
      status
      prices {
        frequency
        amount
        currency {
          name
          symbol
        }
      }
    }
  }
`;
