import React from 'react';

const ContactInfoDisplay = (props) => {
  const { customer } = props;
  const {
    id = '',
    externalId = '',
    email = '',
    address = '',
    address2,
    locality,
    region = '',
    postal = '',
    country = '',
    phone,
    company = '',
  } = customer;
  const _id = id ? `Customer ID: ${id}` : '';
  const _locality = locality ? `${locality},` : '';
  const _address2 = address2 ? `, ${address2}` : '';
  const _externalId = externalId ? `External ID: ${externalId}` : '';
  const _phone = phone ? `Phone: ${phone}` : '';
  const _company = company ? `Company: ${company}` : '';

  return (
    <ul>
      <li>{_id}</li>
      <li>{_externalId}</li>
      <li>{email}</li>
      <li>
        {address}
        {_address2}
      </li>
      <li>
        {_locality} {region} {postal} {country}
      </li>
      <li>{_phone}</li>
      <li>{_company}</li>
    </ul>
  );
};

export default ContactInfoDisplay;
