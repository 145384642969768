import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';

import './add-button.scss';

const AddButton = (props) => {
  const { label, onClick } = props;

  return (
    <div className="add-button" onClick={onClick}>
      <IconButton aria-label="add" size="small" className="add-button-icon">
        <AddCircleIcon fontSize="small" htmlColor="#388e3c" />
      </IconButton>
      <div className="add-button-label">{label}</div>
    </div>
  );
};

export default AddButton;
