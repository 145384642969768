import _cleanProductForMutation from './cleanProductForMutation';
import _cleanPaymentMethodForMutation from './cleanPaymentMethodForMutation';

export const cleanProductForMutation = _cleanProductForMutation;
export const cleanPaymentMethodForMutation = _cleanPaymentMethodForMutation;

const cleaners = {
  cleanProductForMutation,
  cleanPaymentMethodForMutation
};

export default cleaners;
